const unit7 = {
  unitOrder: 7,
  unitName: "Solving Systems",
  unitDescription:
    "Learn how to solve systems of equations using various methods, as well as an introduction to functions",
  unitLogo: {
    url: "",
  },
  lessons: [],
};

export default unit7;
