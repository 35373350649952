export const getRandomArgs = () => {
  const letter = getRandomLowercaseLetter();
  const varColor = getVarColor();
  let constantANV = rando(12);
  let constantADV = constantANV.toString();

  let constantBNV = rando(12);
  let constantBDV = constantBNV.toString();

  let constantCNV = rando(12);
  let constantCDV = constantCNV.toString();

  let coefficientANV = rando(12);
  let coefficientADV = coefficientANV.toString();

  let coefficientBNV = rando(12);
  let coefficientBDV = coefficientBNV.toString();

  let coefficientCNV = rando(12);
  let coefficientCDV = coefficientCNV.toString();
  const args = {
    constantA: { numberValue: constantANV, constantADV },
    constantB: { numberValue: constantBNV, displayValue: constantBDV },
    constantC: { numberValue: constantCNV, displayValue: constantCDV },
    varA: {
      letterValue: letter,
      color: varColor,
    },
    coefficientA: { numberValue: coefficientANV, displayValue: coefficientADV },
    coefficientB: { numberValue: coefficientBNV, displayValue: coefficientBDV },
    coefficientC: { numberValue: coefficientCNV, displayValue: coefficientCDV },
  };
  return args;
};

export const getVarColor = () => {
  const colIndex = Math.floor(Math.random() * 3);
  const colors = ["#a8655d", "#67a386", "#5284cf"];
  return colors[colIndex];
};

export const shuffleArray = (originalArray) => {
  let array = originalArray.slice();
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const gcd = (a, b) => {
  return b ? gcd(b, a % b) : a; // Recursive GCD calculation
};

export const decimalToKaTeXFraction = (decimal) => {
  const tolerance = 1e-7; // Tighter tolerance for precision
  let denominator = 1;
  let numerator = decimal;

  // Convert decimal to a fraction
  while (Math.abs(numerator) - Math.floor(numerator) > tolerance) {
    denominator *= 10;
    numerator = decimal * denominator;
  }

  numerator = Math.round(numerator); // Adjust numerator to be an integer
  const divisor = gcd(numerator, denominator); // Simplify fraction
  numerator /= divisor;
  denominator /= divisor;

  // KaTeX formatted fraction, handling sign and integer cases
  if (denominator === 1) {
    // It's an integer, no need for fraction
    return `${numerator}`;
  }

  const sign = numerator < 0 ? "-" : "";
  return `${sign}{\\frac{${Math.abs(numerator)}}{${denominator}}}`;
};

export const getFractionString = (n, d, isLeading = false) => {
  let katexStr = `{\\frac{${Math.abs(n?.numberValue)}}{${Math.abs(d?.numberValue)}}}`;
  if (n?.numberValue === d?.numberValue) {
    return "";
  }
  return n?.numberValue * d?.numberValue >= 0
    ? `${isLeading ? "" : "+"}${katexStr}`
    : `-${katexStr}`;
};

export const getCoefficientKatexString = (c, isLeading = false) => {
  let katexStr =
    c?.numberValue === 1 || c?.numberValue === -1
      ? ""
      : `${Math.abs(c?.numberValue)}`;
  return c?.numberValue >= 0
    ? `${isLeading ? "" : "+"}${katexStr}`
    : `-${katexStr}`;
};

export const getFractionWithVarCoefficientSign = (
  c,
  num,
  isLeading = false,
  showCoefficient = false,
) => {
  const cNum = c?.numberValue;
  const sign = cNum * num?.numberValue >= 0 ? `${isLeading ? "" : "+"}` : `-`;
  return showCoefficient
    ? `${sign}${cNum === 1 || cNum === -1 ? "" : Math.abs(c?.numberValue)}`
    : sign;
};

export const getRandomLowercaseLetter = () => {
  const letters = "abcdfghjklmnpqrstuvwxyz"; // String of all lowercase letters
  const randomIndex = Math.floor(Math.random() * letters.length); // Generate a random index
  return letters[randomIndex]; // Return the letter at the random index
};

export const numWithSign = (num, isLeading = false) => {
  if (num?.numberValue === 0) return "";
  const absNum = Math.abs(num?.numberValue);
  const sign = num?.numberValue >= 0 ? (isLeading ? "" : `+`) : `-`;
  return `${sign}${isLeading && (absNum === 1 || absNum === -1) ? "" : absNum}`;
};

export const showVar = (variable) => {
  return `{\\textcolor{${variable?.color}}{${variable?.letterValue}}}`;
};

export const rando = (range) => {
  return Math.floor(Math.random() * range) + 1;
};

export const formatFractionForKaTeX = (inequality) => {
  // Splits the compound inequality into individual inequalities
  const parts = inequality.split(/\s(and|or)\s/);

  // Formats each inequality
  const formattedParts = parts.map((part) => {
    // Formats fractions with a whole number followed by a fraction, including handling negative numbers
    part = part.replace(/(-?\d+)\s+(\d+)\/(\d+)/g, (match, whole, num, den) => {
      const sign = whole.startsWith("-") ? "-" : "";
      const wholeAbs = Math.abs(whole);
      return `${sign}${wholeAbs > 0 ? wholeAbs : ""}{\\frac{${num}}{${den}}}`;
    });
    // Formats standalone fractions, handling negative numbers
    part = part.replace(
      /(^|\s)(-?)(\d+)\/(\d+)(\s|$)/g,
      (match, space1, sign, num, den, space2) => {
        return `${space1}${sign === "-" ? "-" : ""}{\\frac{${num}}{${den}}}${space2}`;
      },
    );
    return part;
  });

  // Joins the formatted parts back together
  return formattedParts.join(" ");
};

export const alterNumber = (origNum) => {
  let rA = rando(2) + 1;
  let rB = rando(3) + 1;
  const newNum = rA === 2 ? origNum + rB : origNum - rB;
  return newNum;
};

export const alterArgs = (argz) => {
  const aa = alterNumber(argz?.constantA?.numberValue);
  const bb = alterNumber(argz?.constantB?.numberValue);
  const cc = alterNumber(argz?.constantC?.numberValue);
  const caa = alterNumber(argz?.coefficientA?.numberValue);
  const baa = alterNumber(argz?.coefficientB?.numberValue);
  const newArgs = {
    constantA: { numberValue: aa, displayValue: `${aa}` },
    constantB: { numberValue: bb, displayValue: `${bb}` },
    constantC: { numberValue: cc, displayValue: `${cc}` },
    varA: argz?.varA,
    coefficientA: { numberValue: caa, displayValue: `${caa}` },
    coefficientB: { numberValue: baa, displayValue: `${baa}` },
  };
  return newArgs;
};

export const formatVarForKaTeX = (inputString, varLetter, varColor) => {
  // Regex to match the variable letter that is not directly preceded or followed by A-Z or a-z letters
  const regex = new RegExp(`(?<![A-Za-z])${varLetter}(?![A-Za-z])`, "g");

  // Replace all occurrences of the variable letter with the KaTeX formatted variable
  return inputString.replace(
    regex,
    `{\\textcolor{${varColor}}{\\large{${varLetter}}}}`,
  );
};

export const formatWordWithKaTeX = (
  str,
  word,
  operatorColor,
  isText = false,
) => {
  const regex = new RegExp(`\\b${word}\\b`, "g");
  if (isText) {
    return str.replace(
      regex,
      `{\\text{${" "}}}{\\textcolor{${operatorColor}}{\\text{${word}}}}{\\text{${" "}}}`,
    );
  }
  return str.replace(
    regex,
    `{\\medspace}{\\textcolor{${operatorColor}}{${word.toUpperCase()}}}{\\medspace}{\\medspace}`,
  );
};

export const formatCompoundInequalityForKaTeX = ({
  str,
  letter,
  letterColor,
  operatorColor,
}) => {
  let newString = formatFractionForKaTeX(str);
  let newStringB = formatVarForKaTeX(newString, letter, letterColor);
  let newStringC = formatWordWithKaTeX(newStringB, "and", operatorColor);
  let newStringD = formatWordWithKaTeX(newStringC, "or", operatorColor);
  let newStringE = formatWordWithKaTeX(
    newStringD,
    "No solutions",
    "#1f2020",
    true,
  );
  let newStringF = formatWordWithKaTeX(newStringE, "Infinity", "#1f2020", true);
  let newStringG = formatWordWithKaTeX(
    newStringF,
    "All values of",
    "#1f2020",
    true,
  );
  let finalString = formatWordWithKaTeX(
    newStringG,
    "are solutions",
    "#1f2020",
    true,
  );
  return finalString;
};
