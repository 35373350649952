import katex from "katex";
import { useEffect, useRef } from "react";
import "katex/dist/katex.min.css";
import "./katex.scss";

function Katex({ texExpression, className, color, bold }) {
  const containerRef = useRef(null); // Corrected line

  useEffect(() => {
    if (containerRef.current) {
      katex.render(texExpression, containerRef.current);
    }
  }, [texExpression]);

  return (
    <div
      style={{ color }}
      ref={containerRef}
      className={`${className} ${bold ? "katex-bold" : ""}`}
    />
  );
}

export default Katex;
