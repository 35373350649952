import React, { useState, useEffect } from "react";
import Katex from "../../components/Katex";
import MathInput from "../MathInput/MathInput";
import { createAnswerPartState } from "../../shared/answerPartState";
import TextInput from "../TextInput/TextInput";
import Select, { components } from "react-select";
import "./MultiInput.scss";

const { Option } = components;
const KatexOption = (props) => (
  <Option {...props}>
    <Katex texExpression={props.data.label} />
  </Option>
);
const KatexSingleValueOption = (props) => (
  <Option {...props}>
    <Katex
      className="dropdown-katex-optionSelectedValue"
      texExpression={props.data.label}
    />
  </Option>
);
const TextOption = (props) => (
  <Option {...props}>
    <div>{props.data.label}</div>
  </Option>
);
const selectStyles = (width) => {
  return {
    input: (styles) => ({
      ...styles,
      width,
      fontSize: "14px",
    }),
    placeholder: (styles) => ({ ...styles, fontSize: "14px" }),
  };
};

const renderComponent = (
  part,
  texBG,
  index,
  handleTextChange,
  handleSelectChange,
  size,
) => {
  if (!part || !part.type) return;
  const color = part.color || "#000";

  switch (part.type) {
    case "text":
      return (
        <span
          key={`linePart_${index}`}
          className={`multi-input-part multi-input-part--text text_${size}`}
        >
          {part.value}
        </span>
      );
    case "katex":
      return (
        <span
          key={`linePart_${index}`}
          className={`multi-input-part text_${size}`}
        >
          <Katex
            texExpression={part.value}
            className={`multi-input-part--katex ${texBG ? "texBG" : ""}`}
            color={color}
            bold={part?.bold}
          />
        </span>
      );
    case "textfield":
      return (
        <div key={`linePart_${index}`} className="multi-input-part">
          <TextInput
            size={part?.size}
            onChange={(val) => handleTextChange(index, val)}
            width={part?.width}
          />
        </div>
      );
    case "mathinput":
      return <MathInput size={part?.size} />;
    case "dropdown":
      const katexOptions = part?.component && part.component === "katex";
      return (
        <div
          key={`linePart_${index}`}
          className={`multi-input-part ${katexOptions ? "katexOptions" : ""}`}
        >
          <Select
            placeholder={part?.placeholder}
            styles={selectStyles(part?.width)}
            options={part?.options}
            classNamePrefix="multi-input-select"
            onChange={(ev) => handleSelectChange(index, ev)}
            components={{
              Option: katexOptions ? KatexOption : TextOption,
              SingleValue: katexOptions ? KatexSingleValueOption : TextOption,
            }}
          />
        </div>
      );
    case "newline":
      return (
        <div className="newline-spacer">
          <br />
        </div>
      );
  }
};

const MultiInput = ({
  lineParts,
  texBG,
  center = false,
  size = "md",
  updateAnswerState = () => {},
}) => {
  const inputVals = lineParts.map((part) =>
    part?.correctAnswer ? part.correctAnswer : -1,
  );
  const initialAnswerState = lineParts.map(() => -1);
  const [currentAnswers, setCurrentAnswers] = useState(initialAnswerState);

  const handleAnswerStateChange = (answers) => {
    const isCorrect = JSON.stringify(answers) === JSON.stringify(inputVals);
    let newState = {
      ...createAnswerPartState("MultiInput"),
      isCorrect,
    };
    updateAnswerState(newState);
  };

  useEffect(() => {
    handleAnswerStateChange(currentAnswers);
  }, [currentAnswers]);

  const handleTextChange = (index, currentValue) => {
    const newAnswers = [...currentAnswers];
    newAnswers[index] = currentValue;
    setCurrentAnswers(newAnswers);
  };

  const handleSelectChange = (index, a) => {
    const currentSelectValue = a?.value;
    const newAnswers = [...currentAnswers];
    newAnswers[index] = currentSelectValue;
    setCurrentAnswers(newAnswers);
  };

  if (!lineParts) return;
  return (
    <div
      className="multi-input"
      style={{ justifyContent: center ? "center" : "default" }}
    >
      {lineParts.map((part, index) =>
        renderComponent(
          part,
          texBG,
          index,
          handleTextChange,
          handleSelectChange,
          size,
        ),
      )}
    </div>
  );
};

export default MultiInput;
