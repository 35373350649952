import React, { useState } from "react";
import { addStyles, EditableMathField } from "react-mathquill";
import MathIcon from "./images/math-icon.svg";
import MathIconHover from "./images/math-icon-hover.svg";
import MathIconActive from "./images/math-icon-active.svg";
import CloseIcon from "./images/close-icon.svg";
import CloseIconHover from "./images/close-icon-hover.svg";
import ExpAddIcon from "./images/exp-add.svg";
import FracAddIcon from "./images/fraction-add.svg";
import NthRootIcon from "./images/nth-root-icon.svg";
import PiIcon from "./images/pi-icon.svg";
import SqrtAddIcon from "./images/sqrt-add.svg";
import BackspaceIcon from "./images/backspace-icon.svg";
import BackspaceHoverIcon from "./images/backspace-icon-hover.svg";
import "./MathInput.scss";

addStyles();

const MathInput = ({ size = "lg" }) => {
  const [showFormatters, setShowFormatters] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [latex, setLatex] = useState("");
  const [mathRef, setMathRef] = useState(null);
  const [closeHover, setCloseHover] = useState(false);
  const [formatHover, setFormatHover] = useState(false);
  const [backspaceHover, setBackspaceHover] = useState(false);

  const closeFormatters = () => {
    setShowFormatters(false);
  };

  const toggleFormatters = () => {
    setShowFormatters(!showFormatters);
  };

  const setMathFieldRef = (mathField) => {
    setMathRef(mathField);
  };

  const addSqrt = () => {
    if (mathRef) {
      mathRef.cmd("\\sqrt");
    }
  };

  const addFraction = () => {
    if (mathRef) {
      mathRef.cmd("\\frac");
    }
  };

  const backSpace = () => {
    if (mathRef) {
      mathRef.keystroke("Backspace");
    }
  };

  const addNthRoot = () => {
    if (mathRef) {
      mathRef.write("\\sqrt[]{}");
    }
  };

  const addExponent = () => {
    if (mathRef) {
      mathRef.typedText("^");
    }
  };

  const addPi = () => {
    if (mathRef) {
      mathRef.typedText("pi");
    }
  };

  const handleInputChange = (mathField) => {
    setLatex(mathField.latex());
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div
      className={`mathInput ${size !== "lg" ? `mathInput--${size}` : ""} ${isFocused ? "focus" : ""}`}
    >
      <EditableMathField
        latex={latex}
        config={{ autoCommands: "pi sqrt" }}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        mathquillDidMount={setMathFieldRef}
      />
      <div
        className={`mathInput-toggleFormatBtnsContainer ${
          showFormatters ? "active" : ""
        }`}
      >
        <div
          onClick={toggleFormatters}
          className="mathInput-toggleFormatBtns"
          onMouseEnter={() => setFormatHover(true)}
          onMouseLeave={() => setFormatHover(false)}
        >
          <img
            src={
              showFormatters
                ? MathIconActive
                : formatHover
                  ? MathIconHover
                  : MathIcon
            }
          />
        </div>
        {showFormatters && (
          <div className="mathInput-formatBtns">
            <div className="mathInput-formatBtnsHeader">
              <div className="mathInput-formatBtnsCaret" />
              <button
                className="mathInput-closeIcon"
                onClick={closeFormatters}
                onMouseEnter={() => setCloseHover(true)}
                onMouseLeave={() => setCloseHover(false)}
              >
                <img src={closeHover ? CloseIconHover : CloseIcon} />
              </button>
            </div>
            <div className="mathInput-formatBtnsContent">
              <div className="mathInput-formatBtnsContent--row">
                <button className="mathInput-formatBtn sqrt" onClick={addSqrt}>
                  <img src={SqrtAddIcon} />
                </button>
                <button
                  className="mathInput-formatBtn frac"
                  onClick={addFraction}
                >
                  <img src={FracAddIcon} />
                </button>
                <button
                  className="mathInput-formatBtn exp"
                  onClick={addExponent}
                >
                  <img src={ExpAddIcon} />
                </button>
              </div>
              <div className="mathInput-formatBtnsContent--row">
                <button
                  className="mathInput-formatBtn nth"
                  onClick={addNthRoot}
                >
                  <img src={NthRootIcon} />
                </button>
                <button className="mathInput-formatBtn pi" onClick={addPi}>
                  <img src={PiIcon} />
                </button>
                <button
                  className="mathInput-formatBtn backspace"
                  onClick={backSpace}
                  onMouseEnter={() => setBackspaceHover(true)}
                  onMouseLeave={() => setBackspaceHover(false)}
                >
                  <img
                    src={backspaceHover ? BackspaceHoverIcon : BackspaceIcon}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MathInput;
