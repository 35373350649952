const question5 = () => {
  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: "Graph a line that contains the point ",
            },
            {
              type: "katex",
              value: "(3, 7)",
            },
            {
              type: "text",
              value: "and has a slope of ",
            },
            {
              type: "katex",
              value: "-{\\frac{1}{2}}",
            },
            {
              type: "text",
              value: ".",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
          ],
        },
      },
      {
        type: "Grapher",
        args: {
          plotType: "line",
          plotPoints: [
            [
              [-7, 4],
              [4, 7],
            ],
          ],
          fixedAsymptote: true,
          showAsymptote: true,
          asymptoteHexColor: "#23dba4",
          lineColors: ["#2d81ff"],
          showPoints: true,
          yAxisTitle: "",
          xAxisTitle: "",
        },
      },
    ],
  };
};

export default question5();
