import { getCoefficientKatexString } from "../../../../../../../../helpers/main";

export const question2 = ({ constantA, varA, coefficientA }) => ({
  questionConfig: [
    {
      type: "MultiInput",
      args: {
        size: "md",
        texBG: false,
        lineParts: [
          {
            type: "text",
            value: "Evaluate ",
          },
          {
            type: "katex",
            value: `${constantA?.displayValue} ${getCoefficientKatexString(coefficientA)} `,
          },
          {
            type: "katex",
            value: `\\textcolor{${varA?.color}}{${varA?.letterValue}}`,
          },
          {
            type: "text",
            value: " when ",
          },
          {
            type: "katex",
            value: `\\textcolor{${varA?.color}}{${varA?.letterValue}} `,
          },
          {
            type: "text",
            value: " = ",
          },
          {
            type: "katex",
            value: ` ${varA?.displayValue}`,
          },
          {
            type: "text",
            value: ".",
          },
          {
            type: "newline",
          },
          {
            type: "textfield",
            width: 30,
            size: "sm",
          },
        ],
      },
    },
  ],
});

const args = {
  constantA: { numberValue: 9, displayValue: "9" },
  coefficientA: { numberValue: 1, displayValue: "1" },
  varA: {
    numberValue: 2,
    displayValue: "2",
    letterValue: "b",
    color: "#a8655d",
  },
};

export default question2(args);
