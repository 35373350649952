import React, { useState, useEffect } from "react";
import "./QuizQuestion.scss";
import MultiInput from "../MultiInput/MultiInput";
import ChoiceInput from "../ChoiceInput/ChoiceInput";
import TableChoice from "../TableChoice/TableChoice";
// import Image from "./Image";
import NumberGrid from "../NumberGrid/NumberGrid";
import OrderInputTable from "../OrderInputTable/OrderInputTable";
import OrderInputStacks from "../OrderInputStacks/OrderInputStacks";
import Grapher from "../Grapher/Grapher";
import FactorizationTree from "../FactorizationTree/FactorizationTree";

const QuizQuestion = ({ questionConfig, updateAnswerState, questionIndex }) => {
  const [currentConfig, setCurrentConfig] = useState([]);

  useEffect(() => {
    setCurrentConfig(questionConfig);
  }, [questionConfig]);

  const renderQuestionPart = (partConfig, questionIndex) => {
    switch (partConfig.type) {
      case "MultiInput":
        return <MultiInput {...partConfig.args} />;
      case "FactorizationTree":
        return <FactorizationTree {...partConfig.args} />;
      case "ChoiceInput":
        return <ChoiceInput {...partConfig.args} />;
      case "NumberGrid":
        return <NumberGrid {...partConfig.args} />;
      case "TableChoice":
        return <TableChoice {...partConfig.args} />;
      case "Image":
        return <img {...partConfig.args} />;
      case "OrderInputTable":
        return <OrderInputTable {...partConfig.args} />;
      case "OrderInputStacks":
        return <OrderInputStacks {...partConfig.args} />;
      case "Grapher":
        return (
          <Grapher key={`question_${questionIndex}`} {...partConfig.args} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="quiz-question">
      {currentConfig.map((part, index) => (
        <div key={index} className="quiz-part">
          {renderQuestionPart(part, questionIndex)}
        </div>
      ))}
    </div>
  );
};

export default QuizQuestion;
