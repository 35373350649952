export const drawAbsValThroughPoints = (
  paper,
  points,
  lineColor,
  gridOffset,
) => {
  const { yAxisXOffsetFromLeft, xAxisYOffsetFromTop } = gridOffset;
  const gridXOffsetValue = -25 * yAxisXOffsetFromLeft;
  const gridYOffsetValue = -25 * xAxisYOffsetFromTop;

  const [pOne, pTwo] = points;
  let [h, k] = pOne;
  let [x2, y2] = pTwo;
  // Vertex point in pixels
  let vertexPx = gridXOffsetValue + h * 25;
  let vertexPy = gridYOffsetValue - k * 25;

  // Second point in pixels
  let secondPx = gridXOffsetValue + x2 * 25;
  let secondPy = gridYOffsetValue - y2 * 25;

  // Determine the slope of the line from the vertex to the second point
  let slope = (secondPy - vertexPy) / (secondPx - vertexPx);

  // Draw the 'right arm' using the slope from the vertex to the second point
  let endPxRight = vertexPx <= secondPx ? 500 : 0; // If vertex is to the left of second point, draw to right border, otherwise to left border
  let endPyRight = vertexPy + slope * (endPxRight - vertexPx);

  // Draw the 'left arm' with negative slope to mirror the 'right arm'
  let endPxLeft = vertexPx > secondPx ? 500 : 0; // If vertex is to the right of second point, draw to right border, otherwise to left border
  let endPyLeft = vertexPy - slope * (endPxLeft - vertexPx);

  // Draw the 'right arm'
  paper.path(`M${vertexPx},${vertexPy}L${endPxRight},${endPyRight}`).attr({
    stroke: lineColor,
    "stroke-width": 2,
  });

  // Draw the 'left arm'
  paper.path(`M${vertexPx},${vertexPy}L${endPxLeft},${endPyLeft}`).attr({
    stroke: lineColor,
    "stroke-width": 2,
  });
};
