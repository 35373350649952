import { getFractionWithVarCoefficientSign } from "../../../../../../../../helpers/main";

const question7 = ({ coefficientA, varA, denominatorA }) => ({
  questionConfig: [
    {
      type: "MultiInput",
      args: {
        size: "md",
        texBG: false,
        lineParts: [
          {
            type: "text",
            value: "What is ",
          },
          {
            type: "katex",
            value: `${getFractionWithVarCoefficientSign(coefficientA, denominatorA, true, true)}\\frac{\\textcolor{${varA?.color}}{${varA?.letterValue}}}{${Math.abs(denominatorA?.displayValue)}}`,
          },
          {
            type: "text",
            value: " when ",
          },
          {
            type: "katex",
            value: `\\textcolor{${varA?.color}}{${varA?.letterValue}}`,
          },
          {
            type: "text",
            value: " = ",
          },
          {
            type: "katex",
            value: `${varA?.displayValue}`,
          },
          {
            type: "text",
            value: "?",
          },
          {
            type: "newline",
          },
          {
            type: "textfield",
            width: 30,
            size: "sm",
          },
        ],
      },
    },
  ],
});

const args = {
  coefficientA: { numberValue: 1, displayValue: "1" },
  varA: {
    numberValue: 12,
    displayValue: "12",
    letterValue: "j",
    color: "#a8655d",
  },
  denominatorA: { numberValue: 4, displayValue: "4" },
};

export default question7(args);
