const part2 = {
  title: "Compound & Double Inequalities",
  type: "video",
  partOrder: 2,
  videoURL: "",
  videoLessonPartDescription:
    "How inequalities can combine to form multiple simple inequalities using 'and' or 'or' statements.",
};

export default part2;
