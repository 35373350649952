import React, { useState } from "react";
import PropTypes from "prop-types";
import "./TextInput.scss";

const TextInput = ({ size, placeholder, onChange, width, className }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    const val = e.target.value;
    setInputValue(val);
    onChange(val);
  };

  return (
    <input
      onChange={handleInputChange}
      className={`am-textInput am-textInput--${size} ${className}`}
      style={{ width }}
      type="text"
      value={inputValue}
      placeholder={placeholder}
    />
  );
};

TextInput.propTypes = {
  size: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextInput;
