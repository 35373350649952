import { getRandomComposite } from "./compositeManager.js";
import { primeFactors } from "./helpers.js";

const question1 = () => {
  const composite = getRandomComposite(11, 76);
  const allPrimeFactors = primeFactors(composite);

  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: `Use the prime factorization tree widget below to list the prime factors of ${composite}?`,
              bold: true,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
          ],
        },
      },
      {
        type: "FactorizationTree",
        args: {
          initialValue: composite,
        },
      },
    ],
  };
};

export default question1();
