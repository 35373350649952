import React from "react";
import QuizQuestion from "../../stories/QuizQuestion/QuizQuestion";
import "./Dashboard.scss";

const QUESTIONS = [
  {
    id: "q1",
    type: "multiple-choice",
    questionTextParts: [
      "What is the result of adding the vectors",
      "\b and",
      "?",
    ],
    questionMathParts: [
      "\\ ( \\vec{a} = \\begin{bmatrix} 3 \\\\ -2 \\end{bmatrix} )",
      "\\ ( \\vec{b} = \\begin{bmatrix} -1 \\\\ 4 \\end{bmatrix} )",
    ],
    options: [
      "\\begin{bmatrix} 2 \\\\ 2 \\end{bmatrix}",
      "\\begin{bmatrix} -4 \\\\ -6 \\end{bmatrix}",
      "\\begin{bmatrix} 2 \\\\ -6 \\end{bmatrix}",
      "\\begin{bmatrix} 3 \\\\ 2 \\end{bmatrix}",
    ],
    correctAnswer: "A",
    media: "https://i.ibb.co/SdW32dR/question-1.png",
  },
  {
    id: "q2",
    type: "true-false",
    questionTextParts: [
      "True or False: A vector's length is also known as its magnitude.",
    ],
    questionMathParts: [],
    correctAnswer: "True",
  },
  {
    id: "q3",
    type: "input",
    questionTextParts: [
      "If \b",
      "\b represents a vector in two-dimensional space, express the vector's magnitude.",
    ],
    questionMathParts: [
      "( \\vec{v} = \\begin{bmatrix} x \\\\ y \\end{bmatrix} )",
    ],
    correctAnswer: "\\sqrt{x^2 + y^2}",
  },
];

const Dashboard = () => {
  return (
    <div className="dashboard">
      <h1>User Dashboard</h1>
      <QuizQuestion question={QUESTIONS[0]} />
    </div>
  );
};

export default Dashboard;
