import React, { useState, useEffect } from "react";
import getCourseContent from "../../courseContent";
import { useParams, useLocation, Link } from "react-router-dom";
import CourseLogo from "../Home/images/CourseLogo.svg";
import Quiz from "../../stories/Quiz/Quiz";
import Caret from "./images/caret.svg";
import CaretSVG from "./CaretSVG";
import "./Lesson.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const videoUrl =
  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4";

const Lesson = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const { courseId, lessonId } = useParams(); // Assuming "lesson/1_1" is captured as :lessonId in your route
  const query = useQuery();
  const partOrder = query.get("partOrder") || 1; // Get partOrder from query parameters
  const [currentUnitOrderID, setCurrentUnitOrderID] = useState("");
  const [currentLesson, setCurrentLesson] = useState(null);
  const [activePart, setActivePart] = useState(null);
  const [course, setCourse] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [caretAColor, setCaretAColor] = useState("#000");
  const [caretBColor, setCaretBColor] = useState("#000");

  useEffect(() => {
    if (!courseId || !lessonId || !partOrder) return;

    const activeCourse = getCourseContent(courseId);
    setCourse(activeCourse);

    // "courses/asdf/lesson/11121_1" --> 1st 11121 is Unit, 2nd 1 is Lesson
    const [unitOrder, lessonOrder] = lessonId.split("_").map(Number);
    setCurrentUnitOrderID(unitOrder);
    const lesson = activeCourse.units
      .find((unit) => unit.unitOrder === unitOrder)
      ?.lessons.find((lesson) => lesson.lessonOrder === lessonOrder);

    setCurrentLesson(lesson);

    if (lesson && lesson.lessonParts.length >= partOrder) {
      setActivePart(lesson.lessonParts[partOrder - 1]); // partOrder - 1 to convert to zero-based index
    }
  }, [lessonId, partOrder]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLessonPartClick = (partOrder) => {
    setActivePart(currentLesson.lessonParts[partOrder]);
  };

  if (!activePart) {
    return <div>Loading lesson part...</div>;
  }

  const handleVideoPlay = () => {
    const videoPlayer = document.getElementById("videoPlayer");
    videoPlayer.play();
    setIsPlaying(true);
  };

  const isVideoPart = activePart && activePart?.videoLessonPartDescription;

  const navigateLessons = (currentLessonID, course) => {
    const [unitIndex, lessonIndex] = currentLessonID.split("_").map(Number);
    let nextLesson = null,
      prevLesson = null;

    // Calculate the next lesson
    if (course.units[unitIndex - 1].lessons[lessonIndex]) {
      nextLesson = `${unitIndex}_${lessonIndex + 1}`;
    } else if (course.units[unitIndex]) {
      nextLesson =
        course.units[unitIndex].lessons.length > 0
          ? `${unitIndex + 1}_1`
          : null;
    }

    // Calculate the previous lesson
    if (lessonIndex > 1) {
      prevLesson = `${unitIndex}_${lessonIndex - 1}`;
    } else if (unitIndex > 1) {
      const prevUnitLastLessonIndex =
        course.units[unitIndex - 2].lessons.length;
      prevLesson = `${unitIndex - 1}_${prevUnitLastLessonIndex}`;
    }

    return { nextLesson, prevLesson };
  };

  const { nextLesson, prevLesson } = navigateLessons(lessonId, course);
  const { lessonOrder, lessonName } = currentLesson;

  return (
    <div
      className={`lesson-container ${isSidebarOpen ? "" : "lesson-sidebar-closed"}`}
    >
      <aside className="lesson-sidebar">
        <div className="lesson-mainHeader">
          <img src={CourseLogo} />
          <div className="lesson-titleText">
            <div>{course?.courseName}</div>
            <div className="lesson-titleText--backBtn">
              <Link to={`/courses/${courseId}`}>Course Home</Link>
              <span className="lesson-titleText--backBtnSpace">{">"}</span>
              <Link to={`/courses/${courseId}?unit=${currentUnitOrderID}`}>
                Unit {currentUnitOrderID}
              </Link>
            </div>
          </div>
        </div>
        <div className="lesson-lessonArrowsContainer">
          <div
            onMouseEnter={() => setCaretAColor("#2d81ff")}
            onMouseLeave={() => setCaretAColor("#000")}
          >
            {prevLesson ? (
              <Link to={`/courses/${courseId}/lesson/${prevLesson}/`}>
                <div className="lesson-lessonArrows leftCaret">
                  <CaretSVG color={caretAColor} />
                </div>
              </Link>
            ) : (
              <div className="lesson-lessonArrows leftCaret disabled">
                <CaretSVG color="#9e9e9e" />
              </div>
            )}
          </div>

          <div className="lesson-title">{`Lesson ${lessonOrder}: ${lessonName}`}</div>

          <div
            onMouseEnter={() => setCaretBColor("#2d81ff")}
            onMouseLeave={() => setCaretBColor("#000")}
          >
            {nextLesson ? (
              <Link to={`/courses/${courseId}/lesson/${nextLesson}/`}>
                <div className="lesson-lessonArrows rightCaret">
                  <CaretSVG color={caretBColor} />
                </div>
              </Link>
            ) : (
              <div className="lesson-lessonArrows rightCaret disabled">
                <CaretSVG color="#9e9e9e" />
              </div>
            )}
          </div>
        </div>
        <div className="lesson-partsOuterContainer">
          <div className="lesson-partsContainer">
            {currentLesson.lessonParts.map((part, partIndex) => (
              <div
                key={partIndex}
                className="lesson-partItem"
                onClick={handleLessonPartClick.bind(this, partIndex)}
              >
                <div
                  className={`lesson-partItemInner ${
                    part.partOrder === activePart?.partOrder
                      ? "lesson-partItemInner--active"
                      : ""
                  }`}
                >
                  <div className="lesson-partItemOrder">{part.partOrder}.</div>
                  <div className="lesson-partItemTitle">{part.title}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </aside>
      {isVideoPart ? (
        <main className="lesson-detail">
          <div className="sidebarToggle" onClick={toggleSidebar}>
            <img src={Caret} />
          </div>
          <div className="lesson-activePartTitle">
            <div>{activePart.title}</div>
          </div>
          <div className="lesson-videoPlayer">
            <video id="videoPlayer" controls>
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {!isPlaying && (
              <button
                className={`video-play-button lesson_${lessonId}`}
                onClick={handleVideoPlay}
              ></button>
            )}
          </div>
        </main>
      ) : (
        <main className="lesson-detail">
          <div
            className={`sidebarToggle ${isSidebarOpen ? "" : "lesson-sidebar-closed"}`}
            onClick={toggleSidebar}
          >
            <img src={Caret} />
          </div>
          <div className="lesson-activePartTitle">{activePart.title}</div>
          <Quiz
            quizQuestions={activePart?.quizQuestions}
            className={`lesson_${lessonId}`}
          />
        </main>
      )}
    </div>
  );
};

export default Lesson;
