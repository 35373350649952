const question3 = () => {
  return {
    questionConfig: [
      {
        type: "Grapher",
        args: {
          plotType: "line",
          plotPoints: [
            [
              [-1, -3],
              [3, 4],
            ],
          ],
          fixedAsymptote: true,
          showAsymptote: false,
          asymptoteHexColor: "#23dba4",
          lineColors: ["#2d81ff"],
          showPoints: false,
          yAxisTitle: "",
          xAxisTitle: "",
        },
      },
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: "What is the slope of the line?",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "mathinput",
              size: "md",
            },
          ],
        },
      },
    ],
  };
};

export default question3();
