import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { displayMap, calculateResult, transformForDisplay } from "./helpers.js";
import CloseIcon from "./images/close-icon.svg";
import Dots from "./images/dots.svg";

import "./Calculator.scss";

const Calculator = ({ onClose }) => {
  const [displayValueIDs, setDisplayValueIDs] = useState([]);
  const [displayValue, setDisplayValue] = useState("0");
  const [displayResults, setDisplayResults] = useState(null);
  const [lastAnswer, setLastAnswer] = useState(null);
  const [isDegree, setIsDegree] = useState(false);
  const [size, setSize] = useState({ width: 300, height: 600 });

  const adjustedWidth = Math.min(Math.max(size.width, 250), 550);

  const dynamicStyles = {
    "--calc-width": `${adjustedWidth}px`,
    "--button-height": `${29 + (10 * (adjustedWidth - 250)) / 100}px`,
    "--display-height": `${Math.max(adjustedWidth / 5.33, 75)}px`,
    "--font-size-buttons": `${Math.max(adjustedWidth / 30.29, 14)}px`,
    "--font-size-display": `${Math.max(adjustedWidth / 14.29, 28)}px`,
    "--font-size-results-shown": `${Math.max(adjustedWidth / 16.67, 24)}px`,
  };

  const startResizing = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.addEventListener("mousemove", resizing);
    window.addEventListener("mouseup", stopResizing);
  };

  const resizing = (e) => {
    setSize((prevSize) => ({
      width: Math.max(
        300,
        e.clientX -
          document.querySelector(".calculator").getBoundingClientRect().left,
      ),
      height: Math.max(
        200,
        e.clientY -
          document.querySelector(".calculator").getBoundingClientRect().top,
      ),
    }));
  };

  const stopResizing = (e) => {
    window.removeEventListener("mousemove", resizing);
    window.removeEventListener("mouseup", stopResizing);
  };

  const handleDisplayAddition = (e) => {
    const id = e.target.id;
    const isSwitchableOp = (val) =>
      ["plus", "minus", "times", "divide"].includes(val);
    if (!id || typeof id !== "string" || id.length < 1) return;
    let newValues = [...displayValueIDs];
    if (isSwitchableOp(id) && isSwitchableOp(newValues[newValues.length - 1])) {
      newValues[newValues.length - 1] = id;
    } else {
      newValues.push(id);
    }
    setDisplayValueIDs(newValues);
  };
  const handleDisplayDeleteLastValue = () => {
    let updatedDisplayValueIds = [...displayValueIDs];
    updatedDisplayValueIds.pop();
    setDisplayValueIDs(updatedDisplayValueIds);
  };

  const getDisplayValue = () => {
    if (displayValueIDs.length === 0) {
      setDisplayValue("0");
    } else {
      const newDisplayVal = displayValueIDs
        .map((key) => {
          if (displayMap?.[key]) {
            return displayMap[key];
          } else if (!isNaN(Number(key))) {
            return key;
          }
        })
        .join("");
      const transformedDisplayValue = transformForDisplay(newDisplayVal);
      setDisplayValue(transformedDisplayValue);
    }
  };

  useEffect(() => {
    getDisplayValue(displayValueIDs);
  }, [displayValueIDs]);

  const clearDisplayValueIds = () => {
    setDisplayValueIDs([]);
    setDisplayValue("0");
    setDisplayResults(null);
  };

  const handleAnsClick = () => {
    let newValues = [...displayValueIDs];
    newValues.push(lastAnswer);
    setDisplayValueIDs(newValues);
  };

  const handleEval = () => {
    const result = calculateResult(displayValueIDs, displayMap, isDegree);
    setDisplayResults(result);
    setLastAnswer(result);
  };

  const preventDragHandler = (e) => {
    e.preventDefault();
  };

  return (
    <Draggable>
      <div className="calculator" style={dynamicStyles}>
        <div className="calculator-header">
          <div className="draggable-icon">
            <img
              src={Dots}
              alt="Drag Calculator"
              onDragStart={preventDragHandler}
            />
          </div>
          <div className="calculator-icon" onClick={onClose}>
            <img
              src={CloseIcon}
              alt="Close Calculator"
              onDragStart={preventDragHandler}
            />
          </div>
          <div onMouseDown={startResizing} className="calc-resizeContainer">
            <div className="calc-resize" />
          </div>
        </div>
        <div className="display" aria-label="calculator display">
          <div
            className={`displayValue ${displayResults ? "resultsShown" : ""}`}
          >
            {displayValue}
            <span>{displayResults ? " =" : ""}</span>
          </div>
          <div className="displayResults">{displayResults}</div>
        </div>
        <div className="toggle">
          <button
            onClick={() => setIsDegree(true)}
            className={`degree-button ${isDegree ? "active" : ""}`}
            aria-label="toggle degree"
          >
            DEG
          </button>
          <button
            onClick={() => setIsDegree(false)}
            className={`radian-button ${!isDegree ? "active" : ""}`}
            aria-label="toggle radian"
          >
            RAD
          </button>
        </div>
        <div className="buttons">
          <div className="buttons-group">
            <div className="button-row">
              <button
                onClick={handleDisplayAddition}
                id="asin"
                data-testid="asin"
                aria-label="inverse sine"
              >
                sin<sup>-1</sup>
              </button>
              <button
                onClick={handleDisplayAddition}
                id="acos"
                data-testid="acos"
                aria-label="inverse cosine"
              >
                cos<sup>-1</sup>
              </button>
              <button
                onClick={handleDisplayAddition}
                id="atan"
                data-testid="atan"
                aria-label="inverse tangent"
              >
                tan<sup>-1</sup>
              </button>
              <button
                onClick={handleDisplayDeleteLastValue}
                aria-label="delete last entry"
              >
                del
              </button>
              <button
                onClick={clearDisplayValueIds}
                className="button-ac"
                data-testid="clear"
                aria-label="clear all"
              >
                AC
              </button>
            </div>
            <div className="button-row">
              <button
                onClick={handleDisplayAddition}
                id="sin"
                data-testid="sin"
                aria-label="sin"
              >
                sin
              </button>
              <button
                onClick={handleDisplayAddition}
                id="cos"
                data-testid="cos"
                aria-label="cosine"
              >
                cos
              </button>
              <button
                onClick={handleDisplayAddition}
                id="tan"
                data-testid="tangent"
                aria-label="tangent"
              >
                tan
              </button>
              <button
                onClick={handleDisplayAddition}
                id="lParen"
                aria-label="open parenthesis"
              >
                (
              </button>
              <button
                onClick={handleDisplayAddition}
                id="rParen"
                aria-label="close parenthesis"
              >
                )
              </button>
            </div>
            <div className="button-row">
              <button
                onClick={handleDisplayAddition}
                id="xToTheY"
                data-testid="xToTheY"
                aria-label="exponent"
              >
                x<sup>y</sup>
              </button>
              <button
                onClick={handleDisplayAddition}
                id="ln"
                data-testid="ln"
                aria-label="natural logarithm"
              >
                ln
              </button>
              <button
                onClick={handleDisplayAddition}
                id="log"
                data-testid="log"
                aria-label="logarithm"
              >
                log
              </button>
              <button onClick={handleAnsClick} aria-label="answer">
                ans
              </button>
              <button
                onClick={handleDisplayAddition}
                id="etoTheX"
                data-testid="eToTheX"
                aria-label="exponential function"
              >
                e<sup>x</sup>
              </button>
            </div>
            <div className="button-row">
              <button
                className="button-op"
                onClick={handleDisplayAddition}
                id="plus"
                aria-label="add"
              >
                +
              </button>
              <button
                className="button-op"
                onClick={handleDisplayAddition}
                id="minus"
                aria-label="subtract"
              >
                -
              </button>
              <button
                className="button-num"
                onClick={handleDisplayAddition}
                id="seven"
                aria-label="number 7"
              >
                7
              </button>
              <button
                className="button-num"
                onClick={handleDisplayAddition}
                id="eight"
                aria-label="number 8"
              >
                8
              </button>
              <button
                className="button-num"
                onClick={handleDisplayAddition}
                id="nine"
                aria-label="number 9"
              >
                9
              </button>
            </div>
            <div className="button-row">
              <button
                className="button-op"
                onClick={handleDisplayAddition}
                id="times"
                aria-label="multiply"
              >
                ×
              </button>
              <button
                className="button-op"
                onClick={handleDisplayAddition}
                id="divide"
                data-testid="divide"
                aria-label="divide"
              >
                ÷
              </button>
              <button
                className="button-num"
                onClick={handleDisplayAddition}
                id="four"
                aria-label="number 4"
              >
                4
              </button>
              <button
                className="button-num"
                onClick={handleDisplayAddition}
                id="five"
                aria-label="number 5"
              >
                5
              </button>
              <button
                className="button-num"
                onClick={handleDisplayAddition}
                id="six"
                aria-label="number 6"
              >
                6
              </button>
            </div>
            <div className="button-row">
              <button
                className="button-op"
                onClick={handleDisplayAddition}
                id="pi"
                data-testid="pi"
                aria-label="pi"
              >
                π
              </button>
              <button
                className="button-op"
                onClick={handleDisplayAddition}
                id="sqrt"
                aria-label="square root"
              >
                √
              </button>
              <button
                className="button-num"
                onClick={handleDisplayAddition}
                id="one"
                aria-label="number 1"
              >
                1
              </button>
              <button
                className="button-num"
                onClick={handleDisplayAddition}
                id="two"
                data-testid="two"
                aria-label="number 2"
              >
                2
              </button>
              <button
                className="button-num"
                onClick={handleDisplayAddition}
                id="three"
                aria-label="number 3"
              >
                3
              </button>
            </div>
            <div className="button-row">
              <button
                className="button-op"
                onClick={handleDisplayAddition}
                id="xSquared"
                data-testid="xSquared"
                aria-label="square number"
              >
                x<sup>2</sup>
              </button>
              <button
                className="button-op"
                onClick={handleDisplayAddition}
                id="e"
                data-testid="e"
                aria-label="eulers number"
              >
                e
              </button>
              <button
                className="button-num"
                onClick={handleDisplayAddition}
                id="point"
                aria-label="decimal point"
              >
                .
              </button>
              <button
                className="button-num"
                onClick={handleDisplayAddition}
                id="zero"
                aria-label="number 0"
              >
                0
              </button>
              <button
                onClick={handleEval}
                data-testid="evaluate"
                className="button-equal"
                aria-label="calculate"
              >
                =
              </button>
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default Calculator;
