const part1 = {
  title: "Dividing by Zero",
  type: "video",
  partOrder: 1,
  videoURL: "",
  videoLessonPartDescription:
    "In this video, we'll explore the mathematical concept of division by zero, explaining some of the reasoning behind why it is undefined.",
};

export default part1;
