import React, { useState } from "react";
import { createAnswerPartState } from "../../shared/answerPartState";
import "./TableChoice.scss";

const TableChoice = ({
  rowChoices,
  colChoices,
  width,
  correctAnswers,
  updateAnswerState = () => {},
}) => {
  const [selections, setSelections] = useState(rowChoices.map(() => -1));

  const handleAnswerStateChange = (choices) => {
    const isCorrect =
      JSON.stringify(choices) === JSON.stringify(correctAnswers);
    let newState = {
      ...createAnswerPartState("TableChoice"),
      isCorrect,
    };
    console.log("all choices are correct:", newState?.isCorrect);
    updateAnswerState(newState);
  };

  const updateSelections = (row, col) => {
    let newSelections = [...selections];
    newSelections[row] = col;
    setSelections(newSelections);
    handleAnswerStateChange(newSelections);
  };

  const isSelected = (r, c) => {
    return selections[r] === c;
  };

  return (
    <div className="table-choice" style={{ width: `${width}px` }}>
      {rowChoices.map((rVal, rowIndex) => (
        <div className="table-choiceRow" key={`row_${rowIndex}`}>
          <div className="table-choiceRow-label">{rVal}</div>
          {colChoices.map((cVal, colIndex) => (
            <div key={`row_${rowIndex}_col_${colIndex}`}>
              {rowIndex === 0 ? (
                <div className="table-choiceCol-label">{cVal}</div>
              ) : (
                <div className="table-choiceCol-btnContainer">
                  <div
                    onClick={() => updateSelections(rowIndex, colIndex)}
                    className={`table-choice--btnBorder ${
                      isSelected(rowIndex, colIndex) ? "optionSelected" : ""
                    }`}
                  >
                    <div className={`table-choice--btn`} />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TableChoice;
