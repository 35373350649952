import { numWithSign } from "../../../../../../../../helpers/main";

const question2 = ({
  constantA,
  varA,
  coefficientA,
  coefficientB,
  coefficientC,
}) => ({
  questionConfig: [
    {
      type: "MultiInput",
      args: {
        size: "md",
        center: true,
        texBG: false,
        lineParts: [
          {
            type: "text",
            value:
              "Combine the like terms to create an equivalent expression: ",
            bold: true,
          },
          {
            type: "newline",
          },
          {
            type: "newline",
          },
          {
            type: "newline",
          },
          {
            type: "katex",
            value: `${coefficientA.displayValue}{\\textcolor{${varA.color}}{${varA.letterValue}}} ${numWithSign(constantA)} ${numWithSign(coefficientB)} (${coefficientC.displayValue}{\\textcolor{${varA.color}}{${varA.letterValue}}}) = `,
          },
          {
            type: "mathinput",
            size: "sm",
          },
        ],
      },
    },
  ],
});

const args = {
  constantA: { numberValue: 9, displayValue: "9" },
  varA: {
    letterValue: "n",
    color: "#5280b1",
  },
  coefficientA: { numberValue: 5, displayValue: "5" },
  coefficientB: { numberValue: 11, displayValue: "11" },
  coefficientC: { numberValue: -7, displayValue: "-7" },
};

export default question2(args);
