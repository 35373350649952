import part1 from "./parts/part1";
import part2 from "./parts/part2";

const lesson3 = {
  lessonName: "Combining Like Terms",
  lessonOrder: 3,
  lessonParts: [part1, part2],
};

export default lesson3;
