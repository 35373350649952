import { fracty } from "../../courseContent/helpers/fracty";
import { formatFractionForKaTeX } from "./main";

export const createCompoundIneqOptions = (
  args,
  katexAnswer,
  shuffleArrayFunc,
) => {
  const { constantA, constantB, constantC, varA } = args;

  const operIndex = Math.floor(Math.random() * 2) + 1;
  const operIndexB = Math.floor(Math.random() * 2) + 1;
  const operatorA = operIndexB === 1 ? `>` : `<`;
  const operatorB = operIndex === 1 ? `{\\leq}` : `{\\geq}`;
  const constValA =
    operIndex === 1
      ? formatFractionForKaTeX(fracty(constantA.numberValue - 1))
      : formatFractionForKaTeX(fracty(Math.floor(constantA.numberValue) + 2.5));

  // Generate potential incorrect options
  let incorrectOption1 = `${operIndexB !== 1 ? `${constantA.numberValue - 3} <` : ""}{\\textcolor{${varA.color}}{\\large{${varA.letterValue}}}} ${operatorA} ${constValA}`;
  let incorrectOption2 = `{\\textcolor{${varA.color}}{\\large{${varA.letterValue}}}}{\\space}${operatorB}{\\space}${formatFractionForKaTeX(fracty(constantC.numberValue))}`;

  // Check if the incorrect options are the same as the correct one and adjust if necessary
  while (
    incorrectOption1 === katexAnswer ||
    incorrectOption2 === katexAnswer ||
    incorrectOption1 === incorrectOption2
  ) {
    // Regenerate the incorrect options until they are unique and not equal to the correct answer
    incorrectOption1 = `{\\textcolor{${varA.color}}{\\large{${varA.letterValue}}}} ${operatorA} ${formatFractionForKaTeX(fracty(constantB.numberValue + Math.random() * 5 - 2.5))}`;
    incorrectOption2 = `{\\textcolor{${varA.color}}{\\large{${varA.letterValue}}}}{\\space}${operatorB}{\\space}${formatFractionForKaTeX(fracty(constantC.numberValue + Math.random() * 3 - 1.5))}`;
  }

  const allOptions = [];

  // Conditionally add the "No solutions" and "All values are solutions" options
  if (!katexAnswer.toLowerCase().includes("no solutions")) {
    allOptions.push({
      type: "katex",
      correctAnswer: false,
      className: "katexOption",
      value: `{\\text{No solutions}}`,
    });
  }

  if (!katexAnswer.toLowerCase().includes("are solutions")) {
    allOptions.push({
      type: "katex",
      correctAnswer: false,
      className: "katexOption",
      value: `{\\text{All values of}} {\\space} {\\textcolor{${varA.color}}{\\large{${varA.letterValue}}}} {\\space} {\\text{are solutions}}`,
    });
  }

  // Always add the correct answer and some incorrect options
  allOptions.push(
    {
      type: "katex",
      correctAnswer: true,
      value: katexAnswer,
      className: "katexOption",
    },
    {
      type: "katex",
      correctAnswer: false,
      value: incorrectOption1,
      className: "katexOption",
    },
    {
      type: "katex",
      correctAnswer: false,
      value: incorrectOption2,
      className: "katexOption",
    },
  );

  // Shuffle the options to randomize their order
  const shuffledOptions = shuffleArrayFunc(allOptions);
  return shuffledOptions;
};
