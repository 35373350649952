const getProgress = (progress) => {
  let totalParts = 0;
  let completedParts = 0;
  let fluentParts = 0;
  const unitProgress = [];

  Object.values(progress).forEach((course) => {
    course.units.forEach((unit, unitIndex) => {
      let unitTotalParts = 0;
      let unitCompletedParts = 0;
      let unitFluentParts = 0;

      unit.lessons.forEach((lesson) => {
        lesson.parts.forEach((part) => {
          totalParts++;
          unitTotalParts++;
          // Check for video completion
          if (part.type === "video" && part.videoProgress === 100) {
            completedParts++;
            unitCompletedParts++;
            fluentParts++; // For videos, completion is the same as fluency
            unitFluentParts++;
          }
          // Check for quiz completion and fluency
          if (part.type === "quiz") {
            const correctLastAttempt = part.lastAnswers.every(
              (answer) => answer === 1,
            );
            const firstAttemptCorrect =
              part.answerHistory.length === 0
                ? correctLastAttempt
                : part.answerHistory[0].every((answer) => answer === 1);
            // Completion check
            if (correctLastAttempt) {
              completedParts++;
              unitCompletedParts++;
            }
            // Fluency check: correct on the first attempt and no more than two attempts
            if (firstAttemptCorrect && part.answerHistory.length <= 1) {
              fluentParts++;
              unitFluentParts++;
            }
          }
        });
      });

      unitProgress.push({
        unitIndex: unitIndex + 1,
        completionPercentage:
          unitTotalParts === 0
            ? 0
            : ((unitCompletedParts / unitTotalParts) * 100).toFixed(2),
        fluencyPercentage:
          unitTotalParts === 0
            ? 0
            : ((unitFluentParts / unitTotalParts) * 100).toFixed(2),
      });
    });
  });

  return {
    overallProgress: {
      completionPercentage:
        totalParts === 0 ? 0 : ((completedParts / totalParts) * 100).toFixed(2),
      fluencyPercentage:
        totalParts === 0 ? 0 : ((fluentParts / totalParts) * 100).toFixed(2),
    },
    unitProgress,
  };
};

// vns = "video not started"
// vs = "video started"
// vc = "video complete"
// qns = "quiz never attempted"
// qa = "last quiz attempted but not 100%"
// p = "passed last 1 quiz attempt"
// pc = "proficient - last two quiz attempts 100%"
// fl  = "fluent - last 3 quiz attempts 100%"
export default getProgress;
