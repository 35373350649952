// compositeManager.js
let usedComposites = []; // Array to store composites used in various questions

const isComposite = (num) => {
  for (let i = 2, s = Math.sqrt(num); i <= s; i++) {
    if (num % i === 0) return num > 1;
  }
  return false;
};

export const getRandomComposite = (min, max) => {
  let composite = 0;
  while (!composite) {
    let num = Math.floor(Math.random() * (max - min + 1)) + min;
    if (isComposite(num) && !usedComposites.includes(num)) {
      composite = num;
    }
  }
  usedComposites.push(composite);
  return composite;
};
