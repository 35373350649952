import React from "react";

const CalcIcon = ({ fill = "#000000" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="900pt"
      height="1320pt"
      viewBox="0 0 900 1320"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0,1320) scale(0.1,-0.1)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M2210 12728 c-160 -11 -409 -64 -500 -106 -25 -12 -64 -27 -87 -35
-81 -27 -288 -159 -413 -263 -137 -113 -314 -311 -375 -418 -16 -29 -36 -60
-43 -68 -23 -28 -110 -205 -136 -277 -14 -38 -34 -112 -46 -163 -11 -51 -29
-122 -38 -158 -16 -61 -17 -356 -20 -4517 -1 -2831 1 -4497 7 -4575 6 -68 17
-150 25 -183 32 -137 39 -164 64 -240 73 -233 326 -605 504 -743 29 -22 67
-55 83 -72 32 -34 110 -88 185 -128 25 -14 52 -30 60 -37 27 -23 183 -97 255
-122 139 -47 325 -84 475 -94 125 -8 4574 -7 4620 1 131 23 271 58 355 87 39
14 84 28 100 32 35 9 30 6 210 113 77 45 158 99 180 118 22 20 79 69 127 109
107 88 243 250 341 406 91 146 156 293 190 435 46 186 56 246 64 370 13 215
10 8738 -4 8900 -17 206 -87 461 -170 620 -98 185 -195 319 -339 465 -157 160
-222 212 -380 304 -33 19 -66 39 -74 46 -14 11 -173 81 -235 104 -70 26 -287
73 -385 83 -130 14 -4410 20 -4600 6z m4705 -470 c103 -28 265 -89 323 -124
134 -78 198 -129 318 -250 79 -79 147 -158 169 -196 21 -35 44 -70 51 -78 40
-47 113 -224 140 -340 46 -191 44 -48 41 -4705 -3 -2831 -8 -4411 -14 -4450
-32 -192 -104 -384 -198 -525 -38 -56 -125 -159 -190 -225 -66 -65 -190 -175
-199 -175 -3 0 -50 -26 -103 -58 -54 -32 -123 -66 -153 -76 -30 -10 -59 -22
-65 -26 -5 -5 -28 -11 -50 -15 -22 -4 -62 -13 -90 -21 -27 -7 -111 -18 -184
-24 -179 -13 -4331 -14 -4488 0 -122 11 -243 37 -343 74 -72 26 -167 72 -190
91 -8 7 -38 25 -66 39 -28 15 -62 38 -75 52 -13 15 -50 46 -81 71 -64 49 -215
219 -265 298 -104 164 -165 327 -200 535 -11 69 -15 8750 -4 8910 9 121 26
209 70 358 14 46 96 207 126 246 14 18 25 36 25 40 0 17 94 127 182 212 97 95
189 169 248 201 19 10 53 31 75 45 84 57 316 125 475 142 19 1 1066 2 2325 2
l2290 -2 100 -26z"
        />
        <path
          d="M2160 11600 c-145 -13 -289 -82 -402 -194 -82 -80 -165 -195 -178
-247 -5 -19 -17 -59 -27 -89 -16 -51 -18 -140 -21 -1265 -3 -1261 -1 -1367 38
-1488 11 -32 41 -90 67 -129 113 -168 264 -281 433 -324 73 -18 152 -19 2415
-19 2270 0 2342 1 2415 19 94 24 137 45 226 112 158 119 261 276 291 447 15
81 17 229 21 1242 4 1202 1 1280 -39 1436 -43 163 -204 352 -369 434 -30 15
-84 36 -120 48 l-65 22 -2300 1 c-1265 1 -2338 -2 -2385 -6z m4691 -352 c20
-10 50 -28 65 -41 16 -13 36 -26 45 -30 9 -3 38 -37 63 -75 80 -119 77 -58 73
-1407 -2 -649 -6 -1196 -10 -1215 -19 -96 -120 -220 -217 -268 l-55 -27 -2310
-3 c-2050 -2 -2315 0 -2362 13 -82 24 -135 64 -214 162 -5 7 -19 30 -32 50
l-22 38 0 1260 c0 1433 -6 1327 83 1421 66 69 112 102 173 124 53 19 103 19
2369 17 2162 -2 2317 -3 2351 -19z"
        />
        <path
          d="M4935 7055 c-22 -8 -50 -15 -62 -15 -40 0 -160 -97 -196 -159 -63
-105 -62 -92 -62 -1006 0 -659 3 -843 14 -892 16 -75 88 -185 142 -217 20 -11
47 -28 60 -38 50 -34 150 -38 981 -38 885 0 900 1 980 56 57 39 119 104 139
145 43 87 44 116 44 989 0 924 1 913 -62 1004 -71 101 -143 148 -266 171 -115
22 -1651 22 -1712 0z m1662 -336 c15 -5 31 -16 35 -22 12 -19 10 -1625 -2
-1648 -10 -19 -30 -19 -835 -19 -805 0 -825 0 -835 19 -7 13 -10 291 -10 830
0 798 0 811 20 831 20 20 33 20 809 20 533 0 799 -3 818 -11z"
        />
        <path
          d="M6065 6261 c-22 -10 -85 -64 -139 -119 -69 -70 -104 -98 -115 -94 -9
3 -59 45 -112 93 -53 48 -111 94 -127 103 -64 33 -128 9 -156 -59 -12 -27 -12
-41 -2 -74 14 -47 29 -65 129 -151 42 -36 79 -76 83 -88 6 -20 -7 -38 -83
-115 -110 -112 -123 -131 -123 -179 0 -30 8 -47 34 -76 31 -33 40 -37 80 -36
40 1 53 8 112 58 37 32 84 79 106 106 21 26 45 51 54 54 11 4 55 -28 140 -104
115 -101 128 -110 165 -110 88 0 140 62 119 142 -11 40 -73 108 -176 192 -69
57 -68 62 41 167 134 130 162 192 113 254 -19 24 -73 55 -95 55 -4 0 -26 -9
-48 -19z"
        />
        <path
          d="M2132 7040 c-102 -15 -191 -68 -252 -150 -45 -60 -43 -57 -65 -160
-13 -64 -15 -192 -15 -855 0 -934 -1 -920 90 -1053 19 -28 44 -55 55 -61 11
-6 32 -20 47 -32 17 -14 60 -28 120 -40 85 -16 163 -18 874 -18 906 -1 900 -2
1013 76 77 53 125 119 147 202 12 46 14 202 14 916 0 921 0 918 -51 1002 -25
43 -89 113 -102 113 -5 0 -22 10 -39 21 -65 47 -100 48 -968 47 -448 0 -839
-4 -868 -8z m1692 -346 c3 -9 6 -384 6 -835 l0 -819 -24 -15 c-20 -14 -117
-15 -829 -13 -538 2 -809 6 -816 13 -7 7 -11 282 -11 835 -1 770 0 826 17 837
13 10 192 13 834 13 736 0 817 -2 823 -16z"
        />
        <path
          d="M2913 6351 c-48 -35 -53 -58 -53 -257 0 -158 20 -142 -185 -146 -128
-3 -143 -5 -172 -26 -45 -32 -62 -90 -40 -137 30 -62 53 -70 227 -73 103 -3
157 -8 162 -15 4 -7 8 -78 8 -159 0 -143 1 -147 26 -177 14 -17 33 -31 43 -31
9 0 22 -5 28 -11 17 -17 78 7 108 42 24 29 25 36 25 164 0 74 4 145 10 159
l10 26 153 0 c144 0 156 2 188 23 18 12 39 34 47 47 16 33 7 88 -21 119 -39
43 -76 51 -226 51 -101 0 -142 3 -148 13 -4 6 -10 87 -13 178 -6 185 -9 196
-79 225 -46 19 -52 18 -98 -15z"
        />
        <path
          d="M2106 4234 c-147 -27 -267 -131 -301 -260 -21 -82 -23 -1740 -2
-1821 31 -119 125 -222 238 -259 50 -17 113 -19 874 -22 537 -2 847 1 900 8
120 16 215 78 279 184 54 89 56 124 55 996 0 855 -3 910 -50 987 -64 103 -136
157 -248 184 -71 18 -129 19 -871 18 -642 -1 -810 -4 -874 -15z m1692 -341 c4
-10 7 -388 7 -841 0 -720 -2 -824 -15 -832 -18 -12 -1619 -13 -1637 -2 -31 20
-33 69 -33 850 0 853 -2 824 53 835 12 2 381 5 820 6 751 1 798 0 805 -16z"
        />
        <path
          d="M2555 3131 c-102 -31 -125 -152 -40 -210 l34 -23 443 1 c495 1 474
-1 511 73 14 29 15 41 6 69 -16 47 -43 74 -87 87 -40 11 -831 14 -867 3z"
        />
        <path
          d="M4912 4205 c-150 -33 -254 -139 -285 -290 -15 -71 -17 -170 -17 -865
0 -664 2 -799 16 -875 19 -111 56 -179 136 -245 97 -82 69 -80 1031 -80 526 0
854 4 888 10 78 15 175 83 226 157 73 107 73 103 73 1019 0 909 0 909 -71
1014 -59 88 -116 128 -218 155 -83 21 -1680 21 -1779 0z m1718 -344 c7 -13 10
-289 10 -826 0 -537 -3 -813 -10 -826 -10 -19 -30 -19 -834 -19 l-824 0 -11
31 c-8 22 -11 275 -11 823 0 700 2 794 16 814 l15 22 819 0 c800 0 820 0 830
-19z"
        />
        <path
          d="M5354 3399 c-67 -13 -104 -52 -106 -110 -2 -50 8 -73 44 -99 l31 -23
447 -1 447 0 36 27 c66 51 55 147 -23 192 -34 19 -51 20 -441 20 -224 0 -420
-3 -435 -6z"
        />
        <path
          d="M5314 2821 c-32 -15 -42 -26 -48 -51 -14 -72 -8 -96 34 -134 l40 -36
438 0 c416 0 439 1 469 20 34 20 63 66 63 98 0 42 -28 88 -65 105 -32 15 -79
17 -463 17 -395 0 -430 -2 -468 -19z"
        />
      </g>
    </svg>
  );
};

export default CalcIcon;
