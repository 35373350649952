import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, signOut } from "aws-amplify/auth";
import { useAuth } from "../../context/AuthContext";
import logo from "../../stories/assets/logo.svg";
import logoText from "../../stories/assets/logo-text.svg";
import "./Header.scss";

const Header = () => {
  const navigate = useNavigate();
  const { isUserLoggedIn, setIsUserLoggedIn } = useAuth();

  useEffect(() => {
    checkCurrentUser();
  }, []);

  const checkCurrentUser = async () => {
    try {
      await getCurrentUser();
      setIsUserLoggedIn(true); // User is logged in
    } catch (error) {
      setIsUserLoggedIn(false); // User is not logged in
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      setIsUserLoggedIn(false);
      navigate("/signin");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <nav className="am-header">
      {/* Logo or Brand Name */}
      <Link to="/home?page=courses" className="logo-wrapper">
        <div className="logo-wrapper--logo">
          <img src={logo} />
        </div>
        <img className="logo-wrapper--text" src={logoText} />
      </Link>

      {/* Conditional Rendering for Auth Links */}
      <div className="auth-buttons">
        {isUserLoggedIn ? (
          <>
            <Button
              className="am-header--link"
              type="link"
              onClick={handleLogout.bind(this)}
            >
              Log Out
            </Button>
            {/* Other user-specific links or dropdowns */}
          </>
        ) : (
          <>
            <Link className="am-header--link" to="/about">
              <Button type="link">About</Button>
            </Link>
            <Link className="am-header--link" to="/signin">
              <Button type="link">Log In</Button>
            </Link>
            <div className="am-header--signup">
              <Link className="am-header--link" to="/signup">
                <Button type="link">Sign Up</Button>
              </Link>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Header;
