import unit1Lessons from "./lessons";

const unit1 = {
  unitOrder: 1,
  unitName: "Fundamentals of Algebraic Equations",
  unitDescription:
    "In this first unit of the course, we'll cover the basics of algebra focusing on variables, expressions, and equations.",
  unitLogo: {
    url: "",
  },
  lessons: unit1Lessons,
};

export default unit1;
