import {
  getFractionWithVarCoefficientSign,
  numWithSign,
} from "../../../../../../../../helpers/main";

const question3 = ({
  constantA,
  varA,
  varB,
  coefficientA,
  coefficientB,
  numeratorA,
  signA,
  signB,
}) => ({
  questionConfig: [
    {
      type: "MultiInput",
      args: {
        size: "md",
        center: true,
        texBG: false,
        lineParts: [
          {
            type: "text",
            value: "Evaluate ",
          },
          {
            type: "katex",
            value: `${getFractionWithVarCoefficientSign(coefficientA, numeratorA, true, true)}\\frac{${Math.abs(numeratorA?.displayValue)}}{\\textcolor{${varA.color}}{${varA.letterValue}}} ${numWithSign(coefficientB)}{\\textcolor{${varB.color}}{${varB.letterValue}}} ${numWithSign(constantA)}`,
          },
          {
            type: "text",
            value: " when ",
          },
          {
            type: "newline",
          },
          {
            type: "katex",
            value: `{\\textcolor{${varA.color}}{${varA.letterValue}}} = ${varA.displayValue}`,
          },
          {
            type: "text",
            value: ", ",
          },
          {
            type: "katex",
            value: `{\\textcolor{${varB.color}}{${varB.letterValue}}} = ${varB.displayValue}`,
          },
          {
            type: "text",
            value: ".",
          },
          {
            type: "newline",
          },
          {
            type: "textfield",
            width: 30,
            size: "sm",
          },
        ],
      },
    },
  ],
});

const args = {
  constantA: { numberValue: 10, displayValue: "10" },
  numeratorA: { numberValue: 9, displayValue: "9" },
  varA: {
    numberValue: 3,
    displayValue: "3",
    letterValue: "g",
    color: "#5280b1",
  },
  varB: {
    numberValue: 6,
    displayValue: "6",
    letterValue: "h",
    color: "#b86f65",
  },
  coefficientA: { numberValue: 1, displayValue: "1" },
  coefficientB: { numberValue: 2, displayValue: "2" },
};

export default question3(args);
