import React from "react";

const PencilIcon = ({ fill = "#000000" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="900.000000pt"
      height="618.000000pt"
      viewBox="0 0 900.000000 618.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,618.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M7785 5910 c-115 -22 -164 -39 -261 -95 -60 -34 -291 -261 -1762
-1730 -932 -929 -1703 -1703 -1714 -1718 -25 -34 -136 -318 -192 -492 -7 -22
-39 -107 -71 -190 -32 -82 -63 -168 -71 -190 -7 -22 -22 -65 -34 -95 -58 -148
-82 -243 -77 -315 4 -67 31 -139 69 -185 30 -37 87 -69 148 -85 56 -15 76 -15
139 -5 41 7 111 25 155 42 45 16 95 33 111 39 17 5 39 13 50 19 11 5 38 16 60
24 87 30 271 98 495 181 129 48 254 95 278 104 36 13 297 270 1570 1546 840
841 1575 1581 1634 1645 124 132 112 120 161 161 92 77 187 241 221 381 22 94
21 281 -3 373 -25 98 -92 230 -154 306 -131 158 -290 249 -496 283 -115 19
-130 19 -256 -4z m257 -296 c95 -24 171 -69 238 -141 68 -72 95 -119 121 -213
43 -147 7 -319 -89 -431 -18 -21 -39 -39 -45 -39 -16 0 -687 672 -687 687 0
16 63 69 113 95 107 56 234 71 349 42z m-334 -697 c210 -210 332 -340 332
-352 0 -28 -2948 -2975 -2975 -2975 -13 0 -84 64 -195 176 -96 97 -193 189
-214 205 -110 82 -261 -20 -226 -152 10 -37 38 -70 177 -210 106 -107 164
-173 161 -184 -1 -9 -23 -25 -48 -35 -57 -23 -425 -160 -459 -170 -14 -5 -71
-25 -126 -45 -55 -20 -122 -45 -150 -55 -36 -13 -55 -16 -68 -8 -24 13 -21 32
33 173 23 61 57 151 75 200 18 50 58 160 90 245 31 85 72 196 90 245 47 130
74 198 83 212 25 39 3057 3063 3071 3063 9 0 166 -150 349 -333z"
        />
        <path
          d="M2290 3105 c-25 -8 -55 -15 -68 -15 -12 0 -30 -4 -40 -9 -9 -5 -52
-24 -95 -41 -43 -17 -93 -40 -110 -50 -18 -11 -41 -24 -52 -29 -28 -12 -179
-107 -240 -149 -125 -89 -207 -148 -225 -165 -8 -8 -37 -31 -65 -51 -27 -21
-72 -57 -100 -81 -27 -24 -69 -59 -92 -77 -38 -30 -123 -103 -255 -221 -93
-84 -508 -488 -645 -629 -138 -143 -153 -166 -153 -240 0 -58 1 -60 51 -109
48 -46 53 -49 104 -49 75 0 118 25 201 117 132 148 250 266 449 452 110 102
218 204 240 227 22 22 80 74 128 115 49 41 141 118 204 172 64 53 119 97 123
97 4 0 18 10 31 23 96 88 337 253 456 312 29 15 55 30 58 36 4 5 16 9 29 9 12
0 27 5 34 12 20 20 114 28 140 12 26 -17 30 -68 7 -98 -8 -11 -15 -27 -15 -36
0 -44 -128 -264 -272 -465 -16 -22 -36 -51 -46 -65 -14 -22 -200 -275 -257
-352 -11 -14 -49 -63 -85 -109 -75 -94 -102 -130 -147 -191 -18 -23 -44 -59
-58 -78 -15 -19 -49 -66 -76 -105 -27 -38 -52 -74 -57 -80 -43 -56 -172 -309
-172 -340 -1 -11 -7 -31 -15 -45 -20 -35 -20 -170 0 -196 8 -10 15 -24 15 -31
0 -24 60 -99 102 -129 79 -57 100 -64 198 -64 104 0 159 15 265 70 74 39 280
174 291 191 3 5 24 22 47 36 23 14 60 43 82 64 22 21 63 55 91 76 27 21 68 54
90 74 21 21 62 55 89 77 28 22 64 54 80 72 17 17 50 46 75 63 25 17 69 52 97
78 59 53 175 133 216 148 44 17 46 -12 6 -79 -5 -8 -12 -22 -15 -30 -3 -8 -10
-22 -15 -30 -5 -8 -14 -26 -19 -39 -6 -12 -26 -47 -45 -77 -61 -94 -154 -240
-172 -269 -7 -11 -15 -27 -18 -35 -4 -8 -10 -22 -15 -30 -10 -17 -48 -95 -60
-125 -5 -11 -11 -31 -14 -45 -2 -14 -10 -44 -17 -67 -11 -36 -10 -49 7 -100
21 -63 94 -153 124 -153 10 0 23 -6 29 -14 6 -8 26 -16 44 -18 18 -3 53 -11
79 -19 39 -11 56 -11 90 -1 24 7 59 17 78 21 32 7 125 50 190 87 14 8 52 35
85 59 33 25 83 60 110 79 61 41 69 50 84 98 10 30 10 46 0 75 -14 40 -75 113
-95 113 -6 0 -17 7 -24 15 -16 19 -70 19 -96 0 -10 -8 -26 -15 -34 -15 -8 0
-44 -29 -80 -63 -78 -75 -173 -147 -195 -147 -8 0 -15 1 -15 3 0 9 104 204
115 217 20 22 115 179 115 190 0 4 7 10 15 14 8 3 15 11 15 17 0 7 15 44 34
83 64 135 98 288 77 346 -6 18 -11 44 -11 58 0 58 -119 192 -170 192 -10 0
-22 5 -25 10 -10 16 -164 12 -215 -6 -127 -45 -270 -131 -421 -252 -101 -82
-275 -230 -289 -247 -8 -10 -44 -42 -80 -70 -36 -29 -86 -73 -113 -99 -26 -25
-64 -57 -85 -70 -20 -13 -46 -33 -57 -45 -27 -31 -174 -131 -247 -169 -79 -41
-67 28 31 178 19 30 40 63 46 72 24 40 93 139 145 208 30 41 62 84 70 96 8 11
38 50 65 86 146 189 405 538 405 546 0 4 14 21 30 37 17 16 30 33 30 38 0 5
14 26 30 47 17 21 30 40 30 43 0 3 17 32 39 64 41 62 96 161 144 258 17 33 36
72 43 87 8 14 14 35 14 46 0 12 5 24 10 27 6 3 10 18 10 33 0 16 7 54 15 86
34 130 -33 297 -155 389 -84 63 -223 91 -310 62z"
        />
      </g>
    </svg>
  );
};

export default PencilIcon;
