const unit10 = {
  unitOrder: 10,
  unitName: "Quadratics",
  unitDescription:
    "This unit we'll learn to recognize and analyze the standard form of quadratics, explore methods for solving them, including factoring, completing the square, and using the quadratic formula.",
  unitLogo: {
    url: "",
  },
  lessons: [],
};

export default unit10;
