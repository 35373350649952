import unit2Lessons from "./lessons";

const unit2 = {
  unitOrder: 2,
  unitName: "Number Theory Foundations",
  unitDescription:
    "This unit explores the foundational concepts of prime numbers, factors, and multiples. We'll also review factorization, which is great to know when solving algebraic equations.",
  unitLogo: {
    url: "",
  },
  lessons: unit2Lessons,
};

export default unit2;
