import { numWithSign } from "../../../../../../../../helpers/main";

const question1 = ({ varA, coefficientA }) => ({
  questionConfig: [
    {
      type: "MultiInput",
      args: {
        size: "md",
        center: true,
        texBG: false,
        lineParts: [
          {
            type: "text",
            value:
              "Combine the like terms to create an equivalent expression: ",
            bold: true,
          },
          {
            type: "newline",
          },
          {
            type: "newline",
          },
          {
            type: "newline",
          },
          {
            type: "katex",
            value: `{\\textcolor{${varA.color}}{${varA.letterValue}}} ${numWithSign({ numberValue: 1 })} (${coefficientA.displayValue}{\\textcolor{${varA.color}}{${varA.letterValue}}}) = `,
          },
          {
            type: "mathinput",
          },
        ],
      },
    },
  ],
});

const args = {
  varA: {
    letterValue: "r",
    color: "#5280b1",
  },
  coefficientA: { numberValue: -5, displayValue: "-5" },
};

export default question1(args);
