export const drawParabolaThroughPoints = (
  paper,
  points,
  lineColor,
  gridOffset,
  isPiecewise,
  sectionIndex,
  totalSections,
) => {
  const { yAxisXOffsetFromLeft, xAxisYOffsetFromTop } = gridOffset;
  const gridXOffsetValue = -25 * yAxisXOffsetFromLeft;
  const gridYOffsetValue = -25 * xAxisYOffsetFromTop;

  const [pOne, pTwo] = points;
  let [h, k] = pOne;
  let [x2, y2] = pTwo;
  // Calculate 'a' using the vertex (h, k) and another point (x2, y2)
  const a = (y2 - k) / Math.pow(x2 - h, 2);

  // Determine the range of x values in graph coordinates
  const leftX = isPiecewise ? h : (0 - gridXOffsetValue) / 25; // Convert pixel boundaries to graph coordinates
  const rightX = isPiecewise ? x2 : (500 - gridXOffsetValue) / 25;

  let path = "";
  let firstPx, firstPy;
  for (let x = leftX; x <= rightX; x += 0.1) {
    // Adjust the step size as needed for smoothness
    const y = a * Math.pow(x - h, 2) + k;
    // Convert back to pixel coordinates for plotting
    let px = gridXOffsetValue + x * 25;
    let py = gridYOffsetValue - y * 25;

    if (path === "") {
      firstPx = px; // Store the first point
      firstPy = py;
    }

    // Move to start or line to continue
    path += path === "" ? `M${px},${py}` : `L${px},${py}`;
  }

  // Draw the parabola
  paper.path(path).attr({
    stroke: lineColor,
    "stroke-width": 2,
  });

  // Add a small filled circle at the start or end if it's the first or last section respectively
  const radius = 5; // Adjust the radius of the circle as needed
  if (sectionIndex === 0) {
    // First section
    paper.circle(firstPx, firstPy, radius).attr({
      fill: lineColor,
      stroke: lineColor,
    });
  }
  if (sectionIndex === totalSections - 1) {
    // Last section
    // Convert the last graph coordinate back to pixels for the circle
    let lastPx = gridXOffsetValue + rightX * 25;
    let lastY = a * Math.pow(rightX - h, 2) + k;
    let lastPy = gridYOffsetValue - lastY * 25;
    paper.circle(lastPx, lastPy, radius).attr({
      fill: lineColor,
      stroke: lineColor,
    });
  }
};
