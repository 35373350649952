function shuffleArray(originalArray) {
  let array = originalArray.slice();
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const generateIncorrectAnswerKaTeXs = (
  varA,
  correctCoefficient,
  correctConstant,
) => {
  const cVal = Math.abs(correctConstant);
  const options = [
    {
      ic: false,
      katexString: `${correctCoefficient + 1 === 0 ? "" : correctCoefficient + 1}{\\textcolor{${varA?.color}}{${varA?.letterValue}}} + ${cVal + 1 === 0 ? cVal + 2 : cVal + 1}`,
    },
    {
      ic: false,
      katexString: `${correctCoefficient === 0 ? "" : correctCoefficient}{\\textcolor{${varA?.color}}{${varA?.letterValue}}} - ${cVal + 1 === 0 ? cVal + 2 : cVal + 1}`,
    },
    {
      ic: false,
      katexString: `${correctCoefficient - 1 === 0 ? "" : correctCoefficient - 1}{\\textcolor{${varA?.color}}{${varA?.letterValue}}} ${correctConstant === 0 ? "" : `${correctConstant > 0 ? `+ ${correctConstant}` : correctConstant}`}`,
    },
  ];
  return options;
};

export const generateCorrectAnswerKaTeX = ({
  constantA,
  constantC,
  varA,
  constantB,
  coefficientA,
}) => {
  const constANum = constantA.numberValue;
  const constBNum = constantC.numberValue;
  const coefANum = constantB.numberValue;
  const coefBNum = coefficientA.numberValue;

  const calculatedCoefficient = Math.round(coefANum * coefBNum * 100) / 100;
  const foilBVal = Math.round(coefANum * constBNum * 100) / 100;
  const calculatedConstant = Math.round(constANum * 100) / 100 + foilBVal;
  const katexString = `${calculatedCoefficient === 0 ? "" : calculatedCoefficient}{\\textcolor{${varA.color}}{${varA.letterValue}}} ${calculatedConstant === 0 ? "" : `${calculatedConstant > 0 ? `+ ${calculatedConstant}` : calculatedConstant}`} `;

  return {
    katexString: katexString,
    correctCoefficient: calculatedCoefficient,
    correctConstant: calculatedConstant,
  };
};

const hideZero = (numObj) => {
  return numObj?.displayValue === "0" ? "" : numObj?.displayValue;
};

const formatNumStr = (numObj) => {
  if (numObj.numberValue === 0) {
    return "";
  } else {
    return hideZero(numObj);
  }
};

const correctIndices = (array) => {
  return array
    .map((obj, index) => (obj.ic === true ? index : -1))
    .filter((index) => index !== -1);
};

export const createUnsimplifiedExpression = (argParams = {}) => {
  const { constantA, constantC, varA, constantB, coefficientA } = argParams;
  const hideParens =
    constantA?.numberValue === 0 && constantB?.numberValue === 0;
  return `${hideZero(constantA)} ${constantB?.numberValue > 0 ? "+" : ""} ${formatNumStr(constantB)}${hideParens ? "" : "("}${coefficientA?.displayValue}{\\textcolor{${varA?.color}}{${varA?.letterValue}}} ${hideZero(coefficientA) === "" ? "" : `${constantC?.numberValue > 0 ? "+" : ""} ${formatNumStr(constantC)}`}${hideParens ? "" : ")"}`;
};

const question3 = (questionArgs) => {
  const { katexString, correctCoefficient, correctConstant } =
    generateCorrectAnswerKaTeX(questionArgs);
  const incorrectOptions = generateIncorrectAnswerKaTeXs(
    questionArgs?.varA,
    correctCoefficient,
    correctConstant,
  );
  const unshuffledOptions = [{ katexString, ic: true }, ...incorrectOptions];
  const questionOptions = shuffleArray(unshuffledOptions);
  const answerIndicies = correctIndices(questionOptions);

  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: "Simplify to create an equivalent expression.",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "katex",
              value: createUnsimplifiedExpression(questionArgs),
            },
          ],
        },
      },
      {
        type: "ChoiceInput",
        args: {
          heading: "Select one:",
          answerIndicies,
          options: questionOptions.map((option) => ({
            type: "mathText",
            lineParts: [
              {
                type: "katex",
                value: option?.katexString,
              },
            ],
          })),
        },
      },
    ],
  };
};

const args = {
  constantA: { numberValue: 5, displayValue: "5" },
  constantB: { numberValue: -2, displayValue: "-2" },
  constantC: { numberValue: -3, displayValue: "-3" },
  varA: {
    letterValue: "x",
    color: "#5280b1",
  },
  coefficientA: { numberValue: -11, displayValue: "-11" },
};

export default question3(args);
