import React from "react";
import { TextField, Switch, FormControlLabel, Button } from "@mui/material";

const CourseDetailsForm = ({ courseData, updateCourseData, handleNext }) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    updateCourseData({
      course: { ...courseData.course, [name]: newValue },
    });
  };

  return (
    <form>
      <TextField
        label="Course Name"
        name="courseName"
        value={courseData.course.courseName}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Course Description"
        name="courseDescription"
        value={courseData.course.courseDescription}
        onChange={handleChange}
        fullWidth
        multiline
        rows={4}
        margin="normal"
      />
      <FormControlLabel
        control={
          <Switch
            checked={courseData.course.isActive}
            onChange={handleChange}
            name="isActive"
          />
        }
        label="Is Active"
        labelPlacement="start"
      />
    </form>
  );
};

export default CourseDetailsForm;
