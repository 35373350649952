const part1 = {
  title: "Single Variable Inequalities",
  type: "video",
  partOrder: 1,
  videoURL: "",
  videoLessonPartDescription:
    "The basics of inequalities and how they differ from equations. Learn the symbols used in inequalities, such as greater than, less than, and not equal to. ",
};

export default part1;
