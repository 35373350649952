const part2 = {
  title: "Single Variable Equation Solutions",
  type: "video",
  partOrder: 2,
  videoURL: "",
  videoLessonPartDescription:
    "Discover what it means to solve a single-variable equation with a variable on both sides.",
};

export default part2;
