const question6 = ({ constantA, varA, numeratorA }) => ({
  questionConfig: [
    {
      type: "MultiInput",
      args: {
        size: "md",
        texBG: false,
        lineParts: [
          {
            type: "text",
            value: "What is ",
          },
          {
            type: "katex",
            value: `${constantA?.displayValue} ${numeratorA?.numberValue >= 0 ? "+" : "-"} \\frac{${Math.abs(numeratorA?.numberValue)}}{\\textcolor{${varA?.color}}{${varA?.letterValue}}}`,
          },
          {
            type: "text",
            value: " when ",
          },
          {
            type: "katex",
            value: `\\textcolor{${varA?.color}}{${varA?.letterValue}}`,
          },
          {
            type: "text",
            value: " = ",
          },
          {
            type: "katex",
            value: `${varA?.displayValue}`,
          },
          {
            type: "text",
            value: "?",
          },
          {
            type: "newline",
          },
          {
            type: "textfield",
            width: 30,
            size: "sm",
          },
        ],
      },
    },
  ],
});

const args = {
  constantA: { numberValue: 11, displayValue: "11" },
  varA: {
    numberValue: 5,
    displayValue: "5",
    letterValue: "y",
    color: "#5280b1",
  },
  numeratorA: { numberValue: 4, displayValue: "4" },
};

export default question6(args);
