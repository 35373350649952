import part1 from "./parts/part1";
import part2 from "./parts/part2";

const lesson1 = {
  lessonName: "Intro to Single Variable Inequalities",
  lessonOrder: 1,
  lessonParts: [part1, part2],
};

export default lesson1;
