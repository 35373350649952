import React, { useState } from "react";
import { TextField, Button } from "@mui/material";

const LessonForm = ({
  courseData,
  updateCourseData,
  handleNext,
  handleBack,
}) => {
  const [lesson, setLesson] = useState({
    lessonName: "",
    lessonOrder: 0, // Default order, might be auto-calculated or adjusted based on existing lessons
  });

  const handleLessonChange = (e) => {
    const { name, value } = e.target;
    setLesson({ ...lesson, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to add the lesson to the current section in courseData
    // updateCourseData logic goes here
    handleNext();
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Lesson Name"
        name="lessonName"
        value={lesson.lessonName}
        onChange={handleLessonChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Lesson Order"
        name="lessonOrder"
        value={lesson.lessonOrder}
        type="number"
        onChange={handleLessonChange}
        fullWidth
        margin="normal"
      />
    </form>
  );
};

export default LessonForm;
