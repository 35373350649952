export const drawLineThroughPoints = (
  paper,
  points,
  lineColor,
  gridOffset,
  isPiecewise,
  sectionIndex,
  totalSections,
) => {
  const { yAxisXOffsetFromLeft, xAxisYOffsetFromTop } = gridOffset;
  const gridXOffsetValue = -25 * yAxisXOffsetFromLeft;
  const gridYOffsetValue = -25 * xAxisYOffsetFromTop;

  const [pOne, pTwo] = points;
  let [h, k] = pOne;
  let [x2, y2] = pTwo;
  // Convert coordinates to pixels (similar to how you did with points)
  let px1 = gridXOffsetValue + h * 25,
    py1 = gridYOffsetValue - k * 25;
  let px2 = gridXOffsetValue + x2 * 25,
    py2 = gridYOffsetValue - y2 * 25;

  // Check for vertical line to avoid division by zero
  if (isPiecewise) {
    paper.path(`M${px1},${py1}L${px2},${py2}`).attr({
      stroke: lineColor,
      "stroke-width": 2,
    });
  } else if (px1 === px2) {
    // The line is vertical
    paper.path(`M${px1},0L${px1},500`).attr({
      stroke: lineColor,
      "stroke-width": 2,
    });
  } else {
    // Calculate slope (m) and y-intercept (b)
    const m = (py2 - py1) / (px2 - px1);
    const b = py1 - m * px1;

    // Determine intersection points with plot borders
    let intersections = [];

    // Left border (x = 50)
    let leftY = m + b;
    if (leftY >= 0 && leftY <= 500) intersections.push([0, leftY]);

    // Right border (x = 450)
    let rightY = m * 500 + b;
    if (rightY >= 0 && rightY <= 500) intersections.push([500, rightY]);

    // Top border (y = 50), solving for x using y = mx + b
    let topX = (0 - b) / m;
    if (topX >= 0 && topX <= 500) intersections.push([topX, 0]);

    // Bottom border (y = 450)
    let bottomX = (500 - b) / m;
    if (bottomX >= 0 && bottomX <= 500) intersections.push([bottomX, 500]);

    // Draw the line through calculated points
    if (intersections.length >= 2) {
      const [startX, startY] = intersections[0];
      const [endX, endY] = intersections[1];
      paper.path(`M${startX},${startY}L${endX},${endY}`).attr({
        stroke: lineColor,
        "stroke-width": 2,
      });
    }
  }

  // Add a small filled circle at the start or end if it's the first or last section respectively
  const radius = 5; // Adjust the radius of the circle as needed
  if (sectionIndex === 0) {
    // First section
    paper.circle(px1, py1, radius).attr({
      fill: lineColor,
      stroke: lineColor,
    });
  }
  if (sectionIndex === totalSections - 1) {
    // Last section
    paper.circle(px2, py2, radius).attr({
      fill: lineColor,
      stroke: lineColor,
    });
  }
};
