const unit9 = {
  unitOrder: 9,
  unitName: "Sequences",
  unitDescription:
    "In this unit, we'll explore the world of sequences, delving into the patterns and properties that define arithmetic and geometric sequences. ",
  unitLogo: {
    url: "",
  },
  lessons: [],
};

export default unit9;
