import { getFractionString } from "../../../../../../../../helpers/main";

const question4 = ({ numeratorA, denominatorA, varA }) => {
  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: "Evaluate ",
            },
            {
              type: "katex",
              value: getFractionString(numeratorA, denominatorA, true),
            },
            {
              type: "katex",
              value: `\\textcolor{${varA?.color}}{${varA?.letterValue}}`,
            },
            {
              type: "text",
              value: " when ",
            },
            {
              type: "katex",
              value: `\\textcolor{${varA?.color}}{${varA?.letterValue}} `,
            },
            {
              type: "text",
              value: " = ",
            },
            {
              type: "katex",
              value: ` ${varA?.displayValue}`,
            },
            {
              type: "text",
              value: ".",
            },
            {
              type: "newline",
            },
            {
              type: "textfield",
              width: 30,
              size: "sm",
            },
          ],
        },
      },
    ],
  };
};

const args = {
  numeratorA: { numberValue: 3, displayValue: "3" },
  denominatorA: { numberValue: 5, displayValue: "5" },
  varA: {
    numberValue: 12,
    displayValue: "12",
    letterValue: "w",
    color: "#a8655d",
  },
};

export default question4(args);
