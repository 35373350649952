import React, { useState } from "react";
import { Stepper, Step, StepLabel, Button, Typography } from "@mui/material";
import { useCourseCreation } from "../../context/CourseCreationContext";
import CourseDetails from "./CourseDetails/CourseDetails";
import Unit from "./Unit/Unit";
import UnitSection from "./UnitSection/UnitSection";
import Lesson from "./Lesson/Lesson";
import LessonPart from "./LessonPart/LessonPart";

import "./NewCourse.scss";

const NewCourse = () => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Course", "Unit 1", "Section 1", "Lesson 1", "Part 1"];

  const { courseData, updateCourseData } = useCourseCreation();

  console.log("courseData:", courseData);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <CourseDetails
            courseData={courseData}
            updateCourseData={updateCourseData}
          />
        );
      case 1:
        return (
          <Unit courseData={courseData} updateCourseData={updateCourseData} />
        );
      case 2:
        return (
          <UnitSection
            courseData={courseData}
            updateCourseData={updateCourseData}
          />
        );
      case 3:
        return (
          <Lesson courseData={courseData} updateCourseData={updateCourseData} />
        );
      case 4:
        return (
          <LessonPart
            courseData={courseData}
            updateCourseData={updateCourseData}
          />
        );
      default:
        return "Unknown Step";
    }
  };

  const stepContent = renderStepContent(activeStep);

  return (
    <div className="new-course-container">
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step className="step-label-container" key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="step-content">
        {activeStep === steps.length ? (
          <div>
            <Typography>All steps completed - you're finished</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className="step-description">
              Step {activeStep + 1}
            </Typography>
            <div className="form-fields">
              {stepContent}
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              <Button variant="contained" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewCourse;
