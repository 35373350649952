import { numWithSign } from "../../../../../../../../helpers/main";

const question4 = ({ varA, varB, coefficientA, coefficientB }) => ({
  questionConfig: [
    {
      type: "MultiInput",
      args: {
        size: "md",
        texBG: false,
        lineParts: [
          {
            type: "text",
            value: "Evaluate ",
          },
          {
            type: "katex",
            value: `${coefficientA?.displayValue}{\\textcolor{${varA?.color}}{${varA?.letterValue}}} ${numWithSign(coefficientB)}\\frac{\\textcolor{${varA?.color}}{${varA?.letterValue}}}{\\textcolor{${varB?.color}}{${varB?.letterValue}}}`,
          },
          {
            type: "text",
            value: " when ",
          },
          {
            type: "katex",
            value: `{\\textcolor{${varA?.color}}{${varA?.letterValue}}} = ${varA?.displayValue}`,
          },
          {
            type: "text",
            value: " and ",
          },
          {
            type: "katex",
            value: `{\\textcolor{${varB?.color}}{${varB?.letterValue}}} = ${varB?.displayValue}`,
          },
          {
            type: "newline",
          },
          {
            type: "textfield",
            width: 30,
            size: "sm",
          },
        ],
      },
    },
  ],
});

const args = {
  constantA: { numberValue: 10, displayValue: "10" },
  varA: {
    numberValue: 6,
    displayValue: "6",
    letterValue: "w",
    color: "#b86f65",
  },
  varB: {
    numberValue: 2,
    displayValue: "2",
    letterValue: "x",
    color: "#5280b1",
  },
  coefficientA: { numberValue: 5, displayValue: "5" },
  coefficientB: { numberValue: 1, displayValue: "1" },
};

export default question4(args);
