const part1 = {
  title: "Introduction to Variables, Expressions & Equations",
  type: "video",
  partOrder: 1,
  videoURL: "",
  videoLessonPartDescription:
    "An introduction to some of the foundations of algebra, including variables and equations.",
};

export default part1;
