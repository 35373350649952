import { numWithSign } from "../../../../../../../../helpers/main";

const question2 = ({
  constantA,
  varA,
  varB,
  varC,
  coefficientA,
  coefficientB,
  coefficientC,
}) => ({
  questionConfig: [
    {
      type: "MultiInput",
      args: {
        size: "md",
        center: true,
        texBG: false,
        lineParts: [
          {
            type: "text",
            value: "Evaluate ",
          },
          {
            type: "katex",
            value: `${coefficientA?.displayValue}{\\textcolor{${varA?.color}}{${varA?.letterValue}}} ${numWithSign(coefficientB)}{\\textcolor{${varB?.color}}{${varB?.letterValue}}} ${numWithSign(constantA)} ${numWithSign(coefficientC)} {\\textcolor{${varC?.color}}{${varC?.letterValue}}}^2`,
          },
          {
            type: "text",
            value: " when ",
          },
          {
            type: "newline",
          },
          {
            type: "katex",
            value: `{\\textcolor{${varA?.color}}{${varA?.letterValue}}} = ${varA?.displayValue}`,
          },
          {
            type: "text",
            value: ", ",
          },
          {
            type: "katex",
            value: `{\\textcolor{${varB?.color}}{${varB?.letterValue}}} = ${varB?.displayValue}`,
          },
          {
            type: "text",
            value: " and ",
          },
          {
            type: "katex",
            value: `{\\textcolor{${varC?.color}}{${varC?.letterValue}}} = ${varC?.displayValue}`,
          },
          {
            type: "text",
            value: ".",
          },
          {
            type: "newline",
          },
          {
            type: "textfield",
            width: 30,
            size: "sm",
          },
        ],
      },
    },
  ],
});

const args = {
  constantA: { numberValue: 10, displayValue: "10" },
  varA: {
    numberValue: 7,
    displayValue: "7",
    letterValue: "a",
    color: "#5280b1",
  },
  varB: {
    numberValue: 5,
    displayValue: "5",
    letterValue: "b",
    color: "#b86f65",
  },
  varC: {
    numberValue: 4,
    displayValue: "4",
    letterValue: "c",
    color: "#67a386",
  },
  coefficientA: { numberValue: 8, displayValue: "8" },
  coefficientB: { numberValue: 3, displayValue: "3" },
  coefficientC: { numberValue: -1, displayValue: "-1" },
};

export default question2(args);
