import React, { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";

const LessonPartForm = ({
  courseData,
  updateCourseData,
  handleNext,
  handleBack,
}) => {
  const initialVideoPartState = {
    lessonPartType: "",
    videoTitle: "",
    videoLectureType: "",
    videoLessonPartDescription: "",
    videoDuration: "",
  };
  const [lessonPart, setLessonPart] = useState(initialVideoPartState);

  const [quizQuestions, setQuizQuestions] = useState([]);

  const initialQuestionState = {
    questionText: "",
    options: ["", "", "", ""], // Assuming 4 options for simplicity
    correctAnswer: "",
    // Add other necessary fields like hints, images, etc.
  };

  const handlePartChange = (e) => {
    const { name, value } = e.target;
    // delete lesson part data on type change
    if (name == "lessonPartType") {
      setQuizQuestions([initialQuestionState]);
      setLessonPart({ lessonPartType: value });
    } else {
      // For other fields, merge with the current state
      setLessonPart((prevLessonPart) => ({ ...prevLessonPart, [name]: value }));
    }
    setLessonPart({ ...lessonPart, [name]: value });
    console.log("lessonPart B:", lessonPart); // lessonPart B: {lessonPartType: 'video', quizTitle: 'sdfdf'}
  };

  const handleAddQuestion = () => {
    setQuizQuestions([...quizQuestions, { ...initialQuestionState }]);
  };

  const handleRemoveQuestion = (index) => {
    const filteredQuestions = quizQuestions.filter((_, i) => i !== index);
    setQuizQuestions(filteredQuestions);
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = quizQuestions.map((question, i) => {
      if (i === index) {
        return { ...question, [field]: value };
      }
      return question;
    });
    setQuizQuestions(updatedQuestions);
  };

  const renderSpecificFields = () => {
    switch (lessonPart.lessonPartType) {
      case "video":
        return (
          <>
            <TextField
              key="videoTitle"
              label="Video Title"
              name="videoTitle"
              onChange={handlePartChange}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Video Lecture Type</InputLabel>
              <Select
                label="Video Lecture Type"
                key="videoLectureType"
                name="videoLectureType"
                onChange={handlePartChange}
              >
                <MenuItem value="VR">VR</MenuItem>
                <MenuItem value="Crafting">Crafting</MenuItem>
                <MenuItem value="Whiteboard">Whiteboard</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Description"
              key="videoLessonPartDescription"
              name="videoLessonPartDescription"
              onChange={handlePartChange}
              fullWidth
              multiline
              rows={3}
              margin="normal"
            />
            <TextField
              label="Duration (in seconds)"
              key="videoDuration"
              name="videoDuration"
              type="number"
              onChange={handlePartChange}
              fullWidth
              margin="normal"
            />
          </>
        );
      case "quiz":
        return (
          <>
            <TextField
              key="quizTitle"
              label="Quiz Title"
              name="quizTitle"
              onChange={handlePartChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Quiz Description"
              key="quizLessonPartDescription"
              name="quizLessonPartDescription"
              onChange={handlePartChange}
              fullWidth
              multiline
              rows={3}
              margin="normal"
            />

            {quizQuestions.map((question, index) => (
              <div key={`question_${index}`}>
                <TextField
                  label="Question Text"
                  value={question.questionText}
                  onChange={(e) =>
                    handleQuestionChange(index, "questionText", e.target.value)
                  }
                  fullWidth
                />
                {/* Render options fields here */}
                {/* Render correct answer field here */}
                <Button onClick={() => handleRemoveQuestion(index)}>
                  Remove Question
                </Button>
              </div>
            ))}
            <Button onClick={handleAddQuestion}>Add Question</Button>
          </>
        );
      default:
        return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to add the lesson part to the current lesson in courseData
    // updateCourseData logic goes here
    handleNext();
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <InputLabel>Lesson Part Type</InputLabel>
        <Select
          label="Lesson Part Type"
          key="lessonPartType"
          name="lessonPartType"
          onChange={handlePartChange}
        >
          <MenuItem value="video">Video</MenuItem>
          <MenuItem value="quiz">Quiz</MenuItem>
        </Select>
      </FormControl>
      {renderSpecificFields()}
    </form>
  );
};

export default LessonPartForm;
