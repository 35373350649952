function shuffleArray(originalArray) {
  let array = originalArray.slice();
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const allOptions = [
  {
    type: "text",
    value: `The shaded numbers are all prime.`,
  },
  {
    type: "text",
    value: `The shaded numbers are all composite.`,
  },
  {
    type: "text",
    value: `Some of the shaded numbers are composite and some are prime.`,
  },
];

const shuffledOptions = shuffleArray(allOptions);

function getRandomCompositesArray(min, max, totalNums) {
  const isComposite = (num) => {
    for (let i = 2; i < num; i++) if (num % i === 0) return true;
    return false;
  };

  const composites = new Set();
  while (composites.size < totalNums) {
    let num = Math.floor(Math.random() * (max - min + 1)) + min;
    if (isComposite(num)) composites.add(num);
  }
  return [...composites];
}

const question3 = (questionArgs) => {
  const len = questionArgs?.totalSquareLength;
  const allComposites = getRandomCompositesArray(4, len * len, 4);
  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: `Which of the following describes the numbers shaded on the ${len} by ${len} chart?`,
              bold: false,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
          ],
        },
      },
      {
        type: "NumberGrid",
        args: {
          numbers: allComposites,
          totalSquareLength: questionArgs?.totalSquareLength,
        },
      },
      {
        type: "ChoiceInput",
        args: {
          heading: "Select one answer:",
          type: "singleAnswer",
          options: shuffledOptions,
        },
      },
    ],
  };
};

const args = {
  totalSquareLength: 7,
};

export default question3(args);
