import React from "react";

const CaretSVG = ({ color }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="648px"
      height="381px"
      viewBox="0 0 648.000000 381.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,381.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path d="M354 3376 c-38 -33 -39 -35 -39 -100 l0 -66 1430 -1431 c787 -786 1442 -1437 1457 -1445 33 -17 85 -18 117 0 13 7 667 656 1452 1442 1534 1533 1450 1443 1436 1532 -4 26 -17 47 -43 70 -33 29 -44 32 -98 32 l-61 0 -1365 -1365 c-751 -751 -1372 -1365 -1380 -1365 -8 0 -629 614 -1380 1365 l-1365 1365 -61 0 c-55 0 -65 -3 -100 -34z" />
      </g>
    </svg>
  );
};

export default CaretSVG;
