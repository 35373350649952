import {
  getFractionString,
  numWithSign,
} from "../../../../../../../../helpers/main";

const question6 = ({
  constantA,
  varA,
  varB,
  numeratorA,
  denominatorA,
  numeratorB,
  denominatorB,
}) => ({
  questionConfig: [
    {
      type: "MultiInput",
      args: {
        size: "md",
        texBG: false,
        lineParts: [
          {
            type: "text",
            value: "Evaluate ",
          },
          {
            type: "katex",
            value: `${getFractionString(numeratorA, denominatorA, true)}\\textcolor{${varA.color}}{${varA.letterValue}} ${numWithSign(constantA)} ${getFractionString(numeratorB, denominatorB, false)}{\\textcolor{${varB.color}}{${varB.letterValue}}}`,
          },
          {
            type: "text",
            value: " when ",
          },
          {
            type: "katex",
            value: `{\\textcolor{${varA.color}}{${varA.letterValue}}} = ${varA.displayValue}`,
          },
          {
            type: "text",
            value: " and ",
          },
          {
            type: "katex",
            value: `{\\textcolor{${varB.color}}{${varB.letterValue}}} = ${varB.displayValue}`,
          },
          {
            type: "newline",
          },
          {
            type: "textfield",
            width: 30,
            size: "sm",
          },
        ],
      },
    },
  ],
});

const args = {
  varA: {
    numberValue: 6,
    displayValue: "6",
    letterValue: "y",
    color: "#b86f65",
  },
  varB: {
    numberValue: 3,
    displayValue: "3",
    letterValue: "z",
    color: "#5280b1",
  },
  constantA: { numberValue: -3, displayValue: "-3" },
  numeratorA: { numberValue: 3, displayValue: "3" },
  denominatorA: { numberValue: 2, displayValue: "2" },
  numeratorB: { numberValue: 5, displayValue: "5" },
  denominatorB: { numberValue: 3, displayValue: "3" },
};

export default question6(args);
