import React, { useState } from "react";
import ChoiceInputOption from "./ChoiceInputOption";
import { createAnswerPartState } from "../../shared/answerPartState";
import "./ChoiceInput.scss";

const ChoiceInput = ({
  options,
  heading,
  type = "singleAnswer",
  updateAnswerState = () => {},
  answerIndicies,
}) => {
  const [optionsSelected, setOptionsSelected] = useState([]);
  const isSingle = type === "singleAnswer";

  const handleAnswerStateChange = (choices) => {
    const isCorrect =
      JSON.stringify(choices) === JSON.stringify(answerIndicies);
    let newState = {
      ...createAnswerPartState("ChoiceInput"),
      isCorrect,
    };
    updateAnswerState(newState);
  };

  const handleChange = (optionIndex) => {
    let filteredOptions = [...optionsSelected];
    // singleAnswer
    if (isSingle) {
      filteredOptions = filteredOptions.includes(optionIndex)
        ? []
        : [optionIndex];
    }

    // multiAnswer
    if (type === "multiAnswer") {
      filteredOptions = filteredOptions.includes(optionIndex)
        ? filteredOptions.filter((item) => item !== optionIndex)
        : [...filteredOptions, optionIndex];
    }
    setOptionsSelected(filteredOptions);
    handleAnswerStateChange(filteredOptions);
  };

  return (
    <div className="choice-input">
      <div className="choice-input--heading">{heading}</div>
      <div className="choice-input--choices">
        {options.map((option, i) => (
          <ChoiceInputOption
            key={`option_${i}`}
            handleChange={handleChange}
            option={option}
            index={i}
            selected={optionsSelected.includes(i)}
            type={type}
          />
        ))}
      </div>
    </div>
  );
};

export default ChoiceInput;
