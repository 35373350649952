export const createUnsimplifiedExpressionQ5 = (argParams = {}) => {
  const { coefficientA, coefficientB, constantA, constantB, varA } = argParams;
  return `${coefficientA?.displayValue}
          {\\textcolor{${varA?.color}}{${varA?.letterValue}}}
          ${constantA?.numberValue > 0 ? "+" : ""} 
          ${constantA?.displayValue}
          (
            ${coefficientB?.displayValue}
            {\\textcolor{${varA?.color}}{${varA?.letterValue}}}
            ${constantB?.numberValue > 0 ? "+" : ""} 
            ${constantB?.displayValue}
          )`;
};

const question1 = (questionArgs) => {
  const initialExpression = createUnsimplifiedExpressionQ5(questionArgs);
  const trimmedKaTeX = initialExpression.replace(/\s+/g, "");
  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: true,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value:
                "Combine the like terms to create an equivalent expression: ",
              bold: true,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "katex",
              value: trimmedKaTeX,
            },
            {
              type: "text",
              value: " = ",
            },
            {
              type: "mathinput",
              size: "sm",
            },
          ],
        },
      },
    ],
  };
};

const args = {
  coefficientA: { numberValue: 7.4, displayValue: "7.4" },
  constantA: { numberValue: -5, displayValue: "-5" },
  coefficientB: { numberValue: -1.6, displayValue: "-1.6" },
  constantB: { numberValue: 2.4, displayValue: "2.4" },
  varA: {
    letterValue: "z",
    color: "#5280b1",
  },
};

export default question1(args);
