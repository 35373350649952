import React from "react";
import "./Unauthorized.scss";

const Unauthorized = () => {
  return (
    <div className="unauthorized">
      <h1>Unauthorized</h1>
    </div>
  );
};

export default Unauthorized;
