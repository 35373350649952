import React, { useState } from "react";
import { TextField, Input } from "@mui/material";

const UnitForm = ({ courseData, updateCourseData, handleNext, handleBack }) => {
  const emptyUnit = {
    unitName: "",
    unitDescription: "",
    unitLogoURL: "",
    unitOrder: 1, // Default order, you may want to auto-calculate or adjust this
    unitSections: [],
  };
  const initialUnitState = courseData.course.units[0] || emptyUnit;
  const [unit, setUnit] = useState(initialUnitState);

  const handleUnitChange = (e) => {
    const { name, value } = e.target;
    const newUnit = { ...unit, [name]: value };
    const unitOrder = newUnit && Number(newUnit.unitOrder);
    let updatedUnits = [...courseData.course.units];
    updatedUnits[unitOrder - 1] = unit;
    setUnit({ ...unit, [name]: value });
    updateCourseData({
      ...courseData,
      course: {
        ...courseData.course,
        units: updatedUnits,
      },
    });
  };

  return (
    <form>
      <TextField
        label="Unit Name"
        name="unitName"
        value={unit.unitName}
        onChange={handleUnitChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Unit Description"
        name="unitDescription"
        value={unit.unitDescription}
        onChange={handleUnitChange}
        fullWidth
        multiline
        rows={3}
        margin="normal"
      />
      <Input
        label="Unit Order"
        name="unitOrder"
        value={unit.unitOrder}
        onChange={handleUnitChange}
        type="number"
        fullWidth
        margin="normal"
      />
    </form>
  );
};

export default UnitForm;
