const part1 = {
  title: "Single Variable Equations Review",
  type: "video",
  partOrder: 1,
  videoURL: "",
  videoLessonPartDescription:
    "Review the different parts of single variable equations, such as constants, coefficients, and variables, and see how each part contributes to the overall equation.",
};

export default part1;
