import question1 from "./questions/question1";

const part2 = {
  title: "Finding x & y Intercepts",
  partOrder: 2,
  type: "quiz",
  quizQuestions: [question1],
};

export default part2;
