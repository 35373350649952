import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import getCourseContent from "../../courseContent";
import completeLogo from "../../images/completed_icon.svg";
import fluentLogo from "../../images/fluent_icon.svg";
import CourseLogo from "../Home/images/CourseLogo.svg";
import TriangleIcon from "../../images/triangle.png";
import QuizIcon from "../../images/pencil.png";
import "./Course.scss"; // Make sure to create this CSS file

// vns = "video not started" - empty square
// vs = "video started" - square green border
// vc = "video complete" - square green
// qns = "quiz never attempted" - empty square
// qa = "last quiz attempted but not 100%" - square orange border
// p = "passed last 1 quiz attempt" - square orange checkmark
// pc = "proficient - last two quiz attempts 100%" - square green checkmark
// fl  = "fluent - last 3 quiz attempts 100%" - square white checkmark, green bg
const icons = {
  pc: completeLogo,
  fl: fluentLogo,
};

const getUnitByOrder = (crs, unitOrder) => {
  if (!crs || !crs.units) {
    return [];
  }
  return crs.units.find((unit) => unit.unitOrder === unitOrder);
};

const Course = ({ progress }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const unitID = queryParams.get("unit");

  const [course, setCourse] = useState(null);
  const [currentUnitID, setCurrentUnitID] = useState(null);
  const [currentUnit, setCurrentUnit] = useState(null);
  const { courseId } = useParams();

  const { unitProgress } = progress;
  const currentUnitProgress =
    unitProgress && currentUnit && unitProgress[currentUnit.unitOrder - 1];

  useEffect(() => {
    if (!course) return;
    const unit = getUnitByOrder(course, Number(unitID));
    setCurrentUnit(unit);
  }, [currentUnitID]);

  useEffect(() => {
    if (!courseId) return;

    const course = getCourseContent(courseId);
    if (!course) return;
    if (course.error) {
      return course.error;
    }

    setCourse(course);
    setCurrentUnitID(unitID);
  }, [courseId]);

  if (!course) {
    return <div>Loading course...</div>;
  }

  const handleUnitClick = (order) => {
    const unit = getUnitByOrder(course, order);
    setCurrentUnit(unit);
  };

  const goToCourseHome = () => {
    setCurrentUnit(null);
  };

  return (
    <div className="course-container">
      <aside className="units-sidebar">
        <div
          className="course-title course-mainHeader"
          onClick={goToCourseHome}
        >
          <img src={CourseLogo} />
          <div className="course-titleText">{course?.courseName}</div>
        </div>
        {course?.units?.map((unit) => (
          <div
            key={unit.unitOrder}
            className="course-unitItem"
            onClick={handleUnitClick.bind(this, unit.unitOrder)}
          >
            <div
              className={`course-unitItemInner ${
                unit.unitOrder === currentUnit?.unitOrder
                  ? "course-unitItemInner--active"
                  : ""
              }`}
            >
              <div className="course-unitItemOrder">{unit.unitOrder}.</div>
              <div className="course-unitItemTitle">{unit.unitName}</div>
            </div>
          </div>
        ))}
      </aside>
      {currentUnit ? (
        <main className="unit-detail">
          <div className="unit-courseHomeLink" onClick={goToCourseHome}>
            Course Home
          </div>
          <div className="unit-header">
            <div className="unit-detailTitle">
              {currentUnit?.unitOrder}. {currentUnit?.unitName}
            </div>
            <div className="unit-progress">
              <div className="courseDetail-courseHeader">
                <div className="courseDetail-courseHeader--title">
                  Section Progress
                </div>
                {currentUnitProgress && (
                  <div className="courseDetail-courseHeader--progress">
                    <div className="courseDetail-courseHeader--progressPart">
                      <img src={completeLogo} />
                      <div className="courseDetail-courseHeader--progressPartText">
                        <b>
                          {parseFloat(
                            Number(
                              currentUnitProgress?.completionPercentage,
                            ).toFixed(1),
                          )}
                          %
                        </b>{" "}
                        complete
                      </div>
                    </div>
                    <div className="courseDetail-courseHeader--progressPart">
                      <img src={fluentLogo} />
                      <div className="courseDetail-courseHeader--progressPartText">
                        <b>
                          {parseFloat(
                            Number(
                              currentUnitProgress?.fluencyPercentage,
                            ).toFixed(1),
                          )}
                          %
                        </b>{" "}
                        fluent
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="unit-detailContent">
            <div className="unit-detailContent--description">
              <div className="unit-descTitle">Section Overview</div>
              <div className="unit-desc">{currentUnit?.unitDescription}</div>
            </div>
          </div>

          {currentUnit &&
            currentUnit.lessons &&
            currentUnit.lessons.map((lesson) => (
              <div className="unit-detailContent">
                <div className="unit-detailContent--description">
                  <Link
                    to={`/courses/${course.courseID}/lesson/${currentUnit?.unitOrder}_${lesson.lessonOrder}/`}
                  >
                    <div className="unit-lessonTitle">{lesson.lessonName}</div>
                  </Link>
                  <div className="unit-desc">
                    {lesson.lessonParts &&
                      lesson.lessonParts.map((part, partIndex) => (
                        <Link
                          to={`/courses/${course.courseID}/lesson/${currentUnit?.unitOrder}_${
                            lesson.lessonOrder
                          }?partOrder=${partIndex + 1}`}
                        >
                          <div className="unit-lessonPart">
                            <div className="unit-lessonPartLogo">
                              <img
                                src={
                                  part.videoLessonPartDescription
                                    ? TriangleIcon
                                    : QuizIcon
                                }
                              />
                            </div>
                            <div className="unit-lessonPartText">
                              {part.title}
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </main>
      ) : (
        <main className="courseDetail-home">
          <div className="courseDetail-header">
            <div className="courseDetail-detailTitle">{course?.courseName}</div>
          </div>

          {course.units &&
            course.units.map((unit) => (
              <div className="courseDetail-unitOverview">
                <div
                  className="courseDetail-unitOverview--title"
                  onClick={handleUnitClick.bind(this, unit.unitOrder)}
                >
                  {unit.unitOrder}. {unit.unitName}
                </div>
                <div className="unit-progress">
                  <div className="courseDetail-courseHeader">
                    <div className="courseDetail-courseHeader--progress">
                      <div className="courseDetail-courseHeader--progressPart">
                        <img src={completeLogo} />
                        <div className="courseDetail-courseHeader--progressPartText">
                          <b>
                            {parseFloat(
                              Number(
                                unitProgress[unit.unitOrder - 1]
                                  ?.completionPercentage,
                              ).toFixed(1),
                            )}
                            %
                          </b>{" "}
                          complete
                        </div>
                      </div>
                      <div className="courseDetail-courseHeader--progressPart">
                        <img src={fluentLogo} />
                        <div className="courseDetail-courseHeader--progressPartText">
                          <b>
                            {parseFloat(
                              Number(
                                unitProgress[unit.unitOrder - 1]
                                  ?.fluencyPercentage,
                              ).toFixed(1),
                            )}
                            %
                          </b>{" "}
                          fluent
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </main>
      )}
    </div>
  );
};

export default Course;
