import part1 from "./parts/part1";
import part2 from "./parts/part2";
import part3 from "./parts/part3";

const lesson1 = {
  lessonName: "Reviewing Prime Numbers, Factors & Multiples",
  lessonOrder: 1,
  lessonParts: [part1, part2, part3],
};

export default lesson1;
