import question1 from "./questions/question1";
import question2 from "./questions/question2";
import question3 from "./questions/question3";
import question4 from "./questions/question4";
import question5 from "./questions/question5";
import question6 from "./questions/question6";
import question7 from "./questions/question7";

const part3 = {
  title: "Evaluating expressions with one variable",
  type: "quiz",
  partOrder: 3,
  quizQuestions: [
    question1,
    question2,
    question3,
    question4,
    question5,
    question6,
    question7,
  ],
};

export default part3;
