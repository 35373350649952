import algebra1Content from "./algebra_1";
import algebra2Content from "./algebra_2";

const allCourses = {
  kdE3e8hK29zuxkkY0: algebra1Content,
  IhajOWwYbgIF6XTE8: algebra2Content,
};

const getCourseContent = (id) => {
  const course = allCourses[id];
  if (!course) return { error: "No course matching that id found." };
  return course;
};

export default getCourseContent;
