import React, { useState } from "react";
import { signIn, confirmSignIn } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import SocialButton from "../../stories/SocialButton/SocialButton";
import Button from "../../stories/Button/Button";
import { Link } from "react-router-dom";
import "./SignIn.scss";

const SignIn = () => {
  const [username, setUsername] = useState(""); // Assuming username is used for sign-in
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [user, setUser] = useState(null);
  const [currentNextStep, setCurrentNextStep] = useState(null);
  const [challengeResponse, setChallengeResponse] = useState("");
  const [mfaCode, setMfaCode] = useState("");
  const [showPassReset, setShowPassReset] = useState(false);
  const [resetPass, setResetPass] = useState("");

  const navigate = useNavigate();

  const { setIsUserLoggedIn } = useAuth();

  const handleSignIn = async () => {
    try {
      const user = await signIn({ username, password });
      if (user?.isSignedIn) {
        setIsUserLoggedIn(true);
        navigate("/");
      } else if (user?.nextStep) {
        const { signInStep } = user?.nextStep;
        setCurrentNextStep(signInStep);
        if (signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
          // The user is required to set a new password. Save the user object to state.
          setUser(user);
          // Prompt the user to input their new password, then call handleCompleteNewPassword
        } else if (signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
          setUser(user);
          setCurrentNextStep("CONFIRM_SIGN_IN_WITH_TOTP_CODE");
        }
      }
    } catch (error) {
      console.log("error signing in", error);
      setError(error.message);
    }
  };

  const handleSendMfaCode = async () => {
    try {
      await confirmSignIn({ challengeResponse: mfaCode });
      setIsUserLoggedIn(true);
      navigate("/");
    } catch (error) {
      console.error("Error when entering MFA code", error);
      setError(error.message);
    }
  };

  const handleConfirmSignIn = async () => {
    try {
      const { isSignedIn, nextStep } = await confirmSignIn({
        challengeResponse,
      });

      if (isSignedIn) {
        setIsUserLoggedIn(true);
        navigate("/");
        // User is signed in, you can redirect them to your application's authenticated part
      } else {
        console.log("Next step required:", nextStep);
        // Handle next step according to the response, which could involve further challenges
        // ( maybe new password flow here -- like pass reset? )
      }
    } catch (error) {
      console.error("Error confirming sign in", error);
      setError(error.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(""); // Reset error message
    if (resetPass) {
      console.log("resetPass:", resetPass);
    } else if (!user) {
      handleSignIn(); // If no user is set, proceed to sign in
    } else if (currentNextStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
      handleSendMfaCode(); // If MFA challenge is required
    } else {
      handleConfirmSignIn(); // If user is set, we're in the new password required flow
    }
  };

  const showPasswordReset = () => {
    setShowPassReset(true);
  };

  const hidePassReset = () => {
    setShowPassReset(false);
  };

  return (
    <div className="sign-in-page">
      <div className="sign-in-top-left"></div>
      <div className="sign-in-top-right">
        <div className="sign-in-outer-container">
          <div className="sign-in-page--title">Log In</div>
          <div className="sign-in-container">
            {!showPassReset ? (
              <form onSubmit={handleSubmit}>
                {error && <div className="error-message">{error}</div>}

                <div className="am-social-buttons">
                  <SocialButton provider="google" />
                  <SocialButton provider="facebook" />
                  <SocialButton provider="apple" />
                </div>

                <div className="am-or-text">or</div>

                <div className="inputGroup">
                  <label className="inputGroup--label" for="username">
                    Email
                  </label>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>

                {!user && ( // Only show the password field if we are not in the new password flow
                  <div className="inputGroup">
                    <label className="inputGroup--label" for="password">
                      Password
                    </label>
                    <input
                      id="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                )}

                {currentNextStep &&
                  currentNextStep ===
                    "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED" && (
                    <div className="inputGroup">
                      <label className="inputGroup--label" for="new_password">
                        New Password
                      </label>
                      <input
                        id="new_password"
                        type="password"
                        value={challengeResponse}
                        onChange={(e) => setChallengeResponse(e.target.value)}
                      />
                    </div>
                  )}

                {currentNextStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE" && (
                  <div className="inputGroup">
                    <label className="inputGroup--label" for="mfa_code">
                      Enter Code
                    </label>
                    <input
                      id="mfa_code"
                      type="text"
                      value={mfaCode}
                      onChange={(e) => setMfaCode(e.target.value)}
                    />
                  </div>
                )}

                <button type="submit">
                  {currentNextStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
                    ? "Submit MFA Code"
                    : user
                      ? "Set New Password"
                      : "Sign In"}
                </button>
              </form>
            ) : (
              <form onSubmit={handleSubmit}>
                {error && <div className="error-message">{error}</div>}

                <div className="inputGroup">
                  <label className="inputGroup--label" for="reset_email">
                    Email
                  </label>
                  <input
                    id="reset_email"
                    type="text"
                    value={resetPass}
                    onChange={(e) => setResetPass(e.target.value)}
                  />
                </div>

                <button type="submit">Send Reset Link</button>
              </form>
            )}
            <div className="sign-in-links">
              {!showPassReset ? (
                <>
                  <Button onClick={showPasswordReset}>Forgot Password</Button>
                  <Link to="/signup">
                    <Button>Create Account</Button>
                  </Link>
                </>
              ) : (
                <Button onClick={hidePassReset}>Back</Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
