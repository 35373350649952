import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { fetchAuthSession } from "aws-amplify/auth";

const ProtectedRoute = ({
  component: Component,
  isAdminRoute = false,
  progress,
}) => {
  const [session, setSession] = useState(null);
  // const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // const currentUser = await getCurrentUser();
        const currentSession = await fetchAuthSession();
        setSession(currentSession);
      } catch (error) {
        console.error("Error in ProtectedRoute:", error);
        setSession(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!session || !session?.tokens?.accessToken?.payload) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (isAdminRoute && session && !isAdmin(session)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Component progress={progress} />;
};

function isAdmin(session) {
  if (session && session.tokens && session.tokens.accessToken) {
    const groups = session.tokens.accessToken.payload["cognito:groups"];
    return groups && groups.includes("Admins");
  }
  return false;
}

export default ProtectedRoute;
