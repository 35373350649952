function shuffleArray(originalArray) {
  let array = originalArray.slice();
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function getRandomComposite(min, max) {
  const isComposite = (num) => {
    for (let i = 2, s = Math.sqrt(num); i <= s; i++)
      if (num % i === 0) return num > 1;
    return false;
  };

  let composite = 0;
  while (!composite) {
    let num = Math.floor(Math.random() * (max - min + 1)) + min;
    if (isComposite(num)) composite = num;
  }
  return composite;
}

function findFactors(num) {
  let factors = [];
  for (let i = 1; i <= Math.sqrt(num); i++) {
    if (num % i === 0) {
      factors.push(i);
      if (i !== num / i) {
        factors.push(num / i);
      }
    }
  }
  return factors.sort((a, b) => a - b).filter((n) => n !== num);
}

function getRandomNotInArray(arr, min, max) {
  let num;
  do {
    num = Math.floor(Math.random() * (max - min + 1)) + min;
  } while (arr.includes(num));
  return num;
}

const multiple = getRandomComposite(24, 80);
const subFactors = findFactors(multiple);
const factor = Math.max(...subFactors);
const numA = getRandomNotInArray(subFactors, 1, factor + 5);
const numB = getRandomNotInArray([...subFactors, numA], 1, factor + 5);
const numC = getRandomNotInArray([...subFactors, numA, numB], 1, factor + 5);

const allOptions = [
  {
    type: "text",
    value: `${factor}`,
    size: "md",
  },
  {
    type: "text",
    value: `${numA}`,
    size: "md",
  },
  {
    type: "text",
    value: `${numB}`,
    size: "md",
  },
  {
    type: "text",
    value: `${numC}`,
    size: "md",
  },
];

const shuffledOptions = shuffleArray(allOptions);

const question7 = () => {
  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: `Which of the following numbers is a factor of ${multiple}?`,
              bold: true,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
          ],
        },
      },
      {
        type: "ChoiceInput",
        args: {
          heading: "Select one answer:",
          type: "singleAnswer",
          options: shuffledOptions,
        },
      },
    ],
  };
};

const args = {};

export default question7(args);
