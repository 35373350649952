import mathsteps from "mathsteps";
import {
  getRandomLowercaseLetter,
  showVar,
  rando,
  getVarColor,
} from "../../../../../../../../helpers/main";
import {
  getSingleVarInequality_level1,
  getRandomInequality,
} from "../../../../../../../../helpers/inequalities";

const allInequalitiesGenerators = [getSingleVarInequality_level1];

const variableLetterValue = getRandomLowercaseLetter();
const varColor = getVarColor();
const inequalitySign = getRandomInequality();

const question3 = (args) => {
  const {
    constantA: a,
    constantB: b,
    constantC: c,
    varA: va,
    coefficientA: ca,
    coefficientB: cb,
  } = args;
  // this will be set to the dynamic difficulty in the future
  // const randLevel = rando(5) - 1;
  const { katexStr, equationStr } = allInequalitiesGenerators[0](
    args,
    inequalitySign,
  );
  const steps = mathsteps.solveEquation(equationStr);
  const solutionString = steps[steps.length - 1]?.newEquation.ascii();

  return {
    steps,
    solutionString,
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: "Solve for ",
            },
            {
              type: "katex",
              value: showVar(va),
            },
            {
              type: "text",
              value: ".",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "katex",
              value: katexStr,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "katex",
              value: `${showVar(va)}`,
            },
            {
              type: "text",
              value: " ",
            },
            {
              type: "dropdown",
              correctAnswer: "x",
              placeholder: "",
              width: 24,
              component: "katex",
              options: [
                { value: "\\leq", label: "\\leq" },
                { value: "\\geq", label: "\\geq" },
                { value: "<", label: "<" },
                { value: ">", label: ">" },
              ],
            },
            {
              type: "text",
              value: " ",
            },
            {
              type: "textfield",
              width: 40,
              correctAnswer: "3",
              size: "md",
            },
          ],
        },
      },
    ],
  };
};

let constantANV = rando(12);
let constantADV = constantANV.toString();

let constantBNV = rando(12);
let constantBDV = constantBNV.toString();

let constantCNV = rando(12);
let constantCDV = constantCNV.toString();

let coefficientANV = rando(12);
let coefficientADV = coefficientANV.toString();

let coefficientBNV = rando(12);
let coefficientBDV = coefficientBNV.toString();

let coefficientCNV = rando(12);
let coefficientCDV = coefficientCNV.toString();
const args = {
  constantA: { numberValue: constantANV, constantADV },
  constantB: { numberValue: constantBNV, displayValue: constantBDV },
  constantC: { numberValue: constantCNV, displayValue: constantCDV },
  varA: {
    letterValue: variableLetterValue,
    color: varColor,
  },
  coefficientA: { numberValue: coefficientANV, displayValue: coefficientADV },
  coefficientB: { numberValue: coefficientBNV, displayValue: coefficientBDV },
  coefficientC: { numberValue: coefficientCNV, displayValue: coefficientCDV },
};

export default question3(args);
