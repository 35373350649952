import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Raphael from "raphael";
import Click from "./images/click.svg";
import TrashClosed from "./images/trash-closed.svg";
import TrashOpen from "./images/trash-open.svg";
import {
  drawPlot,
  drawCubic,
  drawQuartic,
  drawQuintic,
  drawPiecewise,
  processExpPoints,
  redrawPoints,
  updatePoints,
} from "./graphHelpers/common";
import "./Grapher.scss";

const Grapher = ({
  plotPoints = [],
  plotType,
  plotSections = null,
  fixedAsymptote = false,
  initialAsymptoteYGridUnit = 0,
  asymptoteHexColor = "#23dba4",
  lineColors = ["#FFA500", "#2d81ff"],
  yAxisXOffsetFromLeft = -10,
  xAxisYOffsetFromTop = -10,
  zoomDivisor = 1,
  showPoints = true,
  cubicCoefficients = null,
  quarticCoefficients = null,
  quinticCoefficients = null,
  pointsAddable = false,
  showAxisLabels = true,
  yAxisTitle = null,
  xAxisTitle = null,
  singlePointInput = false,
  singlePointX = 300, // in grid px
  singlePointY = 200,
  updateUserPointsState = () => {},
}) => {
  const convertPoint = (point) => {
    const xO = yAxisXOffsetFromLeft;
    const yO = xAxisYOffsetFromTop;
    const zd = zoomDivisor;
    return [
      Math.round(((Math.floor(point[0] / 25) + xO) / zd) * 100) / 100,
      Math.round(-1 * ((Math.floor(point[1] / 25) + yO) / zd) * 100) / 100,
    ];
  };

  const convertLinePoint = (linePoint) => {
    const zd = zoomDivisor;
    return [
      Math.round((linePoint[0] / zd) * 100) / 100,
      Math.round((linePoint[1] / zd) * 100) / 100,
    ];
  };

  const handleUserLinesStateChange = () => {
    const convertedPoints = userPoints.map((point) => convertPoint(point));
    const convertedLinePoints = currentPoints.map((line) =>
      line.map((p) => convertLinePoint(p)),
    );
    const newUserState = {
      lines: convertedLinePoints,
      points: convertedPoints,
      asymptote: Math.round((asymptoteYInGridUnits / zoomDivisor) * 100) / 100,
    };
    updateUserPointsState(newUserState);
  };

  const handleUserPointsStateChange = (newPoints) => {
    const convertedPoints = newPoints.map((point) => convertPoint(point));
    const newUserState = {
      lines: currentPoints,
      points: convertedPoints,
      asymptote: Math.round((asymptoteYInGridUnits / zoomDivisor) * 100) / 100,
    };
    updateUserPointsState(newUserState);
  };

  let initialPoints = plotPoints;
  if (zoomDivisor !== 1) {
    initialPoints = plotPoints.map((line) =>
      line.map((point) => point.map((coord) => coord * zoomDivisor)),
    );
  }
  const prevPointsRef = useRef();
  const listenerAddedRef = useRef(false);
  const initialClickMadeRef = useRef(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingAsymptote, setIsDraggingAsymptote] = useState(false);
  const [currentPoints, setCurrentPoints] = useState(initialPoints);
  const [userPoints, setUserPoints] = useState([]);
  const [singlePointLocation, setSinglePointLocation] = useState([
    singlePointX,
    singlePointY,
  ]);
  const [throwingPointAway, setThrowingPointAway] = useState(false);
  const [pointBeingDragged, setPointBeingDragged] = useState(false);
  const [asymptoteYInGridUnits, setAsymptoteYInGridUnits] = useState(
    initialAsymptoteYGridUnit,
  );
  const staticPlotRef = useRef(null);
  const dynamicPlotRef = useRef(null);
  const showAsymptote = plotType === "exponential";

  const prevPoints = prevPointsRef.current;

  const pointsObj = (paper, points, lineIndex, allLines, lineColor) => ({
    showAsymptote,
    paper,
    setAsymptoteYInGridUnits,
    fixedAsymptote,
    asymptoteYInGridUnits,
    points,
    setCurrentPoints,
    asymptoteHexColor,
    plotType,
    lineIndex,
    allLines,
    lineColor,
    yAxisXOffsetFromLeft,
    xAxisYOffsetFromTop,
    showPoints,
  });

  const addUserPoint = (x, y) => {
    if (!initialClickMadeRef.current) {
      initialClickMadeRef.current = true;
    }
    const newUserPoints = [...userPoints, [x, y]];

    setUserPoints(newUserPoints);
    handleUserPointsStateChange(newUserPoints);
  };

  const updateUserPoint = (oldX, oldY, newX, newY, index) => {
    // This assumes userPoints is an array of [x, y] pairs
    // And that you have a way to identify which point to update, such as an index
    const updatedPoints = userPoints.map((point, idx) =>
      idx === index ? [newX, newY] : point,
    );

    setUserPoints(updatedPoints);
    handleUserPointsStateChange(updatedPoints);
  };

  useEffect(() => {
    if (!staticPlotRef.current || !dynamicPlotRef.current || !currentPoints)
      return;

    if (!staticPlotRef.current.hasChildNodes()) {
      const staticPaper = Raphael(staticPlotRef.current, 500, 500);
      drawPlot(
        staticPaper,
        yAxisXOffsetFromLeft,
        xAxisYOffsetFromTop,
        zoomDivisor,
      );
    }
    const dynamicPaper = Raphael(dynamicPlotRef.current, 500, 500);

    // Draw a large transparent rectangle to catch click events
    const clickCatcher = dynamicPaper.rect(
      0,
      0,
      dynamicPaper.width,
      dynamicPaper.height,
    );
    clickCatcher.attr({
      fill: "#fff", // Make the fill transparent or match the background
      "fill-opacity": 0, // Fully transparent
      stroke: "none", // No border
    });

    // Attach a click event listener to the rectangle
    clickCatcher.click(function (e) {
      // Raphael's event object provides `x` and `y` relative to the page, need to adjust
      const x = e.offsetX; // Adjust based on your specific SVG positioning if necessary
      const y = e.offsetY;
      addUserPoint(x, y);
    });

    currentPoints.forEach((linePoints, lineIndex) => {
      updatePoints(
        pointsObj(
          dynamicPaper,
          linePoints,
          lineIndex,
          currentPoints,
          lineColors[lineIndex],
        ),
        setIsDragging,
        isDragging,
        setIsDraggingAsymptote,
        isDraggingAsymptote,
      );
    });
    prevPointsRef.current = currentPoints;
    handleUserLinesStateChange();
  }, []);

  useEffect(() => {
    setCurrentPoints(plotPoints);
  }, [plotPoints]);

  useEffect(() => {
    if (!currentPoints) return;
    if (
      plotType === "piecewise" ||
      plotType === "quintic" ||
      plotType === "quartic" ||
      plotType === "cubic" ||
      redrawPoints(currentPoints, plotType, prevPoints)
    ) {
      dynamicPlotRef.current.innerHTML = "";
      const dynamicPaper = Raphael(dynamicPlotRef.current, 500, 500);

      let newPoints = currentPoints;
      if (prevPoints) {
        newPoints = processExpPoints(
          prevPoints,
          currentPoints,
          plotType,
          asymptoteYInGridUnits,
          yAxisXOffsetFromLeft,
          xAxisYOffsetFromTop,
        );
      }
      newPoints.forEach((linePoints, lineIndex) => {
        updatePoints(
          pointsObj(
            dynamicPaper,
            linePoints,
            lineIndex,
            newPoints,
            lineColors[lineIndex],
          ),
          setIsDragging,
          isDragging,
          setIsDraggingAsymptote,
          isDraggingAsymptote,
        );
      });
      prevPointsRef.current = newPoints;
    }
  }, [plotType, plotPoints, currentPoints, isDragging, isDraggingAsymptote]);

  const doesPointExist = (x, y) => {
    // Check if the point already exists in userPoints
    return userPoints.some((point) => point[0] === x && point[1] === y);
  };

  const isThrowingAway = (x, y) => {
    return x < 50 && y > 425;
  };

  const snapToGrid = (coordinate) => {
    return Math.round(coordinate / 25) * 25;
  };

  useEffect(() => {
    if (!currentPoints || !dynamicPlotRef.current) return;
    dynamicPlotRef.current.innerHTML = "";
    const dynamicPaper = Raphael(dynamicPlotRef.current, 500, 500);
    let svgElement = dynamicPlotRef.current.querySelector("svg");

    // Check if the listener is already added
    if (pointsAddable && !listenerAddedRef.current && svgElement) {
      const handleClick = (e) => {
        const rect = svgElement.getBoundingClientRect();
        let x = e.clientX - rect.left; // x position within the element.
        let y = e.clientY - rect.top; // y position within the element.

        // Adjust x and y to snap to the nearest grid intersection
        x = snapToGrid(x);
        y = snapToGrid(y);

        if (!doesPointExist(x, y)) {
          addUserPoint(x, y);
        }
      };

      svgElement.addEventListener("click", handleClick, false);

      // Mark the listener as added
      listenerAddedRef.current = true;
    }

    if (plotType === "piecewise") {
      drawPiecewise({
        plotSections,
        yAxisXOffsetFromLeft,
        xAxisYOffsetFromTop,
        dynamicPaper,
        lineColors,
      });
    } else if (plotType === "quintic") {
      drawQuintic(
        quinticCoefficients,
        yAxisXOffsetFromLeft,
        xAxisYOffsetFromTop,
        dynamicPaper,
        lineColors,
        zoomDivisor,
      );
    } else if (plotType === "quartic") {
      drawQuartic(
        quarticCoefficients,
        yAxisXOffsetFromLeft,
        xAxisYOffsetFromTop,
        dynamicPaper,
        lineColors,
        zoomDivisor,
      );
    } else if (plotType === "cubic") {
      drawCubic(
        cubicCoefficients,
        yAxisXOffsetFromLeft,
        xAxisYOffsetFromTop,
        dynamicPaper,
        lineColors,
        zoomDivisor,
      );
    } else {
      currentPoints.forEach((linePoints, lineIndex) => {
        updatePoints(
          pointsObj(
            dynamicPaper,
            linePoints,
            lineIndex,
            currentPoints,
            lineColors[lineIndex],
          ),
          setIsDragging,
          isDragging,
          setIsDraggingAsymptote,
          isDraggingAsymptote,
        );
      });
    }

    if (pointsAddable) {
      userPoints.forEach(([x, y], index) => {
        const radius = 7;
        const circle = dynamicPaper.circle(x, y, radius);
        circle.attr({
          fill: "#74c29c",
          stroke: "#74c29c",
        });

        circle.hover(
          function () {
            // Hover in
            this.animate({ r: 10 }, 200); // Increase size
          },
          function () {
            // Hover out
            this.animate({ r: 7 }, 200); // Restore original size
          },
        );

        // Make the circle draggable (if you want this functionality)
        circle.drag(
          function (dx, dy) {
            // onmove
            const newX = Math.min(
              Math.max(this.ox + dx, 24),
              dynamicPaper.width - 24,
            );
            const newY = Math.min(
              Math.max(this.oy + dy, 24),
              dynamicPaper.height - 24,
            );
            this.attr({
              cx: newX,
              cy: newY,
            });
            const isNearTrashCan = isThrowingAway(newX, newY);
            if (isNearTrashCan) {
              setThrowingPointAway(true);
            } else {
              setThrowingPointAway(false);
            }
          },
          function () {
            // onstart
            setPointBeingDragged(true);
            this.ox = this.attr("cx");
            this.oy = this.attr("cy");
            this.attr({
              cursor: "grabbing",
            });
          },
          function () {
            // onend
            setPointBeingDragged(false);
            const finalX = this.attr("cx");
            const finalY = this.attr("cy");

            const throwingAway = isThrowingAway(finalX, finalY);
            if (throwingAway) {
              const newestUserPoints = setUserPoints((currentPoints) => {
                const newestUserPoints = currentPoints.filter(
                  (_, i) => i !== index,
                );
                handleUserPointsStateChange(newestUserPoints);
                return newestUserPoints;
              });
              this.remove(); // Remove the circle from the canvas
              setThrowingPointAway(false);
              return;
            }

            const snappedX = snapToGrid(finalX);
            const snappedY = snapToGrid(finalY);
            if (
              !doesPointExist(snappedX, snappedY) ||
              (snappedX === this.ox && snappedY === this.oy)
            ) {
              updateUserPoint(this.ox, this.oy, snappedX, snappedY, index);
            } else {
              // If a point exists at the new position, revert to original position
              this.attr({
                cx: this.ox,
                cy: this.oy,
              });
            }
            this.attr({
              cursor: "grab",
            });
          },
        );
      });

      // Optional: Cleanup function to remove the event listener if your component unmounts
      return () => {
        if (svgElement && listenerAddedRef.current) {
          svgElement.removeEventListener("click", addUserPoint);
          listenerAddedRef.current = false;
        }
      };
    } else if (singlePointInput) {
      const [spX, spY] = singlePointLocation;
      const singlePoint = dynamicPaper.circle(spX, spY, 6.5);
      singlePoint.attr({
        fill: "#48CAE4",
        stroke: "#48CAE4",
        cursor: "grab",
      });
      singlePoint.hover(
        function () {
          // Hover in
          this.animate({ r: 10 }, 200); // Increase size
        },
        function () {
          // Hover out
          this.animate({ r: 7 }, 200); // Restore original size
        },
      );
      singlePoint.drag(
        function (dx, dy) {
          // onmove
          const newX = Math.min(
            Math.max(this.ox + dx, 24),
            dynamicPaper.width - 24,
          );
          const newY = Math.min(
            Math.max(this.oy + dy, 24),
            dynamicPaper.height - 24,
          );
          this.attr({
            cx: newX,
            cy: newY,
          });
        },
        function () {
          // onstart
          this.ox = this.attr("cx");
          this.oy = this.attr("cy");
        },
        function () {
          // onend
          const finalX = this.attr("cx");
          const finalY = this.attr("cy");

          const snappedX = snapToGrid(finalX);
          const snappedY = snapToGrid(finalY);
          const newestSinglePoint = [snappedX, snappedY];
          setSinglePointLocation(newestSinglePoint);
          handleUserPointsStateChange([newestSinglePoint]); // Grapher CB expects array of arrays
        },
      );
    }
  }, [
    asymptoteYInGridUnits,
    userPoints,
    singlePointLocation,
    isDragging,
    isDraggingAsymptote,
  ]);

  useEffect(() => {
    handleUserLinesStateChange();
  }, [currentPoints, asymptoteYInGridUnits]);

  const pxFromBorderToGrid = 35;
  const yAxisPxFromLeftBorder =
    Math.abs(yAxisXOffsetFromLeft * 25) + pxFromBorderToGrid;
  const xAxisPxFromTopBorder =
    Math.abs(xAxisYOffsetFromTop * 25) + pxFromBorderToGrid;

  return (
    <div className="grapher-container">
      {showAxisLabels && (
        <>
          <div
            className="grapher-axisLabel grapher-yLabel"
            style={{ left: yAxisPxFromLeftBorder - 2 }}
          >
            y
          </div>
          <div
            className="grapher-axisLabel grapher-xLabel"
            style={{ top: xAxisPxFromTopBorder - 14 }}
          >
            x
          </div>
        </>
      )}
      <div className="grapher-axisTitle grapher-yAxisTitle">{yAxisTitle}</div>
      <div className="grapher-axisTitle grapher-xAxisTitle">{xAxisTitle}</div>
      <div className="plot-inputWrapper">
        <div className="plot-border plot-topBorder" />
        <div ref={staticPlotRef} className="plot-input" />
        <div
          ref={dynamicPlotRef}
          className="plot-input"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            cursor: `${
              isDraggingAsymptote ? "move" : isDragging ? "grabbing" : ""
            }`,
          }}
        />
        {pointsAddable && (
          <>
            <img
              src={throwingPointAway ? TrashOpen : TrashClosed}
              alt="Trash can"
              className={`trashCanIcon ${pointBeingDragged ? "dragging" : ""}`}
            />
            {!initialClickMadeRef.current && (
              <div className="clickOverlay">
                <div className="overlayImg">
                  <img src={Click} />
                </div>
              </div>
            )}
          </>
        )}
        <div className="plot-border plot-bottomBorder" />
      </div>
    </div>
  );
};

Grapher.propTypes = {
  fixedAsymptote: PropTypes.bool,
  plotPoints: PropTypes.array,
  plotType: PropTypes.string,
  quadCoefficients: PropTypes.object,
  showAxisLabels: PropTypes.bool,
};

export default Grapher;
