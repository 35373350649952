export const createUnsimplifiedExpressionQ7 = (argParams = {}) => {
  const { decimalA, decimalB, coefficientA, varA, decimalC } = argParams;

  const formatVariable = (varObj) =>
    `{\\textcolor{${varObj.color}}{${varObj.letterValue}}}`;
  let expression = `${decimalA.displayValue}${formatVariable(varA)}`;
  expression +=
    decimalB?.numberValue < 0
      ? decimalB.displayValue
      : `+${decimalB.displayValue}`;
  if (coefficientA.numberValue === -1) {
    expression += `-${formatVariable(varA)}`;
  } else {
    expression += `+${coefficientA.displayValue}${formatVariable(varA)}`;
  }
  expression +=
    decimalC?.numberValue < 0
      ? decimalC.displayValue
      : `+${decimalC.displayValue}`;

  return expression;
};

const question7 = (questionArgs) => {
  const initialExpression = createUnsimplifiedExpressionQ7(questionArgs);
  const trimmedKaTeX = initialExpression.replace(/\s+/g, "");
  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: true,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value:
                "Combine the like terms to create an equivalent expression: ",
              bold: true,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "katex",
              value: trimmedKaTeX,
            },
            {
              type: "text",
              value: " = ",
            },
            {
              type: "mathinput",
              size: "sm",
            },
          ],
        },
      },
    ],
  };
};

const args = {
  decimalA: { numberValue: 0.25, displayValue: "0.25" },
  decimalB: { numberValue: 1.5, displayValue: "1.5" },
  coefficientA: { numberValue: -1, displayValue: "-1" },
  varA: { letterValue: "k", color: "#5280b1" },
  decimalC: { numberValue: -3.5, displayValue: "-3.5" },
};

export default question7(args);
