import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Profile from "./Profile";
import Billing from "./Billing";
import AllCourses from "./AllCourses";
import Subscription from "./Subscription";
import "./Home.scss";

const Home = ({ progress }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const removeQueryParam = () => {
    navigate("/home", { replace: true });
  };

  // Define state to track the active page
  const [activePage, setActivePage] = useState(page || "");

  // Handler to change active page
  const handlePageChange = (page) => {
    setActivePage(page);
    removeQueryParam();
  };

  return (
    <div className="home">
      <div className="home-top">
        <div className="home-top-left">
          <div className="home-menuGroup">
            <div className="home-menuGroupTitle">My Math</div>
            <div
              className={`home-menuGroupListItem ${
                activePage === "courses" ? "home-menuGroupListItem--active" : ""
              }`}
              onClick={() => handlePageChange("courses")}
            >
              All Courses
            </div>
            <div className="home-menuGroupListItem">
              <Link to={`/courses/kdE3e8hK29zuxkkY0`}>Algebra 1</Link>
            </div>
          </div>

          <div className="home-menuGroup">
            <div className="home-menuGroupTitle">Settings</div>
            <div
              className={`home-menuGroupListItem ${
                activePage === "Billing" ? "home-menuGroupListItem--active" : ""
              }`}
              onClick={() => handlePageChange("Billing")}
            >
              Billing
            </div>
            <div
              className={`home-menuGroupListItem ${
                activePage === "My Subscription"
                  ? "home-menuGroupListItem--active"
                  : ""
              }`}
              onClick={() => handlePageChange("My Subscription")}
            >
              My Subscription
            </div>
            <div
              className={`home-menuGroupListItem ${
                activePage === "My Profile"
                  ? "home-menuGroupListItem--active"
                  : ""
              }`}
              onClick={() => handlePageChange("My Profile")}
            >
              My Profile
            </div>
          </div>
        </div>
        <div className="home-top-right">
          {activePage === "Billing" && <Billing />}
          {activePage === "My Profile" && <Profile />}
          {activePage === "My Subscription" && <Subscription />}
          {activePage === "courses" && <AllCourses progress={progress} />}
        </div>
      </div>
      <div className="home-footer"></div>
    </div>
  );
};

export default Home;
