import React, { createContext, useContext, useState } from "react";

const CourseCreationContext = createContext();

export const useCourseCreation = () => useContext(CourseCreationContext);

function createUUID(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const CourseCreationProvider = ({ children }) => {
  const initialCourseData = {
    course: {
      courseName: "",
      courseID: createUUID(12),
      courseDescription: "",
      courseIntroVideoURL: "",
      isActive: false,
      metadata: {
        creationDate: "",
        lastUpdated: "",
        author: "",
        tags: [],
      },
      units: [],
      courseLogoURL: "",
    },
  };
  const [courseData, setCourseData] = useState(initialCourseData);
  // Function to update state
  const updateCourseData = (newData) => {
    setCourseData((prevData) => ({ ...prevData, ...newData }));
  };

  return (
    <CourseCreationContext.Provider value={{ courseData, updateCourseData }}>
      {children}
    </CourseCreationContext.Provider>
  );
};
