import unit4Lessons from "./lessons";

const unit4 = {
  unitOrder: 4,
  unitName: "Single Variable Inequalities",
  unitDescription:
    "Explore the basics of inequalities that use a single variable, learn to solve simple and compound inequalities, and understand how to apply these concepts in various contexts.",
  unitLogo: {
    url: "",
  },
  lessons: unit4Lessons,
};

export default unit4;
