import React from "react";
import Katex from "../../components/Katex";
import MathText from "../MathText/MathText";
import "./ChoiceInput.scss";

const imageBaseURL = "/images/";

const renderOption = (option) => {
  if (!option || !option.type) return;
  switch (option.type) {
    case "text":
      return (
        <div className={`choice-inputValue--text text_${option.size}`}>
          {option.value}
        </div>
      );
    case "katex":
      return (
        <Katex texExpression={option.value} className={option.className} />
      );
    case "mathText":
      return (
        <div className="choice-inputValue--mathText">
          <MathText lineParts={option.lineParts} />
        </div>
      );
    case "image":
      return (
        <div className="choice-inputValue--image">
          <img src={`${imageBaseURL}${option.fileName}`} />
        </div>
      );
  }
};

const letters = ["A", "B", "C", "D", "E", "F", "G"];

const ChoiceInputOption = ({ option, index, handleChange, selected, type }) => {
  return (
    <div className="choice-input--option">
      <div
        onClick={() => handleChange(index)}
        className={`choice-input--btnBorder ${type} ${
          selected ? "optionSelected" : ""
        }`}
        data-option-index={index}
      >
        <div className="choice-input--btn">{letters[index]}</div>
      </div>
      {renderOption(option, handleChange)}
    </div>
  );
};

export default ChoiceInputOption;
