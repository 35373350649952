import React from "react";
import "./AdminDashboard.scss";
import NewCourse from "../../components/NewCourse/NewCourse";
import { CourseCreationProvider } from "../../context/CourseCreationContext";

const AdminDashboard = () => {
  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <CourseCreationProvider>
        <NewCourse />
      </CourseCreationProvider>
    </div>
  );
};

export default AdminDashboard;
