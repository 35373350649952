const part1 = {
  title: "Expressions with Multiple Variables",
  type: "video",
  partOrder: 1,
  videoURL: "",
  videoLessonPartDescription:
    "Covering the basics of expressions with multiple variables.",
};

export default part1;
