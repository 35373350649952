import { evaluate } from "mathjs";

export const transformLogExpression = (
  expression,
  search = "log(",
  replaceWithPrefix = "log(",
  replaceWithSuffix = ",10)",
) => {
  const findClosingParenthesis = (str, startPos) => {
    let depth = 0;
    for (let i = startPos; i < str.length; i++) {
      if (str[i] === "(") depth++;
      else if (str[i] === ")") {
        if (depth === 0) return i;
        depth--;
      }
    }
    return -1; // Indicates no closing parenthesis found (should not happen in valid expressions)
  };

  let result = "";
  let i = 0;

  while (i < expression.length) {
    let index = expression.indexOf(search, i);

    if (index === -1) {
      // No more "log(" found, append the rest of the expression
      result += expression.slice(i);
      break;
    }

    // Append everything up to "log("
    result += expression.slice(i, index);

    let closingIndex = findClosingParenthesis(
      expression,
      index + search.length,
    );

    if (closingIndex === -1) {
      // Malformed expression; add the rest and break (should not happen in valid expressions)
      result += expression.slice(index);
      break;
    }

    let logContent = expression.slice(index + search.length, closingIndex);
    let commaIndex = logContent.indexOf(",");

    if (commaIndex === -1) {
      // No comma found, transform this log expression
      let transformedContent = transformLogExpression(
        logContent,
        search,
        replaceWithPrefix,
        replaceWithSuffix,
      );
      result += `${replaceWithPrefix}${transformedContent}${replaceWithSuffix}`;
    } else {
      // Comma found, indicating an existing base; leave as is
      result += `${search}${logContent})`;
    }

    i = closingIndex + 1;
  }

  return result;
};

export const transformExpression = (
  expression,
  search,
  replaceBegin,
  replaceEnd,
) => {
  let findClosingParenthesis = function (expr, start) {
    let depth = 1;
    for (let i = start; i < expr.length; i++) {
      if (expr[i] === "(") depth++;
      else if (expr[i] === ")") depth--;
      if (depth === 0) return i;
    }
    return -1;
  };

  let transform = function (expr) {
    let index = expr.indexOf(search);
    if (index === -1) return expr;

    let closingIndex = findClosingParenthesis(expr, index + 3);
    let beforeLn = expr.substring(0, index);
    let lnContent = expr.substring(index + 3, closingIndex);
    let afterLn = expr.substring(closingIndex + 1);
    let transformed =
      beforeLn + replaceBegin + lnContent + replaceEnd + afterLn;

    return transform(transformed);
  };

  return transform(expression);
};

export const calculateResult = (partsArray, displayMap, isDegree) => {
  let expressionStr = partsArray
    .map((key) => {
      if (displayMap?.[key]) {
        return displayMap[key];
      } else if (!isNaN(Number(key))) {
        return key;
      }
    })
    .join("");
  let expression = expressionStr.replace(/×/g, "*").replace(/÷/g, "/");

  let scope = {};

  if (isDegree) {
    // Convert degrees to radians for input and radians to degrees for output (for inverse functions).
    const degToRad = (deg) => deg * (Math.PI / 180);
    const radToDeg = (rad) => rad * (180 / Math.PI);

    // Override trigonometric functions for degree mode.
    scope = {
      sin: (x) => Math.sin(degToRad(x)),
      cos: (x) => Math.cos(degToRad(x)),
      tan: (x) => Math.tan(degToRad(x)),
      asin: (x) => radToDeg(Math.asin(x)),
      acos: (x) => radToDeg(Math.acos(x)),
      atan: (x) => radToDeg(Math.atan(x)),
      pi: Math.PI,
      e: Math.E,
    };
  } else {
    // For radians, no need to override the default behavior.
    scope = {
      sin: Math.sin,
      cos: Math.cos,
      tan: Math.tan,
      asin: Math.asin,
      acos: Math.acos,
      atan: Math.atan,
      pi: Math.PI,
      e: Math.E,
    };
  }

  try {
    const result = evaluate(expression, scope);
    const resultStr = result.toString();
    if (resultStr.includes("e") || resultStr.includes("E")) {
      return resultStr;
    } else {
      const formatted = parseFloat(result).toFixed(10);
      return formatted.replace(/(\.\d*?[1-9])0+$|\.0*$/, "$1");
    }
  } catch (error) {
    console.log("error:", error);
    return "Error";
  }
};

export const transformForDisplay = (calculationString) => {
  let tempString = calculationString.replace(
    /\blog10\(/g,
    "temporaryLogReplacement(",
  );
  tempString = tempString.replace(/\blog\(/g, "ln(");
  return tempString.replace(/temporaryLogReplacement\(/g, "log(");
};

export const displayMap = {
  asin: "asin(",
  acos: "acos(",
  atan: "atan(",
  sin: "sin(",
  cos: "cos(",
  tan: "tan(",
  pi: "pi",
  ln: "log(",
  log: "log10(",
  etoTheX: "e^",
  sqrt: "sqrt(",
  xToTheY: "^",
  lParen: "(",
  rParen: ")",
  xSquared: "^2",
  plus: " + ",
  minus: " - ",
  times: "×",
  divide: "/",
  e: "e",
  point: ".",
  zero: "0",
  one: "1",
  two: "2",
  three: "3",
  four: "4",
  five: "5",
  six: "6",
  seven: "7",
  eight: "8",
  nine: "9",
};
