import React, { useEffect, useState } from "react";
import { post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import ChatIcon from "./images/chat.svg";
import Caret from "./images/caret.jsx";
import Robot from "./images/robot.svg";
import Send from "./images/send.jsx";
import TextInput from "../TextInput/TextInput";
import { CoachApi } from "../../constants.js";
import "./Coach.scss";

const getIdToken = async () => {
  const { tokens } = await fetchAuthSession();

  const { idToken } = tokens;
  return idToken;
};

const messages = [
  {
    text: `Hey! I'm here to help you learn fast. I can't give you the answer,
    but I can clarify any confusion around the question being asked.`,
    isUser: false,
  },
  {
    text: `Okay, thanks!`,
    isUser: true,
  },
  {
    text: `No problem. Let me know if you have any questions at all.`,
    isUser: false,
  },
  {
    text: `I have to do the same thing to both sides, right?`,
    isUser: true,
  },
];

const Message = ({ msg }) => {
  const { isUser, text } = msg;
  return (
    <div className="chat-msg">
      {!isUser && <img src={Robot} />}
      <div className={`message-text ${isUser ? "user-msg" : "bot-msg"}`}>
        {text}
      </div>
    </div>
  );
};

const Coach = () => {
  const [inputValue, setInputValue] = useState("");
  const [aiCoachOpen, setAICoachOpen] = useState(false);
  const [sendHover, setSendHover] = useState(false);

  const callChatApi = async (msg) => {
    const idToken = await getIdToken();
    const stringToken = idToken.toString();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${stringToken}`,
    };

    fetch(`${CoachApi.endpoint}/chat`, {
      method: "POST",
      headers,
      body: JSON.stringify({ message: msg }),
    })
      .then((response) => {
        console.log("RESPONSE:", response);
        return response.json();
      })
      .then((data) => console.log("API Call Successful:", data))
      .catch((error) => console.error("API Call Failed:", error));
  };

  const sendMessage = () => {
    callChatApi(inputValue);
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const toggleAICoach = () => {
    const isOpen = aiCoachOpen;
    setAICoachOpen(!isOpen);
  };

  return (
    <div className={`ai-coach-container ${aiCoachOpen ? "coach-open" : ""}`}>
      <div className="ai-coach-header" onClick={toggleAICoach}>
        <img src={ChatIcon} />
        <Caret fill="#b2bbb7" className="ai-coach-toggle" />
      </div>
      <div className="ai-coach-body">
        <div className="ai-coach-messageHistory">
          {messages.map((msg) => (
            <Message msg={msg} />
          ))}
        </div>
        <div className="ai-coach-messageInput">
          <TextInput className="ai-coach-input" onChange={handleInputChange} />
          <div
            className="ai-coach-send"
            onMouseEnter={() => setSendHover(true)}
            onMouseLeave={() => setSendHover(false)}
            onClick={sendMessage}
          >
            <Send fill={`${sendHover ? "#5284cf" : "#595d6a"}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coach;
