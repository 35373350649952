const unit8 = {
  unitOrder: 8,
  unitName: "Functions",
  unitDescription:
    "This unit introduces the concept of functions, a fundamental building block in algebra and higher mathematics.",
  unitLogo: {
    url: "",
  },
  lessons: [],
};

export default unit8;
