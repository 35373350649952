import React, { useState } from "react";
import { TextField, Button } from "@mui/material";

const UnitSectionForm = ({
  courseData,
  updateCourseData,
  handleNext,
  handleBack,
}) => {
  const emptySection = {
    unitSectionName: "",
    unitSectionDescription: "",
    unitSectionOrder: 1, // Default order, this might need to be dynamically determined based on existing sections
    lessons: [],
  };
  const initialSectionState =
    courseData.course.units[0].unitSections || emptySection;
  const [section, setSection] = useState(initialSectionState);

  const handleSectionChange = (e) => {
    const { name, value } = e.target;
    const newSection = { ...section, [name]: value };
    const sectionOrder = newSection && Number(newSection.sectionOrder);

    let updatedSections = [...courseData.course.units[0].unitSections];
    updatedSections[sectionOrder - 1] = section;
    let currentUnit = {
      ...courseData.course.units[0],
      sections: updatedSections,
    };
    setSection({ ...section, [name]: value });
    updateCourseData({
      ...courseData,
      course: {
        ...courseData.course,
        units: [currentUnit],
      },
    });
  };

  return (
    <form>
      <TextField
        label="Section Name"
        name="unitSectionName"
        value={section.unitSectionName}
        onChange={handleSectionChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Section Description"
        name="unitSectionDescription"
        value={section.unitSectionDescription}
        onChange={handleSectionChange}
        fullWidth
        multiline
        rows={3}
        margin="normal"
      />
      <TextField
        label="Section Order"
        name="unitSectionOrder"
        value={section.unitSectionOrder}
        type="number"
        onChange={handleSectionChange}
        fullWidth
        margin="normal"
      />
    </form>
  );
};

export default UnitSectionForm;
