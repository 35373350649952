const unit6 = {
  unitOrder: 6,
  unitName: "Linear Equations and Their Forms",
  unitDescription:
    "Explore the different representations of linear equations, including slope-intercept, point-slope, and standard forms, to understand how to graph and interpret lines.",
  unitLogo: {
    url: "",
  },
  lessons: [],
};

export default unit6;
