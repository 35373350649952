import { shuffleArray } from "../../../../../../../../helpers/main";

export const allOptions = () => {
  const options = [
    {
      correctAnswer: false,
      katexString: `(3,2)`,
    },
    {
      correctAnswer: false,
      katexString: `(-3,3)`,
    },
    {
      correctAnswer: false,
      katexString: `(4,5)`,
    },
    {
      correctAnswer: false,
      katexString: `(4,8)`,
    },
  ];
  return options;
};

const question3 = () => {
  const questionOptions = shuffleArray(allOptions());

  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: "The equation below creates a line.",
            },
            {
              type: "newline",
            },
            {
              type: "text",
              value: "Which coordinate pair is on that line?",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "katex",
              value: "-x -4y = -10",
            },
          ],
        },
      },
      {
        type: "ChoiceInput",
        args: {
          heading: "Select one:",
          options: questionOptions.map((option) => ({
            type: "mathText",
            lineParts: [
              {
                type: "katex",
                value: option?.katexString,
              },
            ],
          })),
        },
      },
    ],
  };
};

export default question3();
