const part1 = {
  title: "Combining Like Terms",
  type: "video",
  partOrder: 1,
  videoURL: "",
  videoLessonPartDescription:
    "Simplify algebraic expressions by adding or subtracting variables of the same degree.",
};

export default part1;
