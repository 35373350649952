import React, { useState } from "react";
import QuizQuestion from "../QuizQuestion/QuizQuestion";
import CalcIcon from "./images/calc.jsx";
import PencilIcon from "./images/pencil.jsx";
import Coach from "../Coach/Coach.jsx";
import Calculator from "../Calculator/Calculator.jsx";
import SketchPad from "../SketchPad/SketchPad.jsx";
import "./Quiz.scss";

const Quiz = ({ quizQuestions }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [showCalculator, setShowCalculator] = useState(false);
  const [showSketchPad, setShowSketchPad] = useState(false);
  const [isCalcHovered, setIsCalcHovered] = useState(false);
  const [isSketchHovered, setIsSketchHovered] = useState(false);
  if (!quizQuestions || quizQuestions.length === 0) {
    return null;
  }

  const handleCalcMouseEnter = () => setIsCalcHovered(true);
  const handleCalcMouseLeave = () => setIsCalcHovered(false);

  const handleSketchMouseEnter = () => setIsSketchHovered(true);
  const handleSketchMouseLeave = () => setIsSketchHovered(false);

  const startQuiz = () => setCurrentQuestionIndex(0);
  const nextQuestion = () =>
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  const isQuizStarted = currentQuestionIndex >= 0;
  const isQuizFinished = currentQuestionIndex >= quizQuestions.length;

  const handleCalculatorClick = () => {
    let newState = !showCalculator;
    setShowCalculator(newState);
  };

  const handleSketchPadClick = () => {
    let newState = !showSketchPad;
    setShowSketchPad(newState);
  };

  return (
    <div className="quiz">
      {showCalculator && <Calculator onClose={handleCalculatorClick} />}
      {showSketchPad && <SketchPad />}
      <div className="quiz-container">
        {!isQuizStarted && (
          <div className="startPage">
            <div className="startPage-cta">
              <h1>Practice makes perfect!</h1>
              <h3>{quizQuestions.length} questions</h3>
              <div className="startPage-ctaBtnContainer">
                <button className="startPage-ctaBtn" onClick={startQuiz}>
                  Start Practicing
                </button>
              </div>
            </div>
          </div>
        )}

        {isQuizStarted && !isQuizFinished && (
          <div className="quiz-contentWrapper">
            <QuizQuestion
              questionIndex={currentQuestionIndex}
              {...quizQuestions[currentQuestionIndex]}
            />
          </div>
        )}

        {isQuizFinished && (
          <div className="end-page">
            <h1>Quiz Finished</h1>
            <button onClick={() => setCurrentQuestionIndex(-1)}>
              Restart Quiz
            </button>
          </div>
        )}
      </div>
      {isQuizStarted && <Coach />}
      <div className="quiz-footer">
        <div className="quiz-btnsLeft">
          {isQuizStarted && (
            <div
              className="quiz-calcBtn"
              onClick={handleCalculatorClick}
              onMouseEnter={handleCalcMouseEnter}
              onMouseLeave={handleCalcMouseLeave}
            >
              <CalcIcon
                fill={`${isCalcHovered || showCalculator ? "#2d81ff" : "#8c8c8c"}`}
              />
            </div>
          )}
          {isQuizStarted && (
            <div
              className="quiz-sketchBtn"
              onClick={handleSketchPadClick}
              onMouseEnter={handleSketchMouseEnter}
              onMouseLeave={handleSketchMouseLeave}
            >
              <PencilIcon
                fill={`${isSketchHovered || showSketchPad ? "#2d81ff" : "#8c8c8c"}`}
              />
            </div>
          )}
        </div>
        <div className="quiz-footer-nextBtnContainer">
          <button
            onClick={nextQuestion}
            className={`quiz-footer-nextBtn ${isQuizStarted ? "started" : ""}`}
          >
            {isQuizStarted ? "Next" : "Begin"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
