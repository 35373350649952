export const question1 = ({ coefficientA, varA }) => ({
  questionConfig: [
    {
      type: "MultiInput",
      args: {
        size: "md",
        texBG: false,
        lineParts: [
          {
            type: "text",
            value: "Evaluate ",
          },
          {
            type: "katex",
            value: `${coefficientA?.displayValue}{\\textcolor{${varA?.color}}{${varA?.letterValue}}}`,
          },
          {
            type: "text",
            value: " when ",
          },
          {
            type: "katex",
            value: `\\textcolor{${varA?.color}}{${varA?.letterValue}} `,
          },
          {
            type: "text",
            value: " = ",
          },
          {
            type: "katex",
            value: ` ${varA?.displayValue}`,
          },
          {
            type: "text",
            value: ".",
          },
          {
            type: "newline",
          },
          {
            type: "textfield",
            width: 30,
            size: "sm",
          },
        ],
      },
    },
  ],
});

const args = {
  coefficientA: { numberValue: 6, displayValue: "6" },
  varA: {
    numberValue: 4,
    displayValue: "4",
    letterValue: "h",
    color: "#a8655d",
  },
};

export default question1(args);
