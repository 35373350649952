import React from "react";
import "./NumberGrid.scss";

const NumberGrid = ({ factor, totalSquareLength, numbers = [] }) => {
  const squares = [];
  const useNum = numbers.length > 0;

  for (let index = 0; index < totalSquareLength * totalSquareLength; index++) {
    const sqNum = index + 1;
    const isFactor = useNum ? numbers.includes(sqNum) : sqNum % factor === 0;
    squares.push(
      <div
        className={`numbergrid-square ${isFactor ? "factor" : ""}`}
        key={index}
      >
        {sqNum}
      </div>,
    );
  }

  return (
    <div
      style={{
        width: `${totalSquareLength * 30 + 4}px`,
        display: "grid",
        gridTemplateColumns: `repeat(${totalSquareLength}, 34px)`, // 20px width + 4px for margins
        gridGap: "1px",
      }}
      className="numbergrid"
    >
      {squares}
    </div>
  );
};

export default NumberGrid;
