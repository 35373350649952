import {
  getFractionWithVarCoefficientSign,
  getFractionString,
} from "../../../../../../../../helpers/main";

const question3 = ({
  numeratorA,
  denominatorA,
  varA,
  coefficientA,
  denominatorB,
}) => ({
  questionConfig: [
    {
      type: "MultiInput",
      args: {
        size: "md",
        texBG: false,
        lineParts: [
          {
            type: "text",
            value: "What is ",
          },
          {
            type: "katex",
            value: `${getFractionString(numeratorA, denominatorA, true)}
                    ${getFractionWithVarCoefficientSign(coefficientA, denominatorB, false, true)} 
                    {\\frac{\\textcolor{${varA?.color}}{${varA?.letterValue}}} {${Math.abs(denominatorB?.numberValue)}}}`,
          },
          {
            type: "text",
            value: " when ",
          },
          {
            type: "katex",
            value: `\\textcolor{${varA?.color}}{${varA?.letterValue}}`,
          },
          {
            type: "text",
            value: " = ",
          },
          {
            type: "katex",
            value: `${varA?.displayValue}`,
          },
          {
            type: "text",
            value: "?",
          },
          {
            type: "newline",
          },
          {
            type: "textfield",
            width: 30,
            size: "sm",
          },
        ],
      },
    },
  ],
});

const args = {
  numeratorA: { numberValue: 1, displayValue: "1" },
  denominatorA: { numberValue: 2, displayValue: "2" },
  coefficientA: { numberValue: 1, displayValue: "1" },
  varA: {
    numberValue: 12,
    displayValue: "12",
    letterValue: "t",
    color: "#5280b1",
  },
  denominatorB: { numberValue: -3, displayValue: "-3" },
};

export default question3(args);
