import React from "react";

const Caret = ({ fill = "#000000", className }) => {
  return (
    <div className={className}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="900.000000pt"
        height="900.000000pt"
        viewBox="0 0 900.000000 900.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
          fill={fill}
          stroke="none"
        >
          <path
            d="M1277 6151 c-46 -22 -91 -63 -125 -116 -24 -36 -27 -50 -27 -135 0
-130 11 -148 199 -317 83 -75 156 -141 161 -147 6 -6 82 -76 170 -156 197
-177 231 -208 264 -240 14 -14 71 -66 126 -115 55 -49 105 -94 111 -100 6 -5
31 -28 55 -50 24 -22 71 -65 104 -95 182 -166 271 -247 376 -341 63 -57 165
-149 225 -204 194 -177 288 -263 311 -283 13 -10 66 -59 120 -108 53 -49 107
-98 119 -109 12 -11 39 -36 60 -55 22 -20 91 -83 154 -140 159 -144 191 -173
209 -191 9 -8 86 -78 171 -154 85 -77 157 -143 160 -146 17 -22 120 -102 152
-119 54 -28 182 -28 236 0 40 21 110 77 197 159 28 26 100 91 160 146 61 54
117 106 126 114 17 17 28 27 204 186 61 54 117 106 126 114 30 30 194 178 243
220 27 23 54 48 61 54 78 74 155 145 285 262 85 77 157 142 160 145 3 4 50 47
105 96 127 113 114 101 176 159 30 28 99 91 154 140 55 50 112 101 126 115 42
40 97 90 204 185 55 49 105 93 111 99 91 86 154 144 284 261 85 77 160 145
165 151 6 5 73 67 150 137 210 190 210 191 210 327 0 103 -7 120 -69 188 -52
57 -114 82 -201 82 -101 0 -146 -24 -283 -148 -59 -53 -109 -99 -112 -103 -3
-3 -57 -52 -120 -109 -63 -57 -117 -106 -120 -109 -3 -4 -75 -69 -160 -145
-85 -77 -159 -144 -165 -150 -5 -6 -82 -76 -170 -156 -88 -79 -162 -147 -165
-150 -3 -3 -75 -68 -160 -144 -85 -77 -159 -144 -165 -150 -5 -6 -80 -74 -165
-151 -130 -117 -193 -175 -284 -261 -6 -6 -56 -50 -111 -99 -55 -49 -102 -91
-105 -94 -3 -4 -104 -96 -225 -206 -121 -110 -222 -202 -225 -205 -3 -3 -50
-46 -105 -95 -55 -50 -122 -111 -150 -137 -74 -69 -97 -88 -107 -88 -4 0 -41
31 -81 68 -39 38 -124 115 -187 172 -148 133 -287 259 -370 335 -36 33 -85 78
-109 100 -24 22 -51 47 -60 56 -9 8 -86 78 -171 154 -85 77 -159 145 -165 151
-5 6 -80 73 -165 150 -85 76 -159 144 -165 150 -5 6 -80 73 -165 150 -85 76
-157 141 -160 145 -3 3 -57 52 -120 109 -120 109 -185 168 -204 186 -6 5 -63
57 -126 114 -125 113 -187 169 -204 186 -6 5 -63 57 -126 114 -63 57 -117 106
-120 109 -3 4 -53 50 -112 103 -138 126 -182 148 -285 147 -52 0 -91 -6 -116
-18z"
          />
        </g>
      </svg>
    </div>
  );
};

export default Caret;
