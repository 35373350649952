import mathsteps from "mathsteps";
import {
  showVar,
  shuffleArray,
  getRandomArgs,
  formatCompoundInequalityForKaTeX,
  alterArgs,
} from "../../../../../../../../helpers/main";
import { createCompoundIneqOptions } from "../../../../../../../../helpers/createCompoundOptions";
import {
  getSingleVarInequality_level1,
  getRandomInequality,
  evaluateCompoundInequality,
  formatCompoundInequality,
} from "../../../../../../../../helpers/inequalities";

const allInequalitiesGenerators = [getSingleVarInequality_level1];

const generateIneqAndSolution = (argz, changeArgs = true) => {
  let attempts = 0;
  while (attempts < 100) {
    const inequalitySign = getRandomInequality();
    let newArgs = changeArgs ? alterArgs(argz) : argz;
    const { katexStr: kStr, equationStr: eqStr } = allInequalitiesGenerators[0](
      newArgs,
      inequalitySign,
    );
    const resSteps = mathsteps.solveEquation(eqStr);
    const solutionResStr = resSteps[resSteps.length - 1]?.newEquation?.ascii();

    if (
      solutionResStr !== undefined &&
      !solutionResStr.includes("=") &&
      solutionResStr.includes(argz?.varA?.letterValue)
    ) {
      return {
        solution: solutionResStr,
        katex: kStr,
        equation: eqStr,
      };
    }
    attempts++;
  }
  return { error: "Failed to solve inequality after 3 attempts" };
};

const question7 = (args) => {
  const {
    solution: solutionStringA,
    katex: ksA,
    equation: esA,
  } = generateIneqAndSolution(args, true);

  const {
    solution: solutionStringB,
    katex: ksB,
    equation: esB,
  } = generateIneqAndSolution(args, true);

  const operatorColor = "#e4ae4a";

  const spacerStr = `{\\textcolor{#ffffff}{a}}`;
  const compoundOperator = "OR";
  const compOperStr = `{\\textcolor{${operatorColor}}{${compoundOperator}}}`;
  const katexCompIneqStr = `${ksA}${spacerStr}${compOperStr}${spacerStr}${ksB}`;

  const correctAnswer = evaluateCompoundInequality(
    solutionStringA,
    solutionStringB,
    compoundOperator.toLowerCase(),
  );

  const formattedAnswer = formatCompoundInequality(correctAnswer);

  const katexAnswer = formatCompoundInequalityForKaTeX({
    str: formattedAnswer,
    letter: args?.varA?.letterValue,
    letterColor: args?.varA?.color,
    operatorColor,
  });

  const shuffledOptions = createCompoundIneqOptions(
    args,
    katexAnswer,
    shuffleArray,
  );

  const correctAnswerIndex = shuffledOptions.findIndex(
    (opt) => opt.correctAnswer,
  );

  return {
    correctAnswer: correctAnswerIndex,
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: "Solve for ",
            },
            {
              type: "katex",
              value: showVar(args?.varA),
            },
            {
              type: "text",
              value: ".",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "katex",
              value: katexCompIneqStr,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
          ],
        },
      },
      {
        type: "ChoiceInput",
        args: {
          heading: "Select one answer:",
          type: "singleAnswer",
          options: shuffledOptions,
        },
      },
    ],
  };
};

const lesson4Args = getRandomArgs();

export default question7(lesson4Args);
