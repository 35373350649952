import unit3Lessons from "./lessons";

const unit3 = {
  unitOrder: 3,
  unitName: "Solving Single Variable Equations",
  unitDescription:
    "We will focus on the principles and techniques of solving equations, specifically targeting single variable equations. From understanding the basics of equations to finding solutions for single variable scenarios, you'll gain confidence in manipulating algebraic expressions and solving these sorts of equations efficiently.",
  unitLogo: {
    url: "",
  },
  lessons: unit3Lessons,
};

export default unit3;
