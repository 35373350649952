import React from "react";
import Button from "../../stories/Button/Button";
import Unit1Logo from "./images/AlgUnit1Logo.svg";
import Unit2Logo from "./images/AlgUnit2Logo.svg";
import Unit3Logo from "./images/AlgUnit3Logo.svg";
import completeLogo from "../../images/completed_icon.svg";
import fluentLogo from "../../images/fluent_icon.svg";
import { Link } from "react-router-dom";

const AllCourses = ({ progress }) => {
  const { overallProgress } = progress;
  return (
    <div>
      <h2>Courses</h2>

      <div className="allCourses-course">
        <div className="allCourses-courseHeader">
          <div className="allCourses-courseHeader--title">Algebra 1</div>
          <div className="allCourses-courseHeader--progress">
            <div className="allCourses-courseHeader--progressPart">
              <img src={completeLogo} />
              <div className="allCourses-courseHeader--progressPartText">
                <b>
                  {parseFloat(
                    Number(overallProgress?.completionPercentage).toFixed(1),
                  )}
                  %
                </b>{" "}
                complete
              </div>
            </div>
            <div className="allCourses-courseHeader--progressPart">
              <img src={fluentLogo} />
              <div className="allCourses-courseHeader--progressPartText">
                <b>
                  {parseFloat(
                    Number(overallProgress?.fluencyPercentage).toFixed(1),
                  )}
                  %
                </b>{" "}
                fluent
              </div>
            </div>
          </div>
        </div>
        <div className="allCourses-course--content">
          <div className="allCourses-course--contentLeft">
            <div className="verticalLine" />
            <Link to={`/courses/kdE3e8hK29zuxkkY0`}>
              <div className="allCourses-course--unit">
                <div className="allCourses-course--unitLogo">
                  <img src={Unit1Logo} />
                </div>
                <div className="allCourses-course--unitLink">
                  Variables, Expressions & Equations
                </div>
              </div>
            </Link>
            <Link to={`/courses/kdE3e8hK29zuxkkY0`}>
              <div className="allCourses-course--unit">
                <div className="allCourses-course--unitLogo">
                  <img src={Unit2Logo} />
                </div>
                <div className="allCourses-course--unitLink">
                  Single Variable Expressions
                </div>
              </div>
            </Link>
            <Link to={`/courses/kdE3e8hK29zuxkkY0`}>
              <div className="allCourses-course--unit">
                <div className="allCourses-course--unitLogo">
                  <img src={Unit3Logo} />
                </div>
                <div className="allCourses-course--unitLink">
                  Multiple Variable Expressions
                </div>
              </div>
            </Link>
          </div>
          <div className="allCourses-course--contentRight">
            <Link to={`/courses/kdE3e8hK29zuxkkY0`}>
              <Button type="filled">Course Home</Button>
            </Link>
          </div>
        </div>
      </div>
      <div style={{ fontSize: "14px", marginTop: "75px", color: "#1b51a2" }}>
        <b>
          <i>Coming soon...</i>
        </b>
      </div>
      <div style={{ fontSize: "16px", marginTop: "12px", color: "#441414" }}>
        <b>
          <i>Geometry & Algebra 2</i>
        </b>
      </div>
      <div style={{ fontSize: "14px", marginTop: "27px", color: "#1b51a2" }}>
        <b>
          <i>Planned</i>
        </b>
      </div>
      <div style={{ fontSize: "16px", marginTop: "12px", color: "#441414" }}>
        <b>
          <i>Trigonometry</i>
        </b>
      </div>
      <div style={{ fontSize: "16px", marginTop: "12px", color: "#441414" }}>
        <b>
          <i>Pre-Calculus, Calculus 1, 2 & 3</i>
        </b>
      </div>
      <div style={{ fontSize: "16px", marginTop: "12px", color: "#441414" }}>
        <b>
          <i>Linear Algebra</i>
        </b>
      </div>
      <div style={{ fontSize: "16px", marginTop: "12px", color: "#441414" }}>
        <b>
          <i>Differential Equations</i>
        </b>
      </div>
      <div style={{ fontSize: "16px", marginTop: "12px", color: "#441414" }}>
        <b>
          <i>Probability & Statistics</i>
        </b>
      </div>
      <div style={{ fontSize: "16px", marginTop: "12px", color: "#441414" }}>
        <b>
          <i>Numerical Methods</i>
        </b>
      </div>
      <div style={{ fontSize: "16px", marginTop: "12px", color: "#441414" }}>
        <b>
          <i>Optimization</i>
        </b>
      </div>
      <div style={{ fontSize: "16px", marginTop: "12px", color: "#441414" }}>
        <b>
          <i>Discrete Mathmematics</i>
        </b>
      </div>
      <div style={{ fontSize: "16px", marginTop: "12px", color: "#441414" }}>
        <b>
          <i>Complex Systems</i>
        </b>
      </div>
      <div style={{ fontSize: "16px", marginTop: "12px", color: "#441414" }}>
        <b>
          <i>Machine Learning Algorithms</i>
        </b>
      </div>
    </div>
  );
};

export default AllCourses;
