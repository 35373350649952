import {
  getFractionWithVarCoefficientSign,
  numWithSign,
} from "../../../../../../../../helpers/main";

const question5 = ({
  coefficientA,
  varA,
  varB,
  denominator,
  coefficientB,
  constantA,
}) => ({
  questionConfig: [
    {
      type: "MultiInput",
      args: {
        size: "md",
        texBG: false,
        lineParts: [
          {
            type: "text",
            value: "Evaluate ",
          },
          {
            type: "katex",
            value: `${getFractionWithVarCoefficientSign(coefficientA, denominator, true, true)}\\frac{\\textcolor{${varA.color}}{${varA.letterValue}}}{${denominator.displayValue}} ${numWithSign(coefficientB)}{\\textcolor{${varB.color}}{${varB.letterValue}}} ${numWithSign(constantA)}`,
          },
          {
            type: "text",
            value: " when ",
          },
          {
            type: "katex",
            value: `{\\textcolor{${varA.color}}{${varA.letterValue}}} = ${varA.displayValue}`,
          },
          {
            type: "text",
            value: " and ",
          },
          {
            type: "katex",
            value: `{\\textcolor{${varB.color}}{${varB.letterValue}}} = ${varB.displayValue}`,
          },
          {
            type: "newline",
          },
          {
            type: "textfield",
            width: 30,
            size: "md",
          },
        ],
      },
    },
  ],
});

const args = {
  coefficientA: { numberValue: 1, displayValue: "1" },
  varA: {
    numberValue: 12,
    displayValue: "12",
    letterValue: "x",
    color: "#5280b1",
  },
  varB: {
    numberValue: 1 / 2,
    displayValue: "\\frac{1}{2}",
    letterValue: "y",
    color: "#b86f65",
  },
  denominator: { numberValue: 4, displayValue: "4" },
  coefficientB: { numberValue: 2, displayValue: "2" },
  constantA: { numberValue: -3, displayValue: "-3" },
};

export default question5(args);
