import part1 from "./parts/part1";
import part2 from "./parts/part2";

const lesson2 = {
  lessonName: "Slopes",
  lessonOrder: 2,
  lessonParts: [part1, part2],
};

export default lesson2;
