import { shuffleArray } from "../../../../../../../../helpers/main";

export const allOptions = () => {
  const options = [
    {
      correctAnswer: false,
      katexString: `(3,2)`,
    },
    {
      correctAnswer: false,
      katexString: `(-3,3)`,
    },
    {
      correctAnswer: false,
      katexString: `(4,5)`,
    },
    {
      correctAnswer: false,
      katexString: `(4,8)`,
    },
  ];
  return options;
};

const question6 = () => {
  const questionOptions = shuffleArray(allOptions());

  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "katex",
              value: "-4x -y = 24",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "text",
              value:
                "Complete the missing value in the solution to the equation.",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "text",
              value: "(",
            },
            {
              type: "textfield",
              width: 30,
              size: "sm",
            },
            {
              type: "text",
              value: ", 8)",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
          ],
        },
      },
    ],
  };
};

export default question6();
