import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { createAnswerPartState } from "../../shared/answerPartState";
import TrashSvg from "./TrashSvg";
import "./OrderInputStacks.scss";

const gradeAnswer = (array, correctOrder) => {
  if (array.length !== correctOrder.length) return false;
  return array.every((item, index) => item.orderNumber === correctOrder[index]);
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const remove = (list, index) => {
  const result = Array.from(list);
  result.splice(index, 1);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: "#fff",
  color: "#3f424d",
  borderRadius: "5px",
  border: "2px solid #d9d9dc",
  fontFamily: '"Helvetica Neue", Arial, sans-serif',
  borderBottom: "2px solid #b2bbb7",
  textAlign: "center",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  userSelect: "none",
  paddingTop: "15px",
  background: "#eaeaea",
  borderRadius: "3px",
  padding: grid,
  width: 250,
  minHeight: 200,
  boxShadow: "#888888 0px 1px 2px 0px",
});

const OrderInputStacks = ({
  updateAnswerState = () => {},
  orderQuestionSet,
  addCardWidth,
  correctOrder = [],
}) => {
  const [items, setItems] = useState([]);
  const [hoverStates, setHoverStates] = useState({});

  const handleOrderChange = (newItems) => {
    const isCorrect = gradeAnswer(newItems, correctOrder);
    let newState = {
      ...createAnswerPartState("OrderInputStacks"),
      isCorrect,
    };
    updateAnswerState(newState);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    handleOrderChange(newItems);

    setItems(newItems);
  };

  const onRemove = (index) => {
    const newItems = remove(items, index);
    handleOrderChange(newItems);
    setItems(newItems);
  };

  const onAddItem = (item) => {
    const newItems = [...items, { ...item, id: `${item.id}-${Math.random()}` }];
    setItems(newItems);

    handleOrderChange(newItems);
  };

  const handleMouseEnter = (itemId) => {
    setHoverStates((prev) => ({ ...prev, [itemId]: true }));
  };

  const handleMouseLeave = (itemId) => {
    setHoverStates((prev) => ({ ...prev, [itemId]: false }));
  };

  return (
    <div
      className="order-inputStacks"
      style={{ display: "flex", alignItems: "start" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: "20px",
        }}
      >
        {orderQuestionSet.map((item, index) => (
          <div
            key={`item_${index}`}
            className="order-input-addableCardWrapper"
            style={{ width: addCardWidth }}
            onClick={() => onAddItem(item)}
          >
            <div key={index} className="order-input-addableCard">
              <div>{item.content}</div>
              <div className="order-input-addableCard--plus">+</div>
            </div>
          </div>
        ))}
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <div className="order-inputStacks--contentWrapper">
                        {item.content}
                        <button
                          className="trashBtn"
                          onClick={() => onRemove(index)}
                          style={{ marginLeft: "10px" }}
                          onMouseEnter={() => handleMouseEnter(item.id)}
                          onMouseLeave={() => handleMouseLeave(item.id)}
                        >
                          <TrashSvg hover={!!hoverStates[item.id]} />
                        </button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default OrderInputStacks;
