const part1 = {
  title: "Prime Factorization",
  type: "video",
  partOrder: 1,
  videoURL: "",
  videoLessonPartDescription:
    "Prime Factorization is the process of breaking down a number into its prime factors, illustrating how every integer greater than 1 is either a prime number or can be uniquely represented as a product of prime numbers.",
};

export default part1;
