import React, { useState, useRef, useEffect } from "react";
import Katex from "../../components/Katex";
import Click from "./images/click.svg";
import Plus from "./images/plus.svg";
import Trash from "./images/trash.svg";
import "./FactorizationTree.scss";

const FactorNode = ({
  value,
  childrenNodes,
  addChild,
  isLeaf,
  posIndex = 0,
  isRoot = true,
  rootMaxReached,
  onValueChange,
  maxDepth,
  currentNodeDepth,
  parentValue,
  removeLastLeaf,
  isLastLeaf,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);
  const hasTwoChildren = childrenNodes.length === 2;
  const hidePlus =
    hasTwoChildren || (Number(currentNodeDepth) === 5 && posIndex === 1);
  const showPlus =
    !hidePlus && ((isRoot && !rootMaxReached) || (posIndex !== 0 && value));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsEditing(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="node-container">
      {!isRoot && (
        <div
          className={`connector-line ${posIndex === 0 ? "line--left" : "line--right"}`}
        />
      )}
      {isRoot && (
        <div className="factor-node">
          <div className="node-display root">{value}</div>
        </div>
      )}
      {!isRoot && (
        <div className={`factor-node ${isLeaf ? "leafNode" : ""}`}>
          {!isEditing ? (
            <div
              className="node-display clickable"
              onClick={() => setIsEditing(true)}
            >
              {value || (
                <img src={Click} alt="Click to edit" width={25} height={25} />
              )}
            </div>
          ) : (
            <input
              ref={inputRef}
              className="node-input"
              type="text"
              value={value}
              maxLength={3}
              onChange={(e) => onValueChange(e.target.value)}
              onBlur={() => setIsEditing(false)}
              onKeyDown={(e) => e.key === "Enter" && setIsEditing(false)}
              autoFocus
            />
          )}
        </div>
      )}
      <div className="children">
        {childrenNodes.map((childNode, index) => (
          <div
            key={childNode.id}
            className={`child ${index === 0 ? "left-child" : "right-child"}`}
          >
            {childNode.component}
          </div>
        ))}
      </div>
      <div>
        {showPlus ? (
          <div className="plusIcon" onClick={addChild}>
            <img src={Plus} width={25} height={25} />
          </div>
        ) : (
          <div className="plusIcon hidden" />
        )}
        {!isRoot && isLastLeaf && (
          <button className="removeNode" onClick={removeLastLeaf}>
            <img src={Trash} alt="Remove last leaf" />
          </button>
        )}
      </div>
    </div>
  );
};

const FactorizationTree = ({
  initialValue = 54,
  maxDepth = 5,
  maxRootChildren = 2,
}) => {
  const [nodeDepth, setNodeDepth] = useState(1);
  const [nodes, setNodes] = useState([
    { id: 1, value: initialValue, children: [], depth: 1 },
  ]);
  const [primeFactors, setPrimeFactors] = useState([]);

  const findPrimeFactors = (nodeId) => {
    const node = nodes.find((n) => n.id === nodeId);
    if (!node.children.length && node.value) {
      return [node.value];
    }
    let factors = [];
    node.children.forEach((childId) => {
      factors = factors.concat(findPrimeFactors(childId));
    });
    return factors;
  };

  useEffect(() => {
    setNodes([{ id: 1, value: initialValue, children: [], depth: 1 }]);
  }, [initialValue]);

  useEffect(() => {
    const newPrimeFactors = findPrimeFactors(1);
    setPrimeFactors(newPrimeFactors);
  }, [nodes]);

  const handleValueChange = (id, newValue) => {
    const newNodes = nodes.map((node) =>
      node.id === id ? { ...node, value: newValue } : node,
    );
    setNodes(newNodes);
  };

  const addChild = (nodeId) => {
    const parentNode = nodes.find((node) => node.id === nodeId);
    if (parentNode.depth >= maxDepth) return; // Ensure the tree does not exceed maximum depth

    const newNodes = [...nodes];
    const childrenToAdd = 2; // Number of children to add
    let newDepth = parentNode.depth + 1;
    setNodeDepth(newDepth); // Update the depth if necessary

    for (let i = 0; i < childrenToAdd; i++) {
      const newNodeId = newNodes.length + 1; // Calculate the new node's ID
      newNodes.push({
        id: newNodeId,
        value: "",
        children: [],
        depth: newDepth,
      }); // Create the new node
      parentNode.children.push(newNodeId); // Add the new node ID to the parent's children array
    }

    setNodes(newNodes); // Update the nodes state with the new array that includes the new children
  };

  const removeLastLeaf = () => {
    const findLastLeaf = (nodeId, path = []) => {
      const node = nodes.find((n) => n.id === nodeId);
      if (!node.children.length) return { leafId: nodeId, path };
      for (let i = node.children.length - 1; i >= 0; i--) {
        const result = findLastLeaf(node.children[i], path.concat(node.id));
        if (result) return result;
      }
      return null;
    };

    const { leafId, path } = findLastLeaf(1) || {};
    if (!leafId) return;

    setNodes((prev) =>
      prev.reduce((acc, node) => {
        if (node.id === leafId) return acc; // Skip the last leaf
        if (path.includes(node.id)) {
          // Update the children array of the parent node
          return acc.concat({
            ...node,
            children: node.children.filter((id) => id !== leafId),
          });
        }
        return acc.concat(node);
      }, []),
    );
  };

  const findLastLeaf = () => {
    let lastLeafId = null;
    const traverse = (nodeId) => {
      const node = nodes.find((n) => n.id === nodeId);
      if (!node.children.length) {
        lastLeafId = nodeId; // Update last leaf found
      } else {
        node.children.forEach((childId) => {
          traverse(childId);
        });
      }
    };
    traverse(1); // Assuming 1 is the root node ID
    return lastLeafId;
  };

  const rootHasMaxChildren = nodes[0].children.length >= maxRootChildren;

  const renderNode = (
    nodeId,
    isRoot = false,
    posIndex,
    parentNodeId = null,
  ) => {
    const lastLeafId = findLastLeaf();
    const node = nodes.find((n) => n.id === nodeId);
    const childrenComponents = node.children.map((childId, index) => ({
      id: childId,
      component: renderNode(childId, false, index, nodeId),
    }));

    return (
      <FactorNode
        value={node.value}
        posIndex={posIndex}
        parentValue={
          isRoot ? null : nodes.find((n) => n.id === parentNodeId)?.value
        }
        childrenNodes={childrenComponents}
        addChild={() => addChild(nodeId)}
        isLeaf={node.children.length == 0}
        isRoot={isRoot}
        maxDepth={maxDepth}
        rootMaxReached={isRoot && rootHasMaxChildren}
        onValueChange={(newValue) => handleValueChange(nodeId, newValue)}
        currentNodeDepth={node?.depth}
        parentNodeId={parentNodeId}
        removeLastLeaf={removeLastLeaf}
        isLastLeaf={nodeId === lastLeafId}
      />
    );
  };

  return (
    <div className="factorization-tree">
      <div className="factorization-tree-nodesContainer">
        {renderNode(1, true, 0)}
      </div>
      <div className="factorization-tree-primes">
        <div className="factorization-text">Prime Factors:</div>
        <Katex
          texExpression={primeFactors
            .map((pf) => `{\\textcolor{#0080e9}{${pf}}}`)
            .join("\\times")}
        />
      </div>
    </div>
  );
};

export default FactorizationTree;
