function shuffleArray(originalArray) {
  let array = originalArray.slice();
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const generateIncorrectAnswerKaTeXs = (
  varA,
  correctCoefficient,
  correctConstant,
) => {
  const cVal = Math.abs(correctConstant);
  const options = [
    {
      ic: false,
      katexString: `${correctCoefficient + 1 === 0 ? "" : correctCoefficient + 1}{\\textcolor{${varA?.color}}{${varA?.letterValue}}} + ${cVal + 1 === 0 ? cVal + 2 : cVal + 1}`,
    },
    {
      ic: false,
      katexString: `${correctCoefficient === 0 ? "" : correctCoefficient}{\\textcolor{${varA?.color}}{${varA?.letterValue}}} - ${cVal + 1 === 0 ? cVal + 2 : cVal + 1}`,
    },
    {
      ic: false,
      katexString: `${correctCoefficient - 1 === 0 ? "" : correctCoefficient - 1}{\\textcolor{${varA?.color}}{${varA?.letterValue}}} ${correctConstant === 0 ? "" : `${correctConstant > 0 ? `+ ${correctConstant}` : correctConstant}`}`,
    },
  ];
  return options;
};

export const generateCorrectAnswerKaTeXQ4 = ({
  constantA,
  constantB,
  constantC,
  constantD,
  coefficientA,
  coefficientB,
  varA,
}) => {
  const constANum = constantA.numberValue;
  const constBNum = constantB.numberValue;
  const constCNum = constantC.numberValue;
  const constDNum = constantD.numberValue;
  const coefANum = coefficientA.numberValue;
  const coefBNum = coefficientB.numberValue;

  const calculatedCoefficientA = constANum * coefANum;
  const calculatedCoefficientB = constCNum * coefBNum;
  const totalCoefficient = calculatedCoefficientA + calculatedCoefficientB;

  const partA = constANum * constBNum;
  const partB = constCNum * constDNum;
  const calculatedConstant = partA + partB;

  const katexString = `${totalCoefficient === 0 ? "" : `${totalCoefficient}{\\textcolor{${varA.color}}{${varA.letterValue}}}`} ${calculatedConstant === 0 ? "" : `${calculatedConstant > 0 ? `+ ${calculatedConstant}` : calculatedConstant}`} `;

  return {
    katexString: katexString.trim(),
    correctCoefficient: totalCoefficient,
    correctConstant: calculatedConstant,
  };
};

const correctIndices = (array) => {
  return array
    .map((obj, index) => (obj.ic === true ? index : -1))
    .filter((index) => index !== -1);
};

export const createUnsimplifiedExpressionQ4 = (argParams = {}) => {
  const {
    constantA,
    constantB,
    constantC,
    constantD,
    coefficientA,
    coefficientB,
    varA,
  } = argParams;
  return `${constantA?.displayValue} (
          ${constantB?.displayValue}
          ${coefficientA?.numberValue > 0 ? "+" : ""} 
          ${coefficientA?.displayValue}
          {\\textcolor{${varA?.color}}{${varA?.letterValue}}}
          )
          ${constantC?.numberValue > 0 ? "+" : ""} 
          ${constantC?.displayValue}
          (
            ${coefficientB?.displayValue}
            {\\textcolor{${varA?.color}}{${varA?.letterValue}}}
            ${constantD?.numberValue > 0 ? "+" : ""} ${constantD?.displayValue}
          )`;
};

const question4 = (questionArgs) => {
  const { katexString, correctCoefficient, correctConstant } =
    generateCorrectAnswerKaTeXQ4(questionArgs);
  const incorrectOptions = generateIncorrectAnswerKaTeXs(
    questionArgs?.varA,
    correctCoefficient,
    correctConstant,
  );
  const unshuffledOptions = [{ katexString, ic: true }, ...incorrectOptions];
  const questionOptions = shuffleArray(unshuffledOptions);
  const answerIndicies = correctIndices(questionOptions);
  const katexExpression = createUnsimplifiedExpressionQ4(questionArgs);
  const trimmedKaTeX = katexExpression.replace(/\s+/g, "");

  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: "Simplify to create an equivalent expression.",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "katex",
              value: trimmedKaTeX,
            },
          ],
        },
      },
      {
        type: "ChoiceInput",
        args: {
          heading: "Select one:",
          answerIndicies,
          options: questionOptions.map((option) => ({
            type: "mathText",
            lineParts: [
              {
                type: "katex",
                value: option?.katexString,
              },
            ],
          })),
        },
      },
    ],
  };
};

const args = {
  constantA: { numberValue: 2, displayValue: "2" },
  constantB: { numberValue: -2, displayValue: "-2" },
  constantC: { numberValue: 2, displayValue: "2" },
  constantD: { numberValue: -1, displayValue: "-1" },
  coefficientA: { numberValue: -4, displayValue: "-4" },
  coefficientB: { numberValue: -2, displayValue: "-2" },
  varA: {
    letterValue: "p",
    color: "#b86f65",
  },
};

export default question4(args);
