import React from "react";
import { AuthProvider } from "./context/AuthContext";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate, // Import Navigate for redirects
} from "react-router-dom";
import Header from "./components/Header/Header";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import Home from "./pages/Home/Home";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import Dashboard from "./pages/Dashboard/Dashboard";
import Course from "./pages/Course/Course";
import Lesson from "./pages/Course/Lesson";
import ProtectedRoute from "./components/ProtectedRoute";
import Unauthorized from "./pages/Unauthorized/Unauthorized";
import NotFound from "./pages/NotFound/NotFound";
import mockProgress from "./mock/progress";
import getProgress from "./shared/getProgress";

function App() {
  const progress = getProgress(mockProgress);

  return (
    <AuthProvider>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/home/:page?"
            element={<ProtectedRoute component={Home} progress={progress} />}
          />
          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoute component={AdminDashboard} isAdminRoute={true} />
            }
          />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component={Dashboard} />}
          />
          <Route
            path="/courses/:courseId?"
            element={<ProtectedRoute component={Course} progress={progress} />}
          />
          <Route
            path="/courses/:courseId/lesson/:lessonId?"
            element={<ProtectedRoute component={Lesson} progress={progress} />}
          />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<Navigate to="/not-found" />} />{" "}
          {/* Redirect to NotFound */}
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
