import alg1Units from "./units";

const algebra1Content = {
  courseID: "kdE3e8hK29zuxkkY0",
  courseName: "Algebra 1",
  courseDescription:
    "A foundational course in Algebra covering variables, expressions, equations, and more.",
  isActive: true,
  courseLogoURL: "",
  metadata: {
    creationDate: "2024-01-01",
    lastUpdated: "2024-01-02",
    author: "David Ames",
    tags: ["Algebra", "Mathematics", "High School"],
  },
  units: alg1Units,
};

export default algebra1Content;
