import unit5Lessons from "./lessons";

const unit5 = {
  unitOrder: 5,
  unitName: "Linear Relationships and Intercepts",
  unitDescription:
    "This unit introduces the fundamentals of two-variable linear equations and their graphical representations, focusing on understanding and finding x-intercepts and y-intercepts. You'll learn to interpret and solve linear equations, explore their graphical features, and apply these concepts to real-world problems",
  unitLogo: {
    url: "",
  },
  lessons: unit5Lessons,
};

export default unit5;
