// TrashSvg.js
import React from "react";

const TrashSvg = ({ hover }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24px" // Adjusted width
    height="33px" // Adjusted height
    viewBox="0 0 900.000000 1236.000000"
    preserveAspectRatio="xMidYMid meet"
    style={{ fill: hover ? "#6f82ff" : "#8c8c8c" }} // Here you can control the fill based on hover state
  >
    <g transform="translate(0.000000,1236.000000) scale(0.100000,-0.100000)">
      <path
        d="M3165 11959 c-113 -11 -207 -43 -295 -102 -27 -18 -66 -42 -87 -55
-38 -23 -183 -182 -183 -200 0 -5 -9 -21 -21 -36 -48 -61 -86 -225 -79 -341 2
-33 4 -82 4 -110 l1 -50 -650 -6 c-461 -4 -658 -9 -678 -17 -16 -7 -49 -12
-75 -12 -26 0 -65 -5 -87 -11 -22 -6 -59 -14 -82 -19 -51 -11 -144 -40 -158
-50 -5 -4 -39 -17 -75 -30 -36 -13 -98 -47 -138 -77 -95 -71 -144 -145 -171
-263 -20 -84 -20 -86 -2 -163 34 -136 61 -175 190 -275 29 -23 60 -42 68 -42
7 0 44 -15 81 -34 66 -33 208 -72 350 -97 40 -7 75 -16 78 -21 3 -4 -19 -39
-48 -77 -50 -64 -140 -231 -168 -311 -36 -105 -59 -206 -70 -313 -12 -119 -12
-7541 0 -7687 13 -161 34 -227 132 -428 83 -168 136 -242 272 -377 218 -217
383 -313 671 -389 148 -39 207 -40 2540 -44 2011 -3 2279 -2 2430 13 182 17
231 25 340 60 100 32 308 138 391 200 89 67 266 242 324 320 24 33 49 65 55
71 14 15 120 229 135 274 12 35 15 46 43 150 8 30 20 104 26 164 8 77 11 1221
11 3835 0 4044 2 3872 -54 4061 -31 104 -139 323 -202 409 -43 59 -45 65 -29
77 9 7 51 15 93 19 43 3 84 10 93 15 9 5 34 12 55 15 111 20 290 103 385 178
122 97 176 286 125 437 -19 56 -67 142 -95 171 -25 24 -152 109 -165 109 -6 0
-42 16 -79 35 -38 19 -75 35 -84 35 -8 0 -52 11 -97 25 -162 49 -290 58 -936
64 -587 6 -595 6 -593 26 1 11 6 49 12 85 20 122 -18 312 -82 408 -116 174
-269 290 -482 365 -60 21 -66 21 -1460 23 -770 1 -1436 -2 -1480 -7z m2831
-566 c92 -49 134 -113 118 -177 -8 -34 -68 -91 -123 -118 l-46 -23 -1350 0
c-1051 0 -1359 3 -1391 12 -64 20 -131 77 -145 122 -13 45 -8 77 21 113 28 37
104 79 158 88 26 4 648 7 1382 6 l1335 -1 41 -22z m941 -1952 c79 -24 120 -49
173 -107 52 -56 86 -129 99 -214 8 -44 11 -1232 11 -3735 0 -3558 -1 -3672
-19 -3736 -24 -83 -39 -113 -89 -170 -49 -55 -143 -105 -226 -119 -39 -7 -842
-10 -2355 -10 -2229 0 -2298 1 -2359 19 -158 48 -256 157 -282 312 -6 43 -10
1262 -10 3744 0 3573 1 3682 19 3746 10 36 30 83 43 105 54 84 155 155 249
173 24 5 1088 9 2364 10 2248 1 2322 0 2382 -18z"
      />
      <path
        d="M2940 8650 c-8 -5 -26 -10 -39 -10 -29 0 -137 -68 -174 -110 -14 -16
-41 -59 -59 -97 l-33 -68 -3 -2930 c-3 -2775 -2 -2934 15 -3005 17 -75 50
-132 110 -193 58 -58 217 -117 279 -103 16 3 51 10 77 16 89 18 176 94 235
205 l37 70 3 2934 c2 3241 7 3022 -61 3124 -58 87 -120 132 -219 162 -58 17
-145 20 -168 5z"
      />
      <path
        d="M4459 8615 c-97 -27 -189 -109 -237 -211 l-27 -59 -3 -2945 c-2
-2259 0 -2958 9 -3000 14 -64 74 -164 122 -201 49 -39 115 -70 181 -86 50 -11
68 -11 133 2 117 24 206 95 269 215 l29 55 3 2957 c2 3254 7 3017 -60 3116
-72 106 -174 164 -298 168 -41 2 -96 -3 -121 -11z"
      />
      <path
        d="M5989 8616 c-3 -3 -29 -10 -57 -16 -60 -11 -143 -64 -188 -120 -17
-21 -44 -67 -60 -102 l-29 -63 -3 -2929 c-2 -2508 0 -2939 12 -2998 34 -165
185 -291 351 -292 94 -1 149 14 215 56 70 46 103 85 143 167 l32 66 0 2955 c0
2405 -3 2964 -14 3003 -16 60 -71 143 -121 183 -56 44 -149 83 -216 90 -33 3
-62 3 -65 0z"
      />
    </g>
  </svg>
);

export default TrashSvg;
