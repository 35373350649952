function shuffleArray(originalArray) {
  let array = originalArray.slice();
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function getRandomPrime(min, max) {
  const isPrime = (num) => {
    for (let i = 2, s = Math.sqrt(num); i <= s; i++)
      if (num % i === 0) return false;
    return num > 1;
  };

  let prime = 0;
  while (!prime) {
    let num = Math.floor(Math.random() * (max - min + 1)) + min;
    if (isPrime(num)) prime = num;
  }
  return prime;
}

function getRandomCompositesArray(min, max, totalNums) {
  const isComposite = (num) => {
    for (let i = 2; i < num; i++) if (num % i === 0) return true;
    return false;
  };

  const composites = new Set();
  while (composites.size < totalNums) {
    let num = Math.floor(Math.random() * (max - min + 1)) + min;
    if (isComposite(num)) composites.add(num);
  }
  return [...composites];
}

const question6 = (questionArgs) => {
  const len = questionArgs?.totalSquareLength;
  const randomPrime = getRandomPrime(4, len * len);
  const composites = getRandomCompositesArray(4, len * len, 3);
  const allNumbers = [randomPrime, ...composites];
  const shuffledNums = shuffleArray(allNumbers);
  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: `Camila tried to shade only composite numbers on the ${len} by ${len} chart.`,
              bold: false,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "text",
              value: `However, Camila made a mistake.`,
              bold: false,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
          ],
        },
      },
      {
        type: "NumberGrid",
        args: {
          numbers: allNumbers,
          totalSquareLength: questionArgs?.totalSquareLength,
        },
      },
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: "What mistake did Camila make?",
              bold: true,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
          ],
        },
      },
      {
        type: "ChoiceInput",
        args: {
          heading: "Select one answer:",
          type: "singleAnswer",
          options: shuffledNums.map((n) => ({
            type: "text",
            value: `${n} is prime, not composite.`,
          })),
        },
      },
    ],
  };
};

const args = {
  totalSquareLength: 5,
};

export default question6(args);
