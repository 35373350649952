import React from "react";
import Katex from "../../components/Katex";
import "./MathText.scss";

const renderComponent = (part, texBG, index) => {
  if (!part || !part.type) return;
  switch (part.type) {
    case "text":
      return (
        <div
          key={`linePart_${index}`}
          className="math-text-part math-text-part--text"
        >
          {part.value}
        </div>
      );
    case "katex":
      return (
        <div key={`linePart_${index}`} className="math-text-part">
          <Katex
            texExpression={part.value}
            className={`math-text-part--katex ${texBG ? "texBG" : ""}`}
          />
        </div>
      );
    case "newline":
      return (
        <div className="math-text-part newline-spacer">
          <br />
        </div>
      );
  }
};

const MathText = ({ lineParts, texBG }) => {
  if (!lineParts) return;
  return (
    <div className="math-text">
      {lineParts.map((part, index) => renderComponent(part, texBG, index))}
    </div>
  );
};

export default MathText;
