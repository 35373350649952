const part2 = {
  title: "Prime Numbers, Factors & Multiples",
  type: "video",
  partOrder: 2,
  videoURL: "",
  videoLessonPartDescription:
    "Reviewing the basics of prime numbers, explaining what makes a number prime, and explores the concepts of factors and multiples, illustrating the importance of these concepts in algebra.",
};

export default part2;
