const part1 = {
  title: "Solving Single Variable Equations",
  type: "video",
  partOrder: 1,
  videoURL: "",
  videoLessonPartDescription:
    "Knowing the basics of equation solutions, let's solve single-variable equations using methods like addition, subtraction, multiplication, and division to isolate the variable.",
};

export default part1;
