const part2 = {
  title: "Single Variable Expressions",
  type: "video",
  partOrder: 2,
  videoURL: "",
  videoLessonPartDescription:
    "An introduction to expressions and equations that use a single variable.",
};

export default part2;
