import { rando, numWithSign, showVar } from "./main";

export const getSingleVarEquation_level1 = (
  args,
  randomNumA = null,
  randomNumB = null,
) => {
  const num = randomNumA || rando(4);
  const numB = randomNumB || rando(2);
  const { varA: vA, constantA: cA, coefficientA: tA } = args;
  let equationStr = ``;
  let katexStr = ``;
  const expressionSign = numB === 1 ? "+" : "-";
  switch (num) {
    case 1:
      // constantA + varA = coefficientA(varA)
      katexStr = `${numWithSign(cA, true)} ${expressionSign} ${showVar(vA)} = ${numWithSign(tA, true)}${showVar(vA)}`;
      equationStr = `${numWithSign(cA, true)} ${expressionSign} ${vA?.letterValue} = ${numWithSign(tA, true)}(${vA?.letterValue})`;
      break;
    case 2:
      // varA + constantA = coefficientA(varA)
      katexStr = `${showVar(vA)} ${numWithSign(cA)} = ${numWithSign(tA, true)}${showVar(vA)}`;
      equationStr = `${vA?.letterValue} ${numWithSign(cA)} = ${numWithSign(tA, true)}(${vA?.letterValue})`;
      break;
    case 3:
      // coefficientA(varA) = varA + constantA
      katexStr = `${numWithSign(tA, true)}${showVar(vA)} = ${showVar(vA)} ${numWithSign(cA)}`;
      equationStr = `${numWithSign(tA, true)}(${vA?.letterValue}) = ${vA?.letterValue} ${numWithSign(cA)}`;
      break;
    default:
      // coefficientA(varA) + constantA = varA
      katexStr = `${numWithSign(tA, true)}${showVar(vA)} ${numWithSign(cA)} = ${showVar(vA)}`;
      equationStr = `${numWithSign(tA, true)}(${vA?.letterValue}) ${numWithSign(cA)} = ${vA?.letterValue}`;
      break;
  }

  console.log("num level1:", katexStr);
  return {
    katexStr,
    equationStr,
  };
};

export const getSingleVarEquation_level2 = (args, randomNumA = null) => {
  const { varA: vA, constantA: cA, constantB: cB, coefficientA: tA } = args;
  const num = randomNumA || rando(4);
  let equationStr = ``;
  let katexStr = ``;
  switch (num) {
    case 1:
      katexStr = `${numWithSign(tA, true)}${showVar(vA)} ${numWithSign(cA)} = ${showVar(vA)} ${numWithSign(cB)}`;
      equationStr = `${numWithSign(tA, true)}(${vA?.letterValue}) ${numWithSign(cA)} = ${vA?.letterValue} ${numWithSign(cB)}`;
      break;
    case 2:
      katexStr = `${showVar(vA)} ${numWithSign(cA)} = ${numWithSign(tA, true)}${showVar(vA)} ${numWithSign(cB)}`;
      equationStr = `${vA?.letterValue} ${numWithSign(cA)} = ${numWithSign(tA, true)}(${vA?.letterValue}) ${numWithSign(cB)}`;
      break;
    case 3:
      katexStr = `${numWithSign(tA, true)}${showVar(vA)} = ${showVar(vA)} ${numWithSign(cB)}`;
      equationStr = `${numWithSign(tA, true)}(${vA?.letterValue}) = ${vA?.letterValue} ${numWithSign(cA)}`;
      break;
    default:
      katexStr = `${showVar(vA)} ${numWithSign(cB)} = ${numWithSign(tA, true)}${showVar(vA)}`;
      equationStr = `${vA?.letterValue} ${numWithSign(cA)} = ${numWithSign(tA, true)}(${vA?.letterValue})`;
      break;
  }
  console.log("num level2:", katexStr);
  return {
    katexStr,
    equationStr,
  };
};

export const getSingleVarEquation_level3 = (args, randomNumA = null) => {
  const {
    varA: vA,
    constantA: cA,
    constantB: cB,
    constantC: cC,
    coefficientA: tA,
    coefficientB: tB,
  } = args;
  const num = randomNumA || rando(4);
  let equationStr = ``;
  let katexStr = ``;
  switch (num) {
    case 1:
      katexStr = `${numWithSign(cA, true)} ${numWithSign(tA)}${showVar(vA)} = ${numWithSign(tB, true)}${showVar(vA)} ${numWithSign(cB)}`;
      equationStr = `${numWithSign(cA, true)} ${numWithSign(tA)}(${vA?.letterValue}) = ${numWithSign(tB, true)}(${vA?.letterValue}) ${numWithSign(cB)}`;
      break;
    case 2:
      katexStr = `${numWithSign(tA, true)}${showVar(vA)} ${numWithSign(cB)} = ${showVar(vA)} ${numWithSign(cC)}`;
      equationStr = `${numWithSign(tA, true)}(${vA?.letterValue}) ${numWithSign(cB)} = ${vA?.letterValue} ${numWithSign(cC)}`;
      break;
    case 3:
      katexStr = `${showVar(vA)} ${numWithSign(cA)} = ${numWithSign(tA, true)}${showVar(vA)} ${numWithSign(cC)}`;
      equationStr = `${vA?.letterValue} ${numWithSign(cB)} = ${numWithSign(tA, true)}(${vA?.letterValue}) ${numWithSign(cC)}`;
      break;
    default:
      katexStr = `${numWithSign(cA, true)} ${numWithSign(tA)}${showVar(vA)} = ${numWithSign(cB, true)} ${numWithSign(tB)}${showVar(vA)}`;
      equationStr = `${numWithSign(cA, true)} ${numWithSign(tA)}(${vA?.letterValue}) = ${numWithSign(cB, true)} ${numWithSign(tB)}(${vA?.letterValue})`;
      break;
  }
  console.log("num level3:", katexStr);
  return {
    katexStr,
    equationStr,
  };
};

export const getSingleVarEquation_level4 = (args, randomNumA = null) => {
  const {
    varA: vA,
    constantA: cA,
    constantB: cB,
    constantC: cC,
    coefficientA: tA,
    coefficientB: tB,
  } = args;
  const num = randomNumA || rando(4);
  let equationStr = ``;
  let katexStr = ``;
  switch (num) {
    case 1:
      katexStr = `${numWithSign(cA, true)} ${numWithSign(tA)}${showVar(vA)} ${numWithSign(cB)} = ${numWithSign(tB, true)}${showVar(vA)} ${numWithSign(cC)} ${showVar(vA)}`;
      equationStr = `${numWithSign(cA, true)} ${numWithSign(tA)}(${vA?.letterValue}) ${numWithSign(cB)} = ${numWithSign(tB, true)}(${vA?.letterValue}) ${numWithSign(cC)} ${vA?.letterValue}`;
      break;
    case 2:
      katexStr = `${numWithSign(cA, true)} ${showVar(vA)} ${numWithSign(cB)} = ${numWithSign(tA, true)}${showVar(vA)} ${numWithSign(cC)} ${showVar(vA)}`;
      equationStr = `${numWithSign(cA, true)} ${vA?.letterValue} ${numWithSign(cB)} = ${numWithSign(tA, true)}(${vA?.letterValue}) ${numWithSign(cC)} ${vA?.letterValue}`;
      break;
    case 3:
      katexStr = `${showVar(vA)} ${numWithSign(cA)} ${numWithSign(cB)} = ${numWithSign(tA, true)}${showVar(vA)} ${numWithSign(tB)}${showVar(vA)} ${numWithSign(cC)}`;
      equationStr = `${vA?.letterValue} ${numWithSign(cA)} ${numWithSign(cB)} = ${numWithSign(tA, true)}(${vA?.letterValue}) ${numWithSign(tB)}(${vA?.letterValue}) ${numWithSign(cC)}`;
      break;
    default:
      katexStr = `${numWithSign(cA, true)} ${numWithSign(cB)} ${numWithSign(tA)}${showVar(vA)} = ${numWithSign(tB, true)}${showVar(vA)} ${numWithSign(cC)} ${showVar(vA)}`;
      equationStr = `${numWithSign(cA, true)} ${numWithSign(cB)} ${numWithSign(tA)}(${vA?.letterValue}) = ${numWithSign(tB, true)}(${vA?.letterValue}) ${numWithSign(cC)} ${vA?.letterValue}`;
      break;
  }
  console.log("num level4:", katexStr);
  return {
    katexStr,
    equationStr,
  };
};

export const getSingleVarEquation_level5 = (args, randomNumA = null) => {
  const {
    varA: vA,
    constantA: cA,
    constantB: cB,
    constantC: cC,
    coefficientA: tA,
    coefficientB: tB,
    coefficientC: tC,
  } = args;
  const num = randomNumA || rando(4);
  let equationStr = ``;
  let katexStr = ``;
  switch (num) {
    case 1:
      katexStr = `${numWithSign(cA, true)} ${numWithSign(tA)}${showVar(vA)} ${numWithSign(cB)} = ${numWithSign(tB, true)}${showVar(vA)} ${numWithSign(cC)} ${numWithSign(tC)}${showVar(vA)}`;
      equationStr = `${numWithSign(cA, true)} ${numWithSign(tA)}(${vA?.letterValue}) ${numWithSign(cB)} = ${numWithSign(tB, true)}(${vA?.letterValue}) ${numWithSign(cC)} ${numWithSign(tC)}(${vA?.letterValue})`;
      break;
    case 2:
      katexStr = `${numWithSign(cA, true)} ${showVar(vA)} ${numWithSign(cB)} = ${numWithSign(tA, true)}${showVar(vA)} ${numWithSign(tB)}${showVar(vA)} ${numWithSign(cC)}`;
      equationStr = `${numWithSign(cA, true)} ${vA?.letterValue} ${numWithSign(cB)} = ${numWithSign(tA, true)}(${vA?.letterValue}) ${numWithSign(tB)}(${vA?.letterValue}) ${numWithSign(cC)}`;
      break;
    case 3:
      katexStr = `${showVar(vA)} ${numWithSign(cB)} = ${numWithSign(tA, true)}${showVar(vA)} ${numWithSign(tB)}${showVar(vA)} ${numWithSign(cC)}`;
      equationStr = `${vA?.letterValue} ${numWithSign(cB)} = ${numWithSign(tA, true)}(${vA?.letterValue}) ${numWithSign(tB)}(${vA?.letterValue}) ${numWithSign(cC)}`;
      break;
    default:
      katexStr = `${numWithSign(cA, true)} ${numWithSign(tA)}${showVar(vA)} = ${numWithSign(tB, true)}${showVar(vA)} ${numWithSign(cC)} ${numWithSign(tC)}${showVar(vA)}`;
      equationStr = `${numWithSign(cA, true)} ${numWithSign(tA)}(${vA?.letterValue}) = ${numWithSign(tB, true)}(${vA?.letterValue}) ${numWithSign(cC)} ${numWithSign(tC)}(${vA?.letterValue})`;
      break;
  }
  console.log("num level5:", katexStr);
  return {
    katexStr,
    equationStr,
  };
};
