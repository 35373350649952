const part1 = {
  title: "Solving Single Variable Inequalities",
  type: "video",
  partOrder: 1,
  videoURL: "",
  videoLessonPartDescription:
    "View methods to isolate the variable and solve single variable inequalities.",
};

export default part1;
