import { e } from "mathjs";

function shuffleArray(originalArray) {
  let array = originalArray.slice();
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function getRandomPrime(min, max) {
  const isPrime = (num) => {
    for (let i = 2, s = Math.sqrt(num); i <= s; i++)
      if (num % i === 0) return false;
    return num > 1;
  };

  let prime = 0;
  while (!prime) {
    let num = Math.floor(Math.random() * (max - min + 1)) + min;
    if (isPrime(num)) prime = num;
  }
  return prime;
}

function getRandomComposite(min, max) {
  const isComposite = (num) => {
    for (let i = 2, s = Math.sqrt(num); i <= s; i++)
      if (num % i === 0) return num > 1;
    return false;
  };

  let composite = 0;
  while (!composite) {
    let num = Math.floor(Math.random() * (max - min + 1)) + min;
    if (isComposite(num)) composite = num;
  }
  return composite;
}

function coinToss() {
  return Math.floor(Math.random() * 2) + 1;
}

let choices = [{ val: "", answerIndex: -1 }, {}, {}, {}, {}];
const allChoices = choices.map((ch) => {
  if (ch?.val !== "") {
    let toss = coinToss();
    return {
      val: toss === 1 ? getRandomPrime(1, 53) : getRandomComposite(1, 54),
      answerIndex: toss - 1,
    };
  } else {
    return ch;
  }
});

const question5 = () => {
  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: `Is each number prime or composite?`,
              bold: true,
              size: "lg",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
          ],
        },
      },
      {
        type: "TableChoice",
        args: {
          colChoices: ["Prime", "Composite"],
          rowChoices: allChoices.map((ch) => ch?.val),
          width: 330,
          correctAnswers: allChoices.map((ch) => ch?.answerIndex),
        },
      },
    ],
  };
};

const args = {};

export default question5(args);
