import React from "react";

const Billing = () => {
  return (
    <div>
      <h1>Billing</h1>
    </div>
  );
};

export default Billing;
