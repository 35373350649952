const progress = {
  kdE3e8hK29zuxkkY0: {
    units: [
      {
        lessons: [
          {
            parts: [
              {
                type: "video",
                videoProgress: 79,
              },
              {
                type: "quiz",
                lastAnswers: [1, 1],
                answerHistory: [
                  [0, 1],
                  [1, 1], // up to two attempts before lastAnswers
                ],
              },
            ],
          },
          {
            parts: [
              {
                type: "video",
                videoProgress: 24, // percentage watched
              },
              {
                type: "quiz",
                lastAnswers: [0, 1], // last attempts; 0 incorrect, 1 correct
                answerHistory: [[0, 1]],
              },
            ],
          },
        ],
      },
      {
        lessons: [
          {
            parts: [
              {
                type: "video",
                videoProgress: 0,
              },
              {
                type: "quiz",
                lastAnswers: [],
                answerHistory: [],
              },
            ],
          },
          {
            parts: [
              {
                type: "video",
                videoProgress: 0,
              },
              {
                type: "quiz",
                lastAnswers: [], // empty means not answered
                answerHistory: [],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default progress;
