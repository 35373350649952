import React, { useState } from "react";
import { createAnswerPartState } from "../../shared/answerPartState";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import "./OrderInputTable.scss";

const gradeAnswer = (questionSet) => {
  for (let i = 0; i < questionSet.length; i++) {
    if (questionSet[i].correctOrder !== i) {
      return false;
    }
  }
  return true;
};

const grid = 8;

const reorder = (a, b, c) => {
  if (b >= 0 && b < a.length && c >= 0 && c < a.length) {
    const item = a.splice(b, 1)[0];
    a.splice(c, 0, item);
  }
  return a;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: "#fff",
  color: "#3f424d",
  borderRadius: "5px",
  border: "2px solid #d9d9dc",
  fontFamily: '"Helvetica Neue", Arial, sans-serif',
  borderBottom: "2px solid #b2bbb7",
  textAlign: "center",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  userSelect: "none",
  paddingTop: "15px",
  borderRadius: "3px",
  padding: grid,
  width: 250,
});

const OrderInputTable = ({
  updateAnswerState = () => {},
  orderableList,
  staticList,
  cardColTitle,
  staticColTitle,
  width,
}) => {
  const [currentAnswerSet, setCurrentAnswerSet] = useState(orderableList);

  const handleOrderChange = (newItems) => {
    const isCorrect = gradeAnswer(newItems);
    let newState = {
      ...createAnswerPartState("OrderInputTable"),
      isCorrect,
    };
    updateAnswerState(newState);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const answerCopy = [...currentAnswerSet];

    const newItems = reorder(
      answerCopy,
      result.source.index,
      result.destination.index,
    );

    setCurrentAnswerSet(newItems);
    handleOrderChange(newItems);
  };

  return (
    <div
      className="order-inputTable"
      style={{ height: `${currentAnswerSet.length * 70}px`, width }}
    >
      <div className="order-inputTitles">
        <div className="order-inputTitle static">{staticColTitle}</div>
        <div className="order-inputTitle cards">{cardColTitle}</div>
      </div>
      <div className="order-inputContent">
        <div className="order-staticCol">
          {staticList.map((row, i) => (
            <div key={`item_${i}`} className="order-staticCol--item">
              {row}
            </div>
          ))}
        </div>
        <div className="order-cardCol">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {currentAnswerSet.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          {item.cardContent}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

export default OrderInputTable;
