import React from "react";
import PropTypes from "prop-types";
import FacebookLogo from "./Logos/fb-logo.svg";
import GoogleLogo from "./Logos/google-logo.png";
import AppleLogo from "./Logos/apple-logo.svg";
import "./SocialButton.scss";

const PROVIDERS = {
  facebook: {
    title: "Facebook",
    logo: FacebookLogo,
  },
  google: {
    title: "Google",
    logo: GoogleLogo,
  },
  apple: {
    title: "Apple",
    logo: AppleLogo,
  },
};

const SocialButton = ({ provider, disabled, onClick }) => {
  const prov = PROVIDERS[provider];
  return (
    <div onClick={onClick} disabled={disabled} className="am-social-button">
      <div className="am-image-container">
        <img src={prov.logo} />
      </div>
      <div className="am-social-button-text">Continue with {prov.title}</div>
    </div>
  );
};

SocialButton.propTypes = {
  disabled: PropTypes.bool,
  provider: PropTypes.string,
  onClick: PropTypes.func,
};

export default SocialButton;
