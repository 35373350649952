const unit11 = {
  unitOrder: 11,
  unitName: "e and Other Irrational Numbers",
  unitDescription:
    "Irrational numbers are numbers that cannot be written as a fraction. We'll explore why in this unit, along with introductions to some famous irrational numbers and some ways that they're used in math.",
  unitLogo: {
    url: "",
  },
  lessons: [],
};

export default unit11;
