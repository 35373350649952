import React, { useState } from "react";
import { signUp } from "aws-amplify/auth";
import Button from "../../stories/Button/Button";
import { Link } from "react-router-dom";
import "./SignUp.scss";

const SignUp = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [signUpSuccess, setSignUpSuccess] = useState(false);

  const handleSignUp = async () => {
    // // Don't allow new sign ups for now
    // return false;
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      await signUp({
        username,
        password,
        options: {
          userAttributes: {
            email: username,
          },
        },
      });
      setSignUpSuccess(true);
    } catch (error) {
      console.error("Error during sign-up:", error);
      setError(error.message);
      setSignUpSuccess(false);
    }
  };

  return (
    <div className="sign-up-page">
      <div className="sign-up-top-left"></div>
      <div className="sign-up-top-right">
        <div className="sign-up-outer-container">
          <div className="sign-up-page--title">Sign Up</div>
          <div className="sign-up-container">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSignUp();
              }}
            >
              {error && <div className="error-message">{error}</div>}

              {!signUpSuccess ? (
                <>
                  <div className="sign-up-inputGroup">
                    <label className="sign-up-inputGroup--label" for="email">
                      Email
                    </label>
                    <input
                      id="email"
                      type="email"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>

                  <div className="sign-up-inputs">
                    <div className="sign-up-inputGroup">
                      <label
                        className="sign-up-inputGroup--label"
                        for="firstName"
                      >
                        First Name
                      </label>
                      <input
                        id="firstName"
                        className="sign-up-input--half"
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="sign-up-inputGroup">
                      <label
                        className="sign-up-inputGroup--label"
                        for="lastName"
                      >
                        Last Name
                      </label>
                      <input
                        className="sign-up-input--half"
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="sign-up-inputGroup">
                    <label className="sign-up-inputGroup--label" for="password">
                      Password
                    </label>
                    <input
                      id="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>

                  <div className="sign-up-inputGroup">
                    <label
                      className="sign-up-inputGroup--label"
                      for="password_confirm"
                    >
                      Confirm Password
                    </label>
                    <input
                      id="password_confirm"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>

                  <button type="submit" disabled={password !== confirmPassword}>
                    Sign Up
                  </button>
                </>
              ) : (
                <div className="sign-up-success-message">
                  Sign up successful! Please check your email to verify your
                  account.
                </div>
              )}
            </form>
            <div className="sign-up-links">
              <Link to="/signin">
                <Button>Log In</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
