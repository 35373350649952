function shuffleArray(originalArray) {
  let array = originalArray.slice();
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function getMultiples(factor, numOfMultiples, inSequence, min, max) {
  let multiples = [];
  for (let i = Math.ceil(min / factor); i * factor <= max; i++) {
    multiples.push(i * factor);
  }
  if (multiples.length < numOfMultiples) {
    throw new Error("Not enough multiples in the given range.");
  }
  if (inSequence) {
    return multiples.slice(0, numOfMultiples);
  }
  let randomMultiples = [];
  while (randomMultiples.length < numOfMultiples) {
    const randomIndex = Math.floor(Math.random() * multiples.length);
    randomMultiples.push(multiples[randomIndex]);
    multiples.splice(randomIndex, 1);
  }

  return randomMultiples;
}

function findFactors(num) {
  let factors = [];
  for (let i = 1; i <= Math.sqrt(num); i++) {
    if (num % i === 0) {
      // Check if i is a factor
      factors.push(i); // Add the factor to the array
      if (i !== num / i) {
        // Check to avoid adding the square root twice if it's a perfect square
        factors.push(num / i);
      }
    }
  }
  return factors.sort((a, b) => a - b); // Return the factors sorted in ascending order
}

const question4 = (questionArgs) => {
  const factor = questionArgs?.factor;
  const subFactors = findFactors(factor);
  const allOptions = [
    {
      type: "text",
      value: `1,${getMultiples(factor, 5, true, factor, 1000).join(",")}`,
      size: "md",
    },
    {
      type: "text",
      value: `${subFactors.join(",")},${getMultiples(factor, 2, true, factor + 1, 1000).join(",")}`,
      size: "md",
    },
    {
      type: "text",
      value: getMultiples(factor, 5, true, factor, 1000).join(","),
      size: "md",
    },
  ];
  const suffledOptions = shuffleArray(allOptions);
  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: `Which of the following only shows mulitples of ${factor}?`,
              bold: true,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
          ],
        },
      },
      {
        type: "ChoiceInput",
        args: {
          heading: "Select one answer:",
          type: "singleAnswer",
          options: suffledOptions,
        },
      },
    ],
  };
};

const args = {
  factor: 8,
};

export default question4(args);
