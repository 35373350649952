export const drawExpThroughPoints = (
  paper,
  points,
  asymptoteYInGridUnits = 0,
  lineColor,
  gridOffset,
  isPiecewise,
  sectionIndex,
  totalSections,
) => {
  const { xAxisYOffsetFromTop, yAxisXOffsetFromLeft } = gridOffset;

  const [pOne, pTwo] = points;
  let [h, k] = pOne;
  let [x2, y2] = pTwo;

  let pointA = { x: h, y: k };
  let pointB = { x: x2, y: y2 };

  const calculateConstants = (pointA, pointB, asymptoteY) => {
    let { x: px1, y: py1 } = pointA;
    let { x: px2, y: py2 } = pointB;

    py1 -= asymptoteY;
    py2 -= asymptoteY;
    let b = Math.pow(py2 / py1, 1 / (px2 - px1));
    let a = py1 / Math.pow(b, px1);

    let pA = Number(a.toFixed(30));
    let pB = Number(b.toFixed(30));

    return { pA, pB };
  };

  const { pA, pB } = calculateConstants(pointA, pointB, asymptoteYInGridUnits);

  const concatNum = (number, max) => {
    let numStr = number.toString();
    if (numStr.length > max) {
      numStr = numStr.substring(0, max);
    }
    while (numStr.length < max) {
      numStr = "0" + numStr;
    }
    return numStr;
  };

  const toPixelCoordinates = (x, y) => {
    return {
      pixelX: (x - yAxisXOffsetFromLeft) * 25,
      pixelY: -1 * ((y + xAxisYOffsetFromTop) * 25),
    };
  };

  let firstPointPlotted = false;
  let pathString = "";

  let startX = isPiecewise ? h : yAxisXOffsetFromLeft;
  let endX = isPiecewise ? x2 : yAxisXOffsetFromLeft + 20;
  let xStep = isPiecewise ? (endX - startX) / 100 : 0.1;
  let firstPixelPoint = null;
  let lastPixelPoint = null;

  for (let i = startX; i <= endX; i += xStep) {
    let adjX = Number(i.toFixed(12));
    let y = pA * Math.pow(pB, adjX) + asymptoteYInGridUnits;
    let { pixelX, pixelY } = toPixelCoordinates(adjX, y);

    // Ensure we only plot points within the graph's vertical limits
    if (pixelY >= -200 && pixelY <= 700) {
      let pixX = Number(pixelX.toFixed(8));
      let pixY = Number(pixelY.toFixed(8));

      // If first point, move to it. Else, draw line to it.
      if (!firstPointPlotted) {
        pathString += `M${concatNum(pixX, 8)},${concatNum(pixY, 8)} `;
        firstPointPlotted = true;
        firstPixelPoint = { pixelX, pixelY };
      } else {
        pathString += `L${concatNum(pixX, 8)},${concatNum(pixY, 8)} `;
        lastPixelPoint = { pixelX, pixelY };
      }
    }
  }

  if (pathString !== "") {
    paper.path(pathString).attr({ stroke: lineColor, "stroke-width": 2 });

    // Draw a circle at the start of the first section
    if (sectionIndex === 0 && firstPixelPoint) {
      paper.circle(firstPixelPoint.pixelX, firstPixelPoint.pixelY, 4).attr({
        fill: lineColor,
        stroke: lineColor,
      });
    }

    // Draw a circle at the end of the last section
    if (sectionIndex === totalSections - 1 && lastPixelPoint) {
      paper.circle(lastPixelPoint.pixelX, lastPixelPoint.pixelY, 4).attr({
        fill: lineColor,
        stroke: lineColor,
      });
    }
  }
};
