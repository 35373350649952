const unit12 = {
  unitOrder: 12,
  unitName: "Review & Practice",
  unitDescription:
    "Remember, practice makes perfect! Let's get fluent in Algebra 1, by tackling problems that use all that we've learned so far.",
  unitLogo: {
    url: "",
  },
  lessons: [],
};

export default unit12;
