export const createUnsimplifiedExpressionQ6 = (argParams = {}) => {
  const {
    numeratorA,
    denominatorA,
    numeratorB,
    denominatorB,
    coefficientA,
    numeratorC,
    denominatorC,
    varA,
  } = argParams;

  // Helper function to format fractions, handling opposite signs between numerator and denominator
  const formatFraction = (numerator, denominator) => {
    const sign = numerator.numberValue * denominator.numberValue < 0 ? "-" : "";
    return `${sign}\\frac{${Math.abs(numerator.displayValue)}}{${Math.abs(denominator.displayValue)}}`;
  };

  // Helper function to format the variable part of the expression
  const formatVariable = (varObj) =>
    `{\\textcolor{${varObj.color}}{${varObj.letterValue}}}`;

  // Build the expression step by step
  let expression = "";

  // Add the first term (numeratorA/denominatorA)varA, handling sign
  if (numeratorA.numberValue !== 0 && denominatorA.numberValue !== 0) {
    expression += `${formatFraction(numeratorA, denominatorA)}${formatVariable(varA)}`;
  }

  // Add the second term (numeratorB/denominatorB), handling sign
  if (numeratorB.numberValue !== 0 && denominatorB.numberValue !== 0) {
    const prefix =
      expression.length > 0
        ? numeratorB.numberValue / denominatorB.numberValue > 0
          ? "+"
          : ""
        : "";
    expression += `${prefix}${formatFraction(numeratorB, denominatorB)}`;
  }

  // Add the third term coefficientA(varA), handling sign
  if (coefficientA.numberValue !== 0) {
    const prefix =
      expression.length > 0 ? (coefficientA.numberValue > 0 ? "+" : "") : "";
    expression += `${prefix}${coefficientA.displayValue}${formatVariable(varA)}`;
  }

  // Add the fourth term (numeratorC/denominatorC), handling sign
  if (numeratorC.numberValue !== 0 && denominatorC.numberValue !== 0) {
    const prefix =
      expression.length > 0
        ? numeratorC.numberValue / denominatorC.numberValue > 0
          ? "+"
          : ""
        : "";
    expression += `${prefix}${formatFraction(numeratorC, denominatorC)}`;
  }

  return expression;
};

const question6 = (questionArgs) => {
  const initialExpression = createUnsimplifiedExpressionQ6(questionArgs);
  const trimmedKaTeX = initialExpression.replace(/\s+/g, "");
  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: true,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value:
                "Combine the like terms to create an equivalent expression: ",
              bold: true,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "katex",
              value: trimmedKaTeX,
            },
            {
              type: "text",
              value: " = ",
            },
            {
              type: "mathinput",
              size: "sm",
            },
          ],
        },
      },
    ],
  };
};

const args = {
  numeratorA: { numberValue: 9, displayValue: "9" },
  denominatorA: { numberValue: 8, displayValue: "8" },
  numeratorB: { numberValue: 9, displayValue: "9" },
  denominatorB: { numberValue: 10, displayValue: "10" },
  coefficientA: { numberValue: -2, displayValue: "-2" },
  numeratorC: { numberValue: -3, displayValue: "-3" },
  denominatorC: { numberValue: 5, displayValue: "5" },
  varA: { letterValue: "m", color: "#5280b1" },
};

export default question6(args);
