import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Button.scss";

const Button = ({ theme, type, disabled, children, onClick }) => {
  return (
    <div
      onClick={onClick}
      disabled={disabled}
      className={`am-button 
            am-button--${theme}
            ${type ? `am-button--${type}` : ""}`}
    >
      {children}
    </div>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  theme: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
