import { numWithSign, showVar, rando } from "./main";
import { fracty } from "./fracty";

export const getSingleVarInequality_level1 = (
  args,
  inequalitySign = { mathStr: "<", katex: "<" },
  randomNumA = null,
  randomNumB = null,
) => {
  const num = randomNumA || rando(4);
  const numB = randomNumB || rando(2);
  const { varA: vA, constantA: cA, coefficientA: tA } = args;
  let equationStr = ``;
  let katexStr = ``;
  const expressionSign = numB === 1 ? "+" : "-";
  switch (num) {
    case 1:
      // constantA + varA = coefficientA(varA)
      katexStr = `${numWithSign(cA, true)} ${expressionSign} ${showVar(vA)} ${inequalitySign?.katex} ${numWithSign(tA, true)}${showVar(vA)}`;
      equationStr = `${numWithSign(cA, true)} ${expressionSign} ${vA?.letterValue} ${inequalitySign?.mathStr} ${numWithSign(tA, true)}(${vA?.letterValue})`;
      break;
    case 2:
      // varA + constantA = coefficientA(varA)
      katexStr = `${showVar(vA)} ${numWithSign(cA)} ${inequalitySign?.katex} ${numWithSign(tA, true)}${showVar(vA)}`;
      equationStr = `${vA?.letterValue} ${numWithSign(cA)} ${inequalitySign?.mathStr} ${numWithSign(tA, true)}(${vA?.letterValue})`;
      break;
    case 3:
      // coefficientA(varA) = varA + constantA
      katexStr = `${numWithSign(tA, true)}${showVar(vA)} ${inequalitySign?.katex} ${showVar(vA)} ${numWithSign(cA)}`;
      equationStr = `${numWithSign(tA, true)}(${vA?.letterValue}) ${inequalitySign?.mathStr} ${vA?.letterValue} ${numWithSign(cA)}`;
      break;
    default:
      // coefficientA(varA) + constantA = varA
      katexStr = `${numWithSign(tA, true)}${showVar(vA)} ${numWithSign(cA)} ${inequalitySign?.katex} ${showVar(vA)}`;
      equationStr = `${numWithSign(tA, true)}(${vA?.letterValue}) ${numWithSign(cA)} ${inequalitySign?.mathStr} ${vA?.letterValue}`;
      break;
  }
  return {
    katexStr,
    equationStr,
  };
};

export const getSingleVarAndInequality_level1 = (
  args,
  inequalitySign = { mathStr: "<", katex: "<" },
  randomNumA = null,
  randomNumB = null,
) => {
  const num = randomNumA || rando(4);
  const numB = randomNumB || rando(2);
  const { varA: vA, constantA: cA, coefficientA: tA } = args;
  let equationStr = ``;
  let katexStr = ``;
  const expressionSign = numB === 1 ? "+" : "-";

  const connector = "\\text{AND}";
  const katexSpacer = "{\\textcolor{#ffffff}{\\text{S}}}";

  switch (num) {
    case 1:
      const leftSideA = `${numWithSign(cA, true)} ${expressionSign} ${showVar(vA)} ${inequalitySign?.katex} ${numWithSign(tA, true)}${showVar(vA)}`;
      const rightSideA = `${numWithSign(cA, true)} ${expressionSign} ${showVar(vA)} ${inequalitySign?.katex} ${numWithSign(tA, true)}${showVar(vA)}`;
      // constantA + varA = coefficientA(varA)
      katexStr = `(${leftSideA})${katexSpacer}${connector}${katexSpacer}(${rightSideA})`;
      equationStr = `${numWithSign(cA, true)} ${expressionSign} ${vA?.letterValue} ${inequalitySign?.mathStr} ${numWithSign(tA, true)}(${vA?.letterValue})`;
      break;
    case 2:
      // varA + constantA = coefficientA(varA)
      katexStr = `${showVar(vA)} ${numWithSign(cA)} ${inequalitySign?.katex} ${numWithSign(tA, true)}${showVar(vA)}`;
      equationStr = `${vA?.letterValue} ${numWithSign(cA)} ${inequalitySign?.mathStr} ${numWithSign(tA, true)}(${vA?.letterValue})`;
      break;
    case 3:
      // coefficientA(varA) = varA + constantA
      katexStr = `${numWithSign(tA, true)}${showVar(vA)} ${inequalitySign?.katex} ${showVar(vA)} ${numWithSign(cA)}`;
      equationStr = `${numWithSign(tA, true)}(${vA?.letterValue}) ${inequalitySign?.mathStr} ${vA?.letterValue} ${numWithSign(cA)}`;
      break;
    default:
      // coefficientA(varA) + constantA = varA
      katexStr = `${numWithSign(tA, true)}${showVar(vA)} ${numWithSign(cA)} ${inequalitySign?.katex} ${showVar(vA)}`;
      equationStr = `${numWithSign(tA, true)}(${vA?.letterValue}) ${numWithSign(cA)} ${inequalitySign?.mathStr} ${vA?.letterValue}`;
      break;
  }
  return {
    katexStr,
    equationStr,
  };
};

export const getRandomInequality = () => {
  const inequalities = [
    { mathStr: ">", katex: ">" },
    { mathStr: "<", katex: "<" },
    { mathStr: "=<", katex: "\\geq" },
    { mathStr: ">=", katex: "\\leq" },
  ];
  const randomIndex = Math.floor(Math.random() * inequalities.length);
  return inequalities[randomIndex];
};

// Compound Inequality Stuff

export const parseInequality = (ineq = "") => {
  // Using regex to split the string around the operators while keeping the operators in the result
  let parts = ineq?.split(/([<>]=?|==|!=)/).filter(Boolean);

  // Ensuring that the array has exactly three parts (variable, operator, value)
  if (parts.length === 3) {
    let [variable, op, value] = parts.map((part) => part.trim());
    value = eval(value); // Evaluate expressions like fractions
    return { variable, operator: op, value };
  } else {
    // If not properly formatted, throw an error or handle it accordingly
    // throw new Error("Invalid inequality format");
  }
};

export const evaluateAnd = (ineq1, ineq2) => {
  // Check for identical conditions and return the condition directly
  if (ineq1.operator === ineq2.operator && ineq1.value === ineq2.value) {
    return `${ineq1.variable} ${ineq1.operator} ${ineq1.value}`;
  }

  let range = {
    lower: -Infinity,
    lowerInclusive: false,
    upper: Infinity,
    upperInclusive: false,
  };

  [ineq1, ineq2].forEach((ineq) => {
    if (ineq.operator === "<" || ineq.operator === "<=") {
      if (
        ineq.value < range.upper ||
        (ineq.value === range.upper && ineq.operator === "<=")
      ) {
        range.upper = ineq.value;
        range.upperInclusive = ineq.operator === "<=";
      }
    }
    if (ineq.operator === ">" || ineq.operator === ">=") {
      if (
        ineq.value > range.lower ||
        (ineq.value === range.lower && ineq.operator === ">=")
      ) {
        range.lower = ineq.value;
        range.lowerInclusive = ineq.operator === ">=";
      }
    }
  });

  return formatInequalityRange(range, ineq1.variable);
};

export const formatInequalityRange = (range, variable) => {
  if (
    range.lower > range.upper ||
    (range.lower === range.upper &&
      !(range.lowerInclusive && range.upperInclusive))
  ) {
    return "No solutions";
  } else if (
    range.lower === range.upper &&
    range.lowerInclusive &&
    range.upperInclusive
  ) {
    return `${variable} = ${range.lower}`; // Handle exact value cases
  } else {
    // Initialize the result variable
    let result = "";

    // Check if lower bound is not -Infinity to include it in the result
    if (range.lower !== -Infinity) {
      const lowerBound = range.lowerInclusive
        ? `${range.lower} <= `
        : `${range.lower} < `;
      result += lowerBound + variable;
    }

    // Check if upper bound is not Infinity to include it in the result
    if (range.upper !== Infinity) {
      const upperBound = range.upperInclusive
        ? ` <= ${range.upper}`
        : ` < ${range.upper}`;
      // Check if we've already added the lower bound part to avoid repeating the variable
      if (result.length > 0) {
        result += upperBound;
      } else {
        result += variable + upperBound;
      }
    }

    // If neither bound was included, it means both are infinity, implying all x are a solution (unbounded)
    if (result === "") {
      result = "All values of " + variable + " are solutions";
    }

    return result;
  }
};

export const evaluateOr = (ineq1 = {}, ineq2 = {}) => {
  const variable = ineq1.variable;

  // Normalize the expressions for comparison
  const exprA = `${ineq1.variable} ${ineq1.operator} ${ineq1.value}`;
  const exprB = `${ineq2.variable} ${ineq2.operator} ${ineq2.value}`;

  // Return immediately if the conditions are identical
  if (exprA === exprB) {
    return exprA;
  }

  // Handling conditions where one operator is inclusive and the other is exclusive
  // and the values are the same, effectively covering all values
  if (ineq1.value === ineq2.value) {
    if (
      (ineq1.operator === "<=" && ineq2.operator === ">") ||
      (ineq1.operator === "<=" && ineq2.operator === ">=") ||
      (ineq1.operator === ">=" && ineq2.operator === "<") ||
      (ineq1.operator === ">=" && ineq2.operator === "<=") ||
      (ineq1.operator === "<" && ineq2.operator === ">=") ||
      (ineq1.operator === ">" && ineq2.operator === "<=")
    ) {
      return `All values of ${variable} are solutions`;
    }
  }

  if (ineq1.value < ineq2.value) {
    if (
      // x >= (n - a) or x < n
      (ineq1.operator === ">=" && ineq2.operator === "<") ||
      // x >= (n - a) or x <= n
      (ineq1.operator === ">=" && ineq2.operator === "<=") ||
      // x > (n - a) or x <= n
      (ineq1.operator === ">" && ineq2.operator === "<=") ||
      // x > (n - a) or x < n
      (ineq1.operator === ">" && ineq2.operator === "<")
    ) {
      return `All values of ${variable} are solutions`;
    }
  }

  if (ineq1.value > ineq2.value) {
    if (
      // x < n or x > (n - a)
      (ineq1.operator === "<" && ineq2.operator === ">") ||
      // x < n or x >= (n - a)
      (ineq1.operator === "<" && ineq2.operator === ">=") ||
      // x <= n or x > (n - a)
      (ineq1.operator === "<=" && ineq2.operator === ">") ||
      // x <= n or x >= (n - a)
      (ineq1.operator === "<=" && ineq2.operator === ">=")
    ) {
      return `All values of ${variable} are solutions`;
    }
  }

  // Normalize conditions to find the least restrictive when using "or"
  if (ineq1.operator.includes("<") && ineq2.operator.includes("<")) {
    const mostRestrictive = Math.max(ineq1.value, ineq2.value);
    const useInclusive =
      (ineq1.value === mostRestrictive && ineq1.operator === "<=") ||
      (ineq2.value === mostRestrictive && ineq2.operator === "<=");
    return `${variable} ${useInclusive ? "<=" : "<"} ${mostRestrictive}`;
  } else if (ineq1.operator.includes(">") && ineq2.operator.includes(">")) {
    const leastRestrictive = Math.min(ineq1.value, ineq2.value);
    const useInclusive =
      (ineq1.value === leastRestrictive && ineq1.operator === ">=") ||
      (ineq2.value === leastRestrictive && ineq2.operator === ">=");
    return `${variable} ${useInclusive ? ">=" : ">"} ${leastRestrictive}`;
  }

  // Default case if none of the above conditions apply
  return `${exprA} or ${exprB}`;
};

export const evaluateCompoundInequality = (eq1, eq2, operator) => {
  const validOperators = ["and", "or"]; // Define the valid operators

  if (!validOperators.includes(operator.toLowerCase())) {
    operator = "and"; // Default to 'and' if the operator is invalid
  }

  const ineq1 = parseInequality(eq1);
  const ineq2 = parseInequality(eq2);

  if (operator.toLowerCase() === "and") {
    return evaluateAnd(ineq1, ineq2);
  } else if (operator.toLowerCase() === "or") {
    return evaluateOr(ineq1, ineq2);
  }
};

export const formatCompoundInequality = (str) => {
  function recurse(s) {
    // Match the first decimal number in the string
    const match = s.match(/[-+]?\d*\.\d+/);
    if (!match) return s; // If no match, return the string as is

    const decimal = match[0];
    const index = match.index;
    const before = s.slice(0, index);
    const after = s.slice(index + decimal.length);

    // Apply the operation to the decimal
    const fraction = fracty(decimal);

    // Recurse on the remaining string and concatenate the results
    return before + fraction + recurse(after);
  }

  return recurse(str);
};
