import { drawLineThroughPoints } from "./linear";
import { drawParabolaThroughPoints } from "./parabola";
import { drawAbsValThroughPoints } from "./absolute";
import { drawExpThroughPoints } from "./exponential";

export const createPoint = (
  ppr,
  lineIndex,
  pointIndex,
  updatePoints,
  points,
  plotType,
  asymptoteYInGridUnits,
  currentLines,
  yAxisXOffsetFromLeft,
  xAxisYOffsetFromTop,
  setIsDragging,
  isDragging,
) => {
  const pointSet = currentLines[lineIndex][pointIndex];
  const [pointX, pointY] = pointSet;
  // Grid scale factor (adjust according to your grid)
  const scaleFactor = 25;
  const gridXOffsetValue = -25 * yAxisXOffsetFromLeft;
  const gridYOffsetValue = -25 * xAxisYOffsetFromTop;
  // Movable Point
  // Example point coordinates (One Point)
  let x = gridXOffsetValue + pointX * scaleFactor,
    y = gridYOffsetValue - pointY * scaleFactor;
  const radius = 7; // Size of the point
  const hoverIncrease = 4;

  // Create a point that can be moved
  const movablePoint = ppr.circle(x, y, radius).attr({
    fill: "#f75e4a", // Fucia
    stroke: "#f75e4a", // Fucia
    "stroke-width": 1,
    cursor: isDragging ? "grabbing" : "grab",
  });

  // Drag functionality
  const onmove = (dx, dy) => {
    // Calculate nearest grid point without snapping
    const tempX =
      Math.round((x + dx - gridXOffsetValue) / scaleFactor) * scaleFactor +
      gridXOffsetValue;
    const tempY =
      Math.round((y + dy - gridYOffsetValue) / scaleFactor) * scaleFactor +
      gridYOffsetValue;

    let newX = (tempX - gridXOffsetValue) / scaleFactor;
    let newY = (-1 * (tempY - gridYOffsetValue)) / scaleFactor;

    // Constrain graphX and graphY to be within +/- 9
    const gridSideLengthInUnits = 20;
    const xBounds = {
      low: yAxisXOffsetFromLeft + 1,
      high: yAxisXOffsetFromLeft + gridSideLengthInUnits - 1,
    };
    const yBounds = {
      low: -1 * (xAxisYOffsetFromTop + gridSideLengthInUnits - 1),
      high: -1 * (xAxisYOffsetFromTop + 1),
    };

    newX = Math.max(xBounds.low, Math.min(xBounds.high, newX));
    newY = Math.max(yBounds.low, Math.min(yBounds.high, newY));

    // Plan:
    // 1. know grid type
    const isE = plotType === "exponential";
    const isL = plotType === "line";
    const isP = plotType === "parabola";
    const isA = plotType === "absolute";

    const otherPointIndex = pointIndex === 0 ? 1 : 0;
    // if Exp/Log, ensure new pointY is not the asymptote
    let newYisOnTheAsymptote = false;
    if (isE) {
      newYisOnTheAsymptote = newY === asymptoteYInGridUnits;
    }
    // if Exp/Log, ensure pointAX and pointBX don't match
    const AxBxMatch = currentLines[lineIndex][otherPointIndex][0] === newX;

    let updatedLines = [...currentLines];
    let updatedPoints = [...updatedLines[lineIndex]];

    // if Parabola, don't allow point and vertex on the same y
    const AyByMatch = currentLines[lineIndex][otherPointIndex][1] === newY;

    const dontDrawNewPoint =
      (isP && AyByMatch) ||
      (isE && newYisOnTheAsymptote) ||
      ((isE || isP || isA) && AxBxMatch);

    if (!dontDrawNewPoint) {
      updatedPoints[pointIndex] = [newX, newY];
      updatedLines[lineIndex] = updatedPoints;
      updatePoints(updatedLines);

      // Move the current point to follow the cursor without altering its behavior based on y-axis crossing
      movablePoint.attr({ cx: tempX, cy: tempY });
    }
  };
  const onstart = () => {
    setIsDragging(true);
  };
  const onend = () => {
    setIsDragging(false);
    // Snap to the nearest grid point
    x = movablePoint.attr("cx");
    y = movablePoint.attr("cy");

    // Convert back to grid coordinates, round to nearest whole number, then convert back to pixel coordinates
    const snappedX =
      Math.round((x - gridXOffsetValue) / scaleFactor) * scaleFactor +
      gridXOffsetValue;
    const snappedY =
      Math.round((y - gridYOffsetValue) / scaleFactor) * scaleFactor +
      gridYOffsetValue;

    // Update the point's position to the snapped coordinates
    movablePoint.attr({ cx: snappedX, cy: snappedY });

    // Update x and y to the snapped coordinates for consistency
    x = snappedX;
    y = snappedY;
  };

  movablePoint.drag(onmove, onstart, onend);

  // Hover functionality
  movablePoint.hover(
    function () {
      // Hover in
      this.animate({ r: radius + hoverIncrease }, 200); // Increase size
    },
    function () {
      // Hover out
      this.animate({ r: radius }, 200); // Restore original size
    },
  );
};

export const drawPlot = (
  paper,
  yAxisXOffsetFromLeft,
  xAxisYOffsetFromTop,
  zoomDivisor,
) => {
  const totalUnits = 20;
  const gridSize = 500;
  const unitPerPixel = gridSize / totalUnits;
  const xAxisPosition = Math.abs(xAxisYOffsetFromTop) * unitPerPixel; // New Y-axis position based on xAxisYOffsetFromTop
  const yAxisPosition = Math.abs(yAxisXOffsetFromLeft) * unitPerPixel; // New X-axis position based on yAxisXOffsetFromLeft

  // Border around the plot
  paper
    .rect(0, 0, gridSize, gridSize)
    .attr({ stroke: "#ccc", "stroke-width": 1, fill: "none" });

  // Adjusted x/y plane
  paper
    .path(`M0 ${xAxisPosition}L${gridSize} ${xAxisPosition}`)
    .attr({ stroke: "#000", "stroke-width": 2 });
  paper
    .path(`M${yAxisPosition} 0L${yAxisPosition} ${gridSize}`)
    .attr({ stroke: "#000", "stroke-width": 2 });

  // Add arrow at the right end of the x-axis
  paper
    .path(
      `M${gridSize - 10} ${xAxisPosition - 5}L${gridSize} ${xAxisPosition}L${
        gridSize - 10
      } ${xAxisPosition + 5}`,
    )
    .attr({ stroke: "#000", "stroke-width": 2 });

  // Add arrow at the top end of the y-axis
  paper
    .path(`M${yAxisPosition - 5} 10L${yAxisPosition} 0L${yAxisPosition + 5} 10`)
    .attr({ stroke: "#000", "stroke-width": 2 });

  // Add arrow at the left end of the x-axis
  paper
    .path(`M10 ${xAxisPosition - 5}L0 ${xAxisPosition}L10 ${xAxisPosition + 5}`)
    .attr({ stroke: "#000", "stroke-width": 2 });

  // Add arrow at the bottom end of the y-axis
  paper
    .path(
      `M${yAxisPosition - 5} ${gridSize - 10}L${yAxisPosition} ${gridSize}L${
        yAxisPosition + 5
      } ${gridSize - 10}`,
    )
    .attr({ stroke: "#000", "stroke-width": 2 });

  // Draw ticks, grid lines, and labels on x-axis and y-axis, labeling every other unit
  for (let i = 0; i <= totalUnits; i++) {
    let pos = i * unitPerPixel;
    let labelX = yAxisXOffsetFromLeft + i;
    let labelY = xAxisYOffsetFromTop + i;

    // Optional: Grid lines for reference
    paper.path(`M${pos} 0L${pos} ${gridSize}`).attr({ stroke: "#ccc" });
    paper.path(`M0 ${pos}L${gridSize} ${pos}`).attr({ stroke: "#ccc" });

    let iIsEven = i % 2 == 0;
    let posNotEnd = pos !== 500;
    let posNotStart = pos !== 0;
    if (iIsEven && posNotEnd && posNotStart) {
      // Label every other unit
      // X-axis ticks and labels
      if (Number(labelX) !== 0) {
        paper
          .path(`M${pos} ${xAxisPosition - 5}L${pos} ${xAxisPosition + 5}`)
          .attr({ stroke: "#000" });
        paper.text(pos, xAxisPosition + 20, labelX / zoomDivisor).attr({
          "font-size": 14,
          "font-weight": 500,
          "font-family": "'Helvetica Neue', Arial, sans-serif",
        });
      }

      if (Number(labelY) !== 0) {
        // Y-axis ticks and labels (invert Y because screen Y goes down)
        paper
          .path(`M${yAxisPosition - 5} ${pos}L${yAxisPosition + 5} ${pos}`)
          .attr({ stroke: "#000" });
        paper.text(yAxisPosition - 20, pos, -labelY / zoomDivisor).attr({
          "font-size": 14,
          "font-weight": 500,
          "font-family": "'Helvetica Neue', Arial, sans-serif",
        });
      }
    }
  }
};

export const connectPoints = (
  ppr,
  pts,
  typ,
  asymptoteYInGridUnits,
  lineColor = "#2d81ff",
  yAxisXOffsetFromLeft,
  xAxisYOffsetFromTop,
) => {
  const gridOffset = {
    yAxisXOffsetFromLeft,
    xAxisYOffsetFromTop,
  };
  const handlers = {
    line: drawLineThroughPoints,
    parabola: drawParabolaThroughPoints,
    absolute: drawAbsValThroughPoints,
    exponential: (paper, points, lineColor, gridOffset) =>
      drawExpThroughPoints(
        paper,
        points,
        asymptoteYInGridUnits,
        lineColor,
        gridOffset,
      ),
  };
  // Check if the typ exists as a key in handlers
  if (typeof handlers[typ] === "function") {
    handlers[typ](ppr, pts, lineColor, gridOffset);
  } else {
    console.error(`No handler defined for type: ${typ}`);
  }
};

export const redrawPoints = (currentPoints, plotType, prevPoints) => {
  // Check for exponential plot type once since it doesn't change per line
  const isE = plotType === "exponential";

  // Ensure there's something to compare to
  if (!prevPoints || currentPoints.length !== prevPoints.length) {
    return true; // If the number of lines has changed, definitely redraw
  }

  for (let i = 0; i < currentPoints.length; i++) {
    const line = currentPoints[i];
    const prevLine = prevPoints[i];

    // Assuming each line will always have two points for simplicity
    // This will need adjusting if lines can have more than two points
    const [[x1, y1], [x2, y2]] = line;
    const [[x01, y01], [x02, y02]] = prevLine;

    // Check if any point in the current line has changed from the previous
    if (x01 !== x1 || y01 !== y1 || x02 !== x2 || y02 !== y2) {
      return true; // Any point change requires a redraw
    }

    // Additional check for non-linear plots where x values are the same
    if (plotType !== "linear" && x1 === x2) {
      return false; // Don't redraw for non-linear plots where x values haven't changed
    }
  }

  // If we make it here, it means no points have changed, and there's no special case preventing redraw
  return false;
};

export const processExpPoints = (
  prevPoints,
  currentPoints,
  plotType,
  asymptoteY,
  yAxisXOffsetFromLeft,
  xAxisYOffsetFromTop,
) => {
  // Assuming exponential plot type has special handling
  const isE = plotType === "exponential";

  if (!isE) {
    return currentPoints; // For non-exponential, return as is
  }

  return currentPoints.map((line, i) => {
    const prevLine = prevPoints[i];

    // Simplifying assumption: each line consists of exactly two points
    const [[x01, y01], [x02, y02]] = prevLine;
    const [[x1, y1], [x2, y2]] = line;

    // Determine which point has changed based on the x and y values
    let changedIndex = x01 === x1 && y01 === y1 ? 1 : 0;

    let yValuesAreOnDifferentSidesOfAsymptote =
      (prevLine[changedIndex][1] - asymptoteY) *
        (line[changedIndex][1] - asymptoteY) <
      0;

    if (!yValuesAreOnDifferentSidesOfAsymptote) {
      return line; // No processing needed if y values are on the same side of the asymptote
    } else {
      // If y values are on different sides of the asymptote, adjust the other point
      let otherIndex = changedIndex === 1 ? 0 : 1;
      let newPoints = [...line]; // Clone the line to avoid mutating the original
      // Calculate the difference from the asymptote and mirror it for the other point
      let differenceFromAsymptote = asymptoteY - newPoints[otherIndex][1];
      let mirroredY = asymptoteY + differenceFromAsymptote;
      // Clamp the y value to the bounds of the grid coordinates
      const gridSideLengthInUnits = 20;
      const yBounds = {
        low: -1 * (xAxisYOffsetFromTop + gridSideLengthInUnits - 1),
        high: -1 * (xAxisYOffsetFromTop + 1),
      };
      newPoints[otherIndex][1] = Math.max(
        yBounds.low,
        Math.min(yBounds.high, mirroredY),
      );
      return newPoints;
    }
  });
};

export const drawAsymptote = (
  paper,
  setYinGridUnits,
  fixedAsymptote,
  asymptoteYInGridUnits, // 0 equals x-axis y position
  allLines,
  asymptoteHexColor,
  yAxisXOffsetFromLeft,
  xAxisYOffsetFromTop,
  isDragging,
  setIsDraggingAsymptote,
  isDraggingAsymptote,
) => {
  // allLines in the form [ [ [x1, y1], [x2, y2] ], [ [x1, y1], [x2, y2] ] ]
  const graphSVGElement = paper.canvas;
  const rect = graphSVGElement.getBoundingClientRect();
  const graphTop = rect.top;

  const scaleFactor = 25;
  let y = -1 * ((xAxisYOffsetFromTop + asymptoteYInGridUnits) * scaleFactor); // Initial position of the asymptote

  // Create the visible asymptote path
  let visibleAsymptote = paper.path(`M0 ${y}L500 ${y}`);
  let asymptote = paper.path(`M0 ${y}L500 ${y}`); // Invisible, for wider interaction area

  asymptote.attr({
    stroke: asymptoteHexColor,
    "stroke-width": 20, // Invisible wider border for interaction
    "stroke-opacity": 0, // Make it invisible
    cursor: isDragging ? "grabbing" : fixedAsymptote ? "default" : "move",
  });

  visibleAsymptote.attr({
    stroke: asymptoteHexColor,
    "stroke-width": fixedAsymptote ? 2 : 3, // Increase this for visual thickness
  });

  const notInRange = (a, b, c) => {
    // return true if a is not b or c, and a is not between b or c
    return a !== b && a !== c && (a < Math.min(b, c) || a > Math.max(b, c));
  };

  const onmove = (dx, dy, x, y) => {
    const adjustedY = y - graphTop;
    const nearestGridPointInPixelsFromTheTop = Math.max(
      0,
      Math.min(500, Math.round(adjustedY / 25) * 25),
    );

    let nearestGridPointInGridUnitsFromXAxis = Math.round(
      (Math.abs(xAxisYOffsetFromTop * 25) -
        nearestGridPointInPixelsFromTheTop) /
        25,
    );

    let lowBound = -1 * (xAxisYOffsetFromTop + 20) + 1;

    // bound the asymptote y to 1 less than the max (in this case 10)
    nearestGridPointInGridUnitsFromXAxis = Math.max(
      lowBound,
      Math.min(lowBound + 18, nearestGridPointInGridUnitsFromXAxis),
    );

    let outOfBoundsCount = 0;
    allLines.map((linePoints) => {
      const [[x1, y1], [x2, y2]] = linePoints;
      outOfBoundsCount += notInRange(
        nearestGridPointInGridUnitsFromXAxis,
        y1,
        y2,
      )
        ? 0
        : 1;
    });

    if (outOfBoundsCount === 0) {
      visibleAsymptote.attr({
        path: `M0 ${nearestGridPointInPixelsFromTheTop}L500 ${nearestGridPointInPixelsFromTheTop}`,
      });
      asymptote.attr({
        path: `M0 ${nearestGridPointInPixelsFromTheTop}L500 ${nearestGridPointInPixelsFromTheTop}`,
      });
      setYinGridUnits(nearestGridPointInGridUnitsFromXAxis);
    }
  };

  if (!fixedAsymptote) {
    asymptote.drag(
      onmove,
      function () {
        setIsDraggingAsymptote(true);
      },
      function () {
        setIsDraggingAsymptote(false);
      },
    );
    asymptote.hover(
      function () {
        visibleAsymptote.animate({ "stroke-width": isDragging ? 3 : 5 }, 100);
      },
      function () {
        visibleAsymptote.animate({ "stroke-width": 3 }, 100);
      },
    );
  }
};

export const updatePoints = (
  pointsObj,
  setIsDragging,
  isDragging,
  setIsDraggingAsymptote,
  isDraggingAsymptote,
) => {
  const {
    showAsymptote,
    paper,
    setAsymptoteYInGridUnits,
    fixedAsymptote,
    asymptoteYInGridUnits,
    points,
    setCurrentPoints,
    asymptoteHexColor,
    plotType,
    lineIndex,
    allLines,
    lineColor,
    yAxisXOffsetFromLeft,
    xAxisYOffsetFromTop,
    showPoints,
  } = pointsObj;
  if (showAsymptote) {
    drawAsymptote(
      paper,
      setAsymptoteYInGridUnits,
      fixedAsymptote,
      asymptoteYInGridUnits,
      allLines,
      asymptoteHexColor,
      yAxisXOffsetFromLeft,
      xAxisYOffsetFromTop,
      isDragging,
      setIsDraggingAsymptote,
      isDraggingAsymptote,
    );
  }
  connectPoints(
    paper,
    points,
    plotType,
    asymptoteYInGridUnits,
    lineColor,
    yAxisXOffsetFromLeft,
    xAxisYOffsetFromTop,
  );
  if (showPoints) {
    points.forEach((pointSet, pointIndex) => {
      createPoint(
        paper,
        lineIndex,
        pointIndex,
        setCurrentPoints,
        points,
        plotType,
        asymptoteYInGridUnits,
        allLines,
        yAxisXOffsetFromLeft,
        xAxisYOffsetFromTop,
        setIsDragging,
        isDragging,
      );
    });
  }
};

export const drawCubic = (
  cubicCoefficients,
  yAxisXOffsetFromLeft,
  xAxisYOffsetFromTop,
  dynamicPaper,
  lineColors,
  zoomDivisor,
) => {
  const { a, b, c, d } = cubicCoefficients; // Expecting coefficients for cubic equation now
  let pathString = "";
  let xStep = Math.round((0.05 * 1000) / zoomDivisor) / 1000;
  for (let x = -10; x <= 10; x += xStep) {
    const y = a * Math.pow(x, 3) + b * Math.pow(x, 2) + c * x + d; // Cubic equation
    const pixelX = (x * zoomDivisor - yAxisXOffsetFromLeft) * 25;
    const pixelY = -1 * ((y * zoomDivisor + xAxisYOffsetFromTop) * 25);

    if (pixelY >= -200 && pixelY <= 700) {
      pathString += `${pathString === "" ? "M" : "L"}${pixelX},${pixelY} `;
    }
  }

  if (pathString !== "") {
    dynamicPaper
      .path(pathString)
      .attr({ stroke: lineColors[0], "stroke-width": 2 });
  }
};

export const drawQuartic = (
  quarticCoefficients,
  yAxisXOffsetFromLeft,
  xAxisYOffsetFromTop,
  dynamicPaper,
  lineColors,
  zoomDivisor,
) => {
  const { a, b, c, d, e } = quarticCoefficients; // Expecting coefficients for quartic equation now
  let pathString = "";
  let xStep = Math.round((0.05 * 1000) / zoomDivisor) / 1000;
  for (let x = -10; x <= 10; x += xStep) {
    const y =
      a * Math.pow(x, 4) + b * Math.pow(x, 3) + c * Math.pow(x, 2) + d * x + e; // Quartic equation
    const pixelX = (x * zoomDivisor - yAxisXOffsetFromLeft) * 25;
    const pixelY = -1 * ((y * zoomDivisor + xAxisYOffsetFromTop) * 25);

    if (pixelY >= -200 && pixelY <= 700) {
      pathString += `${pathString === "" ? "M" : "L"}${pixelX},${pixelY} `;
    }
  }

  if (pathString !== "") {
    dynamicPaper
      .path(pathString)
      .attr({ stroke: lineColors[0], "stroke-width": 2 });
  }
};

export const drawQuintic = (
  quinticCoefficients,
  yAxisXOffsetFromLeft,
  xAxisYOffsetFromTop,
  dynamicPaper,
  lineColors,
  zoomDivisor,
) => {
  const { a, b, c, d, e, f } = quinticCoefficients;
  let pathString = "";
  let xStep = Math.round((0.05 * 1000) / zoomDivisor) / 1000;
  for (let x = -10; x <= 10; x += xStep) {
    const y =
      a * Math.pow(x, 5) +
      b * Math.pow(x, 4) +
      c * Math.pow(x, 3) +
      d * Math.pow(x, 2) +
      e * x +
      f;
    const pixelX = (x * zoomDivisor - yAxisXOffsetFromLeft) * Math.round(25);
    const pixelY =
      -1 * ((y * zoomDivisor + xAxisYOffsetFromTop) * Math.round(25));

    if (pixelY >= -200 && pixelY <= 700) {
      pathString += `${pathString === "" ? "M" : "L"}${pixelX},${pixelY} `;
    }
  }

  if (pathString !== "") {
    dynamicPaper
      .path(pathString)
      .attr({ stroke: lineColors[0], "stroke-width": 2 });
  }
};

export const drawPiecewise = ({
  plotSections,
  yAxisXOffsetFromLeft,
  xAxisYOffsetFromTop,
  dynamicPaper,
  lineColors,
}) => {
  const gridOffset = { yAxisXOffsetFromLeft, xAxisYOffsetFromTop };
  // Loop through each plot section
  const totalSections = plotSections.length;
  plotSections.forEach((section, index) => {
    const lineColor = lineColors[index % lineColors.length]; // Cycle through line colors if not enough are provided
    const isPiecewise = true;
    const sectionIndex = index;
    switch (section.plotSectionType) {
      case "line":
        section.plotPoints.forEach((linePoints) => {
          drawLineThroughPoints(
            dynamicPaper,
            linePoints,
            lineColor,
            gridOffset,
            isPiecewise,
            sectionIndex,
            totalSections,
          );
        });
        break;
      case "exponential":
        section.plotPoints.forEach((expPoints) => {
          const gridOffset = { yAxisXOffsetFromLeft, xAxisYOffsetFromTop };
          drawExpThroughPoints(
            dynamicPaper,
            expPoints,
            0,
            lineColor,
            gridOffset,
            isPiecewise,
            sectionIndex,
            totalSections,
          );
        });
        break;
      case "parabola":
        section.plotPoints.forEach((expPoints) => {
          const gridOffset = { yAxisXOffsetFromLeft, xAxisYOffsetFromTop };
          drawParabolaThroughPoints(
            dynamicPaper,
            expPoints,
            lineColor,
            gridOffset,
            isPiecewise,
            sectionIndex,
            totalSections,
          );
        });
        break;
      default:
        console.error(`Unknown plot section type: ${section.plotSectionType}`);
    }
  });
};
