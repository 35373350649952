import React, { useState, useRef, useEffect } from "react";
import { Stage, Layer, Rect, Circle, Line, Group } from "react-konva";
import Konva from "konva";
import Erase from "./images/erase-icon.svg";
import ActiveErase from "./images/erase-icon-active.svg";
import Grid from "./images/grid-icon.svg";
import ActiveGrid from "./images/grid-icon-active.svg";
import Move from "./images/move-icon.svg";
import ActiveMove from "./images/move-icon-active.svg";
import Undo from "./images/undo-icon.svg";
import Caret from "./images/caretc.svg";
import "./SketchPad.scss";

const SketchComponent = ({ gridUnitSize = 25, penSize = 2 }) => {
  const [lines, setLines] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [activeColor, setActiveColor] = useState("#152744");
  const [movingMode, setMovingMode] = useState(false);
  const stageRef = useRef(null);
  const [showGrid, setShowGrid] = useState(true);
  const gridLayerRef = useRef(null);
  const [eraserActive, setEraserActive] = useState(false);
  const [eraserBox, setEraserBox] = useState(null); // {startX, startY, endX, endY}
  const [drawingEraserBox, setDrawingEraserBox] = useState(false);
  const [currentHeight, setCurrentHeight] = useState(300);
  const [colorPickerIsOpen, setColorPickerIsOpen] = useState(false);
  const [stageOffset, setStageOffset] = useState({ x: 0, y: 0 });

  const colorOptions = [
    "#152744", // dark blue
    "#2FC5CC", // teal
    "#EE4266", // fucia
    "#FFD23F", // yellow
    "#3559E0", // blue
    "#75DAAD", // green
    "#7F78D2", // purple
  ];

  const handleHeightAdjusterMouseDown = (e) => {
    e.preventDefault();
    document.body.style.cursor = "grabbing";
    const initialClientY = e.clientY; // capture initial Y position on mouse down
    const initialHeight = currentHeight; // capture the initial height of the SketchPad

    const handleMouseMove = (ev) => {
      ev.preventDefault();
      const deltaY = ev.clientY - initialClientY; // difference from the initial Y position
      const newHeight = Math.max(
        300,
        Math.min(window.innerHeight / 1.5, initialHeight - deltaY),
      ); // adjust height based on deltaY
      setCurrentHeight(newHeight);
    };

    const handleMouseUp = (ev) => {
      ev.preventDefault();
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      document.body.style.cursor = "";
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleToggleGrid = () => {
    setColorPickerIsOpen(false);
    setShowGrid((prevShowGrid) => {
      if (prevShowGrid) {
        // Clear grid
        gridLayerRef.current.destroyChildren();
        gridLayerRef.current.batchDraw();
      }
      return !prevShowGrid;
    });
  };

  useEffect(() => {
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    if (currentHeight > 528) {
      setColorPickerIsOpen(false);
    }
  }, [currentHeight]);

  useEffect(() => {
    const stage = stageRef.current;
    const gridLayer = gridLayerRef.current;

    const handleDragMove = () => {
      if (movingMode && showGrid) {
        drawGrid(stage, gridLayer);
      }
    };

    // Attach the dragmove listener
    stage.on("dragmove", handleDragMove);

    // Initial draw or clear the grid
    if (showGrid) {
      drawGrid(stage, gridLayer);
    } else {
      gridLayer.clear();
    }

    // Cleanup
    return () => {
      stage.off("dragmove", handleDragMove);
      gridLayer.clear();
    };
  }, [showGrid, movingMode]);

  useEffect(() => {
    if (isDrawing || drawingEraserBox) {
      window.addEventListener("mouseup", handleMouseUpGlobal);
      return () => {
        window.removeEventListener("mouseup", handleMouseUpGlobal);
      };
    }
  }, [isDrawing, drawingEraserBox]);

  const handleMouseDown = (e) => {
    if (movingMode) return;
    if (eraserActive) {
      const { x, y } = e.target.getStage().getPointerPosition();
      setEraserBox({ startX: x, startY: y, endX: x, endY: y });
      setDrawingEraserBox(true);
    } else {
      setIsDrawing(true);
      const stage = e.target.getStage();
      const point = stage.getPointerPosition();
      setLines([
        ...lines,
        {
          points: [point.x - stageOffset.x, point.y - stageOffset.y],
          color: activeColor,
          size: penSize,
        },
      ]);
    }
    // Ensure the mouse up listener is attached only once
    window.removeEventListener("mouseup", handleMouseUpGlobal);
    window.addEventListener("mouseup", handleMouseUpGlobal);
  };

  const handleMouseUpGlobal = (e) => {
    window.removeEventListener("mouseup", handleMouseUpGlobal); // Clean up
    if (drawingEraserBox) {
      setDrawingEraserBox(false);
      eraseLinesWithinBox();
    }
    setIsDrawing(false);
  };

  const handleMouseUp = () => {
    if (drawingEraserBox) {
      setDrawingEraserBox(false);
      eraseLinesWithinBox();
      console.log("Local Mouse Up: Erase lines function called.");
    }
    setIsDrawing(false);
  };

  const handleUndo = () => {
    setColorPickerIsOpen(false);
    const newLines = lines.slice(0, -1);
    setLines(newLines);
  };

  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    if (!stage) {
      return; // Ensure there's a stage to work with
    }

    if (drawingEraserBox) {
      const { x, y } = stage.getPointerPosition();
      setEraserBox((prevBox) => ({
        ...prevBox,
        endX: x,
        endY: y,
      }));
    } else if (isDrawing && !movingMode) {
      const point = stage.getPointerPosition();
      const lastLine = lines[lines.length - 1];

      if (lastLine && Array.isArray(lastLine.points)) {
        // Update points directly without re-fetching the entire array from state
        const newPoints = [
          ...lastLine.points,
          point.x - stage.x(), // Use stage's x() and y() for current offsets
          point.y - stage.y(),
        ];

        const smoothedPoints = smoothLinePoints(newPoints, 300);

        // Update only the last line without triggering multiple state updates
        setLines((lines) => [
          ...lines.slice(0, -1),
          { ...lastLine, points: smoothedPoints },
        ]);
      }
    }
  };

  const toggleMode = () => {
    const newMode = !movingMode;
    setColorPickerIsOpen(false);
    setEraserActive(false);
    setMovingMode(newMode);
  };

  const smoothLinePoints = (points, smoothness = 1) => {
    if (points.length <= 4 || smoothness <= 0) {
      return points; // Not enough points to smooth or invalid smoothness
    }
    let smoothedPoints = [points[0], points[1]]; // Start with the first point

    for (let i = 2; i < points.length - 2; i += 2) {
      let prevX = points[i - 2],
        currX = points[i],
        nextX = points[i + 2];
      let prevY = points[i - 1],
        currY = points[i + 1],
        nextY = points[i + 3];

      // Calculate weighted average based on the smoothness parameter
      let avgX = (prevX + smoothness * currX + nextX) / (2 + smoothness);
      let avgY = (prevY + smoothness * currY + nextY) / (2 + smoothness);

      smoothedPoints.push(avgX, avgY);
    }

    // Add the last point
    smoothedPoints.push(points[points.length - 2], points[points.length - 1]);

    return smoothedPoints;
  };

  const drawGrid = (stage, layer) => {
    layer.destroyChildren(); // Clear the grid before redrawing

    const gridSize = gridUnitSize; // for a denser grid, halve the original size
    const padding = gridSize * 2; // additional padding to ensure grid covers new areas

    // Get the current scale, position, and size of the stage
    const scale = stage.scaleX();
    const sPos = stage.position();
    const { x: stageX, y: stageY } = sPos;
    const stageWidth = stage.width() / scale;
    const stageHeight = stage.height() / scale;

    const xOffset = stageX % gridSize;
    const yOffset = stageY % gridSize;

    // Calculate the viewport's boundaries
    const minX = -stageX + xOffset / scale - padding;
    const maxX = minX + stageWidth + padding * 2;
    const minY = -stageY + yOffset / scale - padding;
    const maxY = minY + stageHeight + padding * 2;

    // Generate vertical grid lines within the viewport
    for (let i = minX; i < maxX; i += gridSize) {
      layer.add(
        new Konva.Line({
          points: [i, minY, i, maxY],
          stroke: "#ddd",
          strokeWidth: 1,
          listening: false,
        }),
      );
    }

    // Generate horizontal grid lines within the viewport
    for (let j = minY; j < maxY; j += gridSize) {
      layer.add(
        new Konva.Line({
          points: [minX, j, maxX, j],
          stroke: "#ddd",
          strokeWidth: 1,
          listening: false,
        }),
      );
    }

    layer.batchDraw();
  };

  const eraseLinesWithinBox = () => {
    console.log("Erasing lines within box:", eraserBox);
    const newLines = lines.filter((line) => {
      const intersects = lineIntersectsBox(line, eraserBox);
      console.log("Line:", line, "Intersects:", intersects); // Log intersection check
      return !intersects;
    });
    setLines(newLines);
    setEraserBox(null);
  };

  const lineIntersectsBox = (line, box) => {
    const minX = Math.min(box.startX - stageOffset.x, box.endX - stageOffset.x);
    const minY = Math.min(box.startY - stageOffset.y, box.endY - stageOffset.y);
    const maxX = Math.max(box.startX - stageOffset.x, box.endX - stageOffset.x);
    const maxY = Math.max(box.startY - stageOffset.y, box.endY - stageOffset.y);
    // Check if any part of the line is within the box
    for (let i = 0; i < line.points.length - 1; i++) {
      const pointX = line.points[i];
      const pointY = line.points[i + 1];

      if (
        pointX >= minX &&
        pointX <= maxX &&
        pointY >= minY &&
        pointY <= maxY
      ) {
        return true;
      }
    }

    return false;
  };

  const handleEraserClick = () => {
    let currentEraserState = !eraserActive;
    setMovingMode(false);
    setColorPickerIsOpen(false);
    setEraserActive(currentEraserState);
  };

  const handleColorPick = (color) => {
    setMovingMode(false);
    setEraserActive(false);
    setActiveColor(color);
    setColorPickerIsOpen(false);
  };

  const toggleColorPicker = () => {
    if (movingMode || eraserActive) {
      setMovingMode(false);
      setEraserActive(false);
      return;
    }
    const isOpen = colorPickerIsOpen;
    setColorPickerIsOpen(!isOpen);
  };

  const handleDragEnd = (e) => {
    const newPosition = e.target.position();
    setStageOffset(newPosition);
  };

  const showMiniToolbar = currentHeight < 530;

  return (
    <div className="sketch-container" style={{ height: `${currentHeight}px` }}>
      <div className="canvas-container">
        <div
          className="canvas-heightAdjuster"
          onMouseDown={handleHeightAdjusterMouseDown}
        >
          <img src={Caret} draggable="false" />
        </div>
        <Stage
          width={window.innerWidth}
          height={window.innerHeight}
          onDragEnd={handleDragEnd}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseUp={handleMouseUp}
          ref={stageRef}
          draggable={movingMode}
          className="canvas"
        >
          <Layer ref={gridLayerRef}></Layer>
          <Layer>
            {lines.map((line, i) => (
              <Line
                key={i}
                points={line?.points}
                stroke={line.color}
                strokeWidth={line.size}
                tension={0.5}
                lineCap="round"
                globalCompositeOperation={
                  line.tool === "eraser" ? "destination-out" : "source-over"
                }
              />
            ))}
            {drawingEraserBox && eraserBox && (
              <Rect
                x={Math.min(
                  eraserBox.startX - stageOffset.x,
                  eraserBox.endX - stageOffset.x,
                )}
                y={Math.min(
                  eraserBox.startY - stageOffset.y,
                  eraserBox.endY - stageOffset.y,
                )}
                width={Math.abs(eraserBox.endX - eraserBox.startX)}
                height={Math.abs(eraserBox.endY - eraserBox.startY)}
                fill="blue"
                opacity={0.5}
              />
            )}
          </Layer>
        </Stage>
      </div>
      <div className={`tools-bar ${showMiniToolbar ? "mini-toolbar" : ""}`}>
        {/* Eraser */}
        <div onClick={handleEraserClick} className="tools-iconAction">
          <img src={eraserActive ? ActiveErase : Erase} draggable="false" />
        </div>
        {/* Consider setting the color to the background color or implementing a different erase method */}
        {/* Undo functionality - implement undo logic in your component */}
        <div onClick={handleUndo} className="tools-iconAction undo">
          <img src={Undo} draggable="false" />
        </div>
        {showMiniToolbar && (
          <div className="color-miniPicker">
            <div
              className={`color-swatchBorder ${
                !movingMode && !eraserActive ? "active" : ""
              }`}
            >
              <div
                key={activeColor}
                className="color-swatch"
                style={{ backgroundColor: activeColor }}
                onClick={toggleColorPicker}
              />
            </div>
          </div>
        )}
        {colorPickerIsOpen && (
          <div className="tools-colors mini">
            {colorOptions.map((color) => (
              <div
                className={`color-swatchBorder ${
                  activeColor === color && !movingMode && !eraserActive
                    ? "active"
                    : ""
                }`}
              >
                <div
                  key={color}
                  className="color-swatch"
                  style={{ backgroundColor: color }}
                  onClick={handleColorPick.bind(this, color)}
                />
              </div>
            ))}
          </div>
        )}
        {!showMiniToolbar && (
          <div className="tools-colors">
            {colorOptions.map((color) => (
              <div
                className={`color-swatchBorder ${
                  activeColor === color && !movingMode && !eraserActive
                    ? "active"
                    : ""
                }`}
              >
                <div
                  key={color}
                  className="color-swatch"
                  style={{ backgroundColor: color }}
                  onClick={handleColorPick.bind(this, color)}
                />
              </div>
            ))}
          </div>
        )}
        <div onClick={toggleMode} className="tools-iconAction move">
          <img src={movingMode ? ActiveMove : Move} draggable="false" />
        </div>
        <div
          onClick={handleToggleGrid}
          className={`tools-iconAction ${showGrid ? "gridShowing" : ""}`}
        >
          <img src={showGrid ? ActiveGrid : Grid} draggable="false" />
        </div>
      </div>
    </div>
  );
};

export default SketchComponent;
