function shuffleArray(originalArray) {
  let array = originalArray.slice();
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const question2 = (questionArgs) => {
  const product = questionArgs?.constantA * questionArgs?.constantB;
  const allOptions = [
    {
      type: "text",
      value: `${questionArgs?.constantA} is a factor of ${questionArgs?.constantB}`,
    },
    {
      type: "text",
      value: `${product} is a multiple of ${questionArgs?.constantA}`,
    },
    {
      type: "text",
      value: `${product} is a factor of ${questionArgs?.constantB}`,
    },
    {
      type: "text",
      value: `${questionArgs?.constantA} is a factor of ${product}`,
    },
  ];
  const suffledOptions = shuffleArray(allOptions);
  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: `We know `,
              bold: false,
            },
            {
              type: "katex",
              value: `${questionArgs?.constantA} \\times ${questionArgs?.constantB} = ${product}`,
              bold: false,
            },
            {
              type: "text",
              value: `.`,
              bold: false,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
            {
              type: "text",
              value: `So, which of the following statements are also true?`,
              bold: true,
            },
          ],
        },
      },
      {
        type: "ChoiceInput",
        args: {
          heading: "Select two answers:",
          type: "multiAnswer",
          options: suffledOptions,
        },
      },
    ],
  };
};

const args = {
  constantA: 9,
  constantB: 2,
};

export default question2(args);
