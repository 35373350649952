function shuffleArray(originalArray) {
  let array = originalArray.slice();
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const question1 = (questionArgs) => {
  const allOptions = [
    {
      type: "text",
      value: `Kieran shaded the multiples of 10 instead of the mulitples of ${questionArgs?.numberGridQuestionTextMultiples}.`,
    },
    {
      type: "text",
      value: `Kieran shaded the multiples of ${questionArgs?.factor} instead of the mulitples of ${questionArgs?.numberGridQuestionTextMultiples}.`,
    },
    {
      type: "text",
      value: `Kieran shaded the factors of 10 instead of the mulitples of ${questionArgs?.numberGridQuestionTextMultiples}.`,
    },
    {
      type: "text",
      value: `Kieran shaded the factors of 18 instead of the mulitples of ${questionArgs?.numberGridQuestionTextMultiples}.`,
    },
  ];
  const suffledOptions = shuffleArray(allOptions);
  return {
    questionConfig: [
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: true,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: `Kieran tried to shade all the multiples of ${questionArgs?.numberGridQuestionTextMultiples} on the hundred chart, but he made a  mistake. `,
              bold: false,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
          ],
        },
      },
      {
        type: "NumberGrid",
        args: {
          factor: questionArgs?.factor,
          totalSquareLength: questionArgs?.totalSquareLength,
        },
      },
      {
        type: "MultiInput",
        args: {
          size: "md",
          center: false,
          texBG: false,
          lineParts: [
            {
              type: "text",
              value: "What mistake did Kieran make?",
              bold: true,
            },
            {
              type: "newline",
            },
            {
              type: "newline",
            },
          ],
        },
      },
      {
        type: "ChoiceInput",
        args: {
          heading: "Select one:",
          type: "singleAnswer",
          options: suffledOptions,
        },
      },
    ],
  };
};

const args = {
  factor: 9,
  totalSquareLength: 10,
  numberGridQuestionTextMultiples: 3,
};

export default question1(args);
